import React, { useEffect } from "react";
import "../../../assets/scss/header/Header.scss";
import Logo from "../../../ui-component/Logo";
import Logout from "./components/Logout";
import { loadContents, setLanguage } from "../../../store/actions/general/main";
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/Login";

const Header = () => {
  const dispatch = useDispatch();
  const course = useSelector((state) => state.mooc.course);

  useEffect(() => {
    let language = 1;
    setLanguage(dispatch, language);
    loadContents(dispatch, language);
  }, [dispatch]);

  return (
    <div className={"header"}>
      <div className={"header"}>
        <div className={"upperSection"}>
          <Logo className={"marchioBraille"} />
          {course ? <h2>{course.name}</h2> : <h2>MOOC</h2>}
          <div className={"rightSection"}>
            <div className={"buttons"} style={{ order: 2 }}>
              <div className={"up"}>
                <Login />
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <div className={"bottomSection"}></div>
      </div>
      <></>
    </div>
  );
};

export default Header;
