import { HeaderMenuTree } from "../../../../views/private/private_menus";
import HeaderMenu from "./HeaderMenu";
import { getLink } from "../../../../views/private/private_menu_util";

const PrivateHeaderMenu = () => {
  return (
    <>
      {HeaderMenuTree.map((level1, i) => {
        return (
          <HeaderMenu menu={level1} getLink={getLink} key={i}></HeaderMenu>
        );
      })}
    </>
  );
};

export default PrivateHeaderMenu;
