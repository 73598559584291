import {
  APPROVE,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/news_events/news";

export const initialState = {
  news: [],
  waitingNews: [],
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL.name:
      return {
        ...state,
        news: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingNews: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default news;
