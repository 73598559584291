import React, { useEffect } from "react";
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Actions.scss";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import { get as getAction } from "../../../store/actions/actions/actions";
import {
  get,
  getAll as getActionItems,
} from "../../../store/actions/actions/actionItems";
import { Parser as HtmlToReactParser } from "html-to-react";
import { getData, getImagePath } from "../../../store/actions/general/image";

function Action({ subTitle, title, text, image }) {
  const dispatch = useDispatch();

  const imageData = useSelector((state) => state.image.imageData);

  useEffect(() => {
    if (image) {
      getData(dispatch, image);
    }
  }, [image]);

  return (
    <Section>
      <div className={"left-side"}>
        {imageData[image] ? (
          <img
            className={"image"}
            src={getImagePath(imageData[image])}
            alt={"Profile"}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={"right-side"}>
        <p className={"sub_title"}>{subTitle}</p>
        <p className={"title"}>{title}</p>
        <div className={"content"}>{text}</div>
      </div>
    </Section>
  );
}

const Actions = () => {
  const htmlToReactParser = new HtmlToReactParser();

  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);
  const actions = useSelector((state) => state.actions.actions);
  const actionItems = useSelector((state) => state.actionItems.actionItems);
  const actionItemsFull = useSelector(
    (state) => state.actionItems.actionItemsFull
  );

  useEffect(() => {
    getAction(dispatch);
    getActionItems(dispatch);
  }, []);

  useEffect(() => {
    for (let item of actionItems) {
      get(dispatch, item.id);
    }
  }, [actionItems]);

  if (!actions) {
    return <></>;
  }

  if (!actionItemsFull) {
    return <></>;
  }

  let values = Object.values(actionItemsFull).sort((a, b) => {
    return a.item_order - b.item_order;
  });

  return (
    <Page id={"actions"} type={"style1"}>
      <Section>
        <div className={"circle1"}></div>
        <div className={"circle2"}></div>
        <div className={"circle3"}></div>
        <div className={"circle4"}></div>
        <div className={"left-side"}>
          <div className={"title"}>
            <p>{contents ? contents["actions"] : ""}</p>
          </div>
        </div>
        <div className={"right-side"}>
          <div className={"content"}>
            {htmlToReactParser.parse(actions?.info)}
          </div>
        </div>
      </Section>
      {values.map((action, j) => {
        let subTitle =
          (contents ? contents["action_title"] : "") + " " + action.item_order;
        return (
          <Action
            subTitle={subTitle}
            title={action.name}
            text={htmlToReactParser.parse(action.info)}
            image={action.image}
            key={j}
          ></Action>
        );
      })}
    </Page>
  );
};

export default Actions;
