import AdminButton from "../../../../assets/images/header/admin.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Admin = () => {
  let navigate = useNavigate();
  const userType = useSelector((state) => state.login.userType);
  if (!userType || userType === "User") {
    return <></>;
  }

  const handleClick = () => {
    switch (userType) {
      case "Web Site Editor": {
        navigate("/website-editor/home");
        break;
      }
      case "Editor": {
        navigate("/editor/home");
        break;
      }
      case "Content Editor": {
        navigate("/editor/home");
        break;
      }
      case "User": {
        navigate("/user/home");
        break;
      }
    }
  };

  return (
    <div
      className={"clickable headerItem search"}
      role={"button"}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={handleClick}
    >
      <img src={AdminButton} alt={"Admin"} width={"36px"} height={"36px"}></img>
    </div>
  );
};

export default Admin;
