import "../../../../assets/scss/components/page/private/Page.scss";
import React, {useEffect, useState} from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {IconButton, TableBody, TableCell, TableHead, TableRow, Tooltip,} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {getAll, save, SAVE} from "../../../../store/actions/news_events/news";
import IconDelete from "../../../../assets/images/operations/icon-delete.png";
import IconEdit from "../../../../assets/images/operations/icon-edit.png";
import Notice from "../../../../ui-component/Notice";
import {Link, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {raiseError} from "../../../../store/actions/general/error";
import Message from "../../../../ui-component/Message";
import {getSimpleDate} from "../../../../util/date_util";
import {getAddLink, getId, getTitle} from "../../../public/public_menu_util";

const ListNews = () => {
    const MENU = "News";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const username = useSelector((state) => state.login.userId);
    const userType = useSelector((state) => state.login.userType);
    const dataList = useSelector((state) => state.news.news);
    const loading = useSelector((state) => state.loading);

    const [deleteItem, setDeleteItem] = useState(null);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case SAVE.method: {
                setSaveSuccess(true);
                getAll(dispatch);
                break;
            }
        }
    }, [loading?.completedOperation]);

    useEffect(() => {
        if (!userType) {
            raiseError(dispatch, {
                type: "page",
                message: "You don't have permission to view this page.\nPlease login.",
            });
        } else {
            getAll(dispatch);
        }
    }, [userType]);

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [open, setOpen] = useState(false);

    const handleSuccessClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSaveSuccess(false);
    };

    const handleDeleteOpen = (download) => {
        setDeleteItem(download);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const _delete = () => {
        setOpen(false);
        save(dispatch, [], {
            main_id: deleteItem.id,
            name: deleteItem.name,
            info: deleteItem.info,
            date: deleteItem.date,
            images: deleteItem.image,
            gallery: deleteItem.gallery,
            owner: username,
            status: "DELETE",
        });
    };

    if (userType) {
        return (
            <Page title={contents[getTitle(MENU)]} id={getId(MENU)} type={"style1"}>
                <Section fullWidth={true} centered={true} display={saveSuccess}>
                    <Message
                        operation={setSaveSuccess}
                        operationHandler={handleSuccessClose}
                        text={
                            contents[getTitle(MENU)] +
                            " Change Request Submitted Successfully."
                        }
                    ></Message>
                </Section>
                <Section centered={true}>
                    <MainCard style={{width: "100%"}}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} sm={12}>
                                <Link
                                    to={getAddLink(MENU)}
                                    style={{
                                        marginLeft: "0.5rem",
                                        textDecoration: "none",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        style={{
                                            padding: "9px 16px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table size="medium" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Name</TableCell>
                                                <TableCell align="left">Date</TableCell>
                                                <TableCell align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataList?.map((d) => (
                                                <TableRow
                                                    key={d.name}
                                                    className={"list"}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": {border: 0},
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {d.name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {getSimpleDate(d.date)}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Tooltip title="Edit" disableInteractive>
                                                            <IconButton
                                                                color="primary"
                                                                aria-label={
                                                                    "Update the " + contents[getTitle(MENU)] + "!"
                                                                }
                                                                onClick={() =>
                                                                    navigate(getAddLink(MENU), {
                                                                        state: {
                                                                            data: d,
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                <img
                                                                    src={IconEdit}
                                                                    width="35rem"
                                                                    height="35rem"
                                                                    alt="edit"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" disableInteractive>
                                                            <IconButton
                                                                color="primary"
                                                                aria-label={
                                                                    "Delete the " + contents[getTitle(MENU)] + "!"
                                                                }
                                                                onClick={() => handleDeleteOpen(d)}
                                                            >
                                                                <img
                                                                    src={IconDelete}
                                                                    width="35rem"
                                                                    height="35rem"
                                                                    alt="delete"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Notice
                                                            open={open}
                                                            openHandler={handleClose}
                                                            linkTo={"/editor/home"}
                                                            operation={_delete}
                                                            text={
                                                                "Delete the " + contents[getTitle(MENU)] + "!"
                                                            }
                                                            comment={"This action is permanent."}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Section>
            </Page>
        );
    }
};

export default ListNews;
