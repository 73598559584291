import axios from "axios";
import { raiseError } from "../store/actions/general/error.js";
import { setLoading, unsetLoading } from "../store/actions/general/loading";

// User authentication details
let username = null;
let token = null;

// Function to set the user authentication details
export function setLoginData(_username, _token) {
  username = _username;
  token = _token;
}

// Request Queue class to manage concurrent requests
class RequestQueue {
  constructor(concurrencyLimit) {
    this.concurrencyLimit = concurrencyLimit;
    this.queue = [];
    this.activeRequests = 0;
  }

  enqueue(requestFunction) {
    return new Promise((resolve, reject) => {
      this.queue.push(() => requestFunction().then(resolve).catch(reject));
      this.processQueue();
    });
  }

  processQueue() {
    if (this.activeRequests < this.concurrencyLimit && this.queue.length > 0) {
      const request = this.queue.shift();
      this.activeRequests++;

      request().finally(() => {
        this.activeRequests--;
        this.processQueue();
      });
    }
  }
}

// Create an instance of the RequestQueue with a limit of 5 concurrent requests
const requestQueue = new RequestQueue(10);

// POST request function
export const post = (
    dispatch,
    event,
    service_name,
    data,
    getParamaters,
    responseType
) => {
  setLoading(dispatch, service_name);

  let headers = {
    Authorization: `Username: ${username} Token: ${token}`,
    "Content-Type": "application/json",
  };

  let options = {
    headers: headers,
    timeout: 120 * 60 * 1000,
  };
  if (responseType === "blob") {
    options.responseType = responseType;
  }

  return requestQueue.enqueue(() =>
      axios
          .post(process.env.REACT_APP_SERVER_URL + service_name, data, options)
          .then(function (response) {
            const params = getParamaters ? data : null;
            if (params) {
              dispatch({
                type: event,
                data: response.data,
                parameters: params,
                success: true,
              });
            } else {
              dispatch({ type: event, data: response.data, success: true });
            }

            unsetLoading(dispatch, service_name);
          })
          .catch(async function (error) {
            console.error("Service:", process.env.REACT_APP_SERVER_URL + service_name, data);
            console.error("Error message:", error.message);
            console.error("Error code:", error.code);
            console.error("Error response:", error.response);
            console.error("Error config:", error.config);

            if (error.code === "ERR_NETWORK") {
              // window.location.href = "/";
            }

            if (responseType === "blob" && error.response.data) {
              error = JSON.parse(await error.response.data?.text());
            }
            dispatch({ type: event, data: null, success: false });
            raiseError(dispatch, error);
          })
  );
};

// GET request function
export const get = (
    dispatch,
    event,
    service_name,
    data,
    getParamaters,
    responseType
) => {
  setLoading(dispatch, service_name);

  return requestQueue.enqueue(() =>
      axios
          .get(process.env.REACT_APP_SERVER_URL + service_name, {
            params: data,
          })
          .then(function (response) {
            const params = getParamaters ? data : null;
            if (params) {
              dispatch({
                type: event,
                data: response.data,
                parameters: params,
                success: true,
              });
            } else {
              dispatch({ type: event, data: response.data, success: true });
            }

            unsetLoading(dispatch, service_name);
          })
          .catch(async function (error) {
            console.error(error);

            if (error.code === "ERR_NETWORK") {
              // window.location.href = "/";
            }

            if (responseType === "blob" && error.response.data) {
              error = JSON.parse(await error.response.data?.text());
            }
            dispatch({ type: event, data: null, success: false });
            raiseError(dispatch, error);
            unsetLoading(dispatch, service_name);
          })
  );
};

// POST file upload function
export const postFile = (dispatch, event, service_name, files, data) => {
  setLoading(dispatch, service_name);

  let headers = {
    Authorization: `Username: ${username} Token: ${token}`,
    "Content-Type": "multipart/form-data",
  };

  let formData = new FormData();

  for (let id of Object.keys(files)) {
    let arr = files[id];
    for (let file of arr) {
      formData.append(id, file);
    }
  }

  for (let item of Object.keys(data)) {
    switch (typeof data[item]) {
      case "object": {
        formData.append(item, JSON.stringify(data[item]));
        break;
      }
      default: {
        formData.append(item, data[item]);
      }
    }
  }

  return requestQueue.enqueue(() =>
      axios
          .post(process.env.REACT_APP_SERVER_URL + service_name, formData, {
            headers: headers,
            timeout: 120 * 60 * 1000,
          })
          .then(function (response) {
            dispatch({ type: event, data: response.data, success: "true" });
            unsetLoading(dispatch, service_name);
          })
          .catch(function (error) {
            console.error(error);

            if (error.code === "ERR_NETWORK") {
              // window.location.href = "/";
            }

            dispatch({ type: event, data: [], success: "false" });
            raiseError(dispatch, error);
            unsetLoading(dispatch, service_name);
          })
  );
};