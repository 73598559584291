import React, { useEffect, useState } from "react";
import "../../../assets/scss/views/private/Dashboard.scss";
import "../../../assets/scss/components/page/private/Page.scss";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../../ui-component/page/private";
import MainCard from "../../../ui-component/cards/MainCard";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "../../../store/actions/user/login";
import Section from "../../../ui-component/page/public/section";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  let link = location?.state?.link;
  let notify = location?.state?.notify;
  let editor = location?.state?.editor;

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  const loginOperationSuccess = useSelector((state) => state.login.success);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(dispatch, username, password);
  };

  const handleChangeUsername = (event) => {
    setUserName(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" || e.key === 13) {
      setLoading(true);
      login(dispatch, username, password);
    }
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    navigate("/signUp");
  };

  const handleEditorSignupClick = (e) => {
    e.preventDefault();
    navigate("/signUp", {
      state: {
        editor: true,
      },
    });
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  useEffect(() => {
    if (!loginOperationSuccess) {
      setLoading(false);
    } else {
      setLoading(false);
      if (link) {
        navigate("/" + link);
      } else {
        navigate("/user/home");
      }
    }
  }, [dispatch, loginOperationSuccess]);

  return (
    <Page id={"home"} type={"style1"}>
      <Section centered={true}>
        <MainCard>
          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: "none",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  {notify ? (
                    editor ? (
                      <>
                        Thank you for registering you can access the Teaching &
                        Learning Tools by logging in in the mask below. As soon
                        as the qualification for Editor has been made, you will
                        be notified by e-mail by the site administrator.
                      </>
                    ) : (
                      <>
                        Thank you for registering you can access the Teaching &
                        Learning Tools by logging in in the mask below.
                      </>
                    )
                  ) : (
                    <>
                      To access the Teaching & Learning Tools and download
                      materials you need to register by{" "}
                      <div
                        className={"clickable anchor"}
                        role={"button"}
                        tabIndex={0}
                        onClick={handleSignupClick}
                        onKeyUp={handleSignupClick}
                      >
                        clicking here
                      </div>
                      and access the restricted area by{" "}
                      <div
                        className={"clickable anchor"}
                        role={"button"}
                        tabIndex={0}
                        onClick={handleLoginClick}
                        onKeyUp={handleLoginClick}
                      >
                        clicking here
                      </div>
                      . If you wish to contribute to the project as an editor
                      with the possibility of editing content you need to
                      register by{" "}
                      <div
                        className={"clickable anchor"}
                        role={"button"}
                        tabIndex={0}
                        onClick={handleEditorSignupClick}
                        onKeyUp={handleEditorSignupClick}
                      >
                        clicking here
                      </div>{" "}
                      and wait for an email confirmation that you have been
                      enabled as an editor.
                    </>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MainCard>
      </Section>
      <Section centered={true}>
        <MainCard title="Login" style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      label="Username"
                      id="username"
                      onChange={handleChangeUsername}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      style={{ marginBottom: "1rem" }}
                      type="password"
                      label="Password"
                      id="password"
                      onChange={handleChangePassword}
                      onKeyUp={handleKeyUp}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
            {
              <LoadingButton
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
              >
                Login
              </LoadingButton>
            }
          </Stack>

          <Table size="small" aria-label="a dense table">
            <TableBody>
              <TableRow>
                <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                      fontSize: "medium",
                      paddingBottom: "1em",
                    }}
                >
                  <div
                      className={"clickable headerItem search"}
                      role={"button"}
                      tabIndex={0}
                      onClick={handleSignupClick}
                      onKeyUp={handleSignupClick}
                  >
                    Click here to <strong>Sign Up.</strong>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MainCard>
      </Section>
    </Page>
  );
};

export default Login;
