import "../../../../assets/scss/components/page/private/Page.scss";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    InputLabel,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {approve} from "../../../../store/actions/project/projectDetails";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";

const HtmlToReactParser = require('html-to-react').Parser;

const ViewProjectDetailDetail = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.login.userType);
    const userName = useSelector((state) => state.login.username);
    const location = useLocation();
    const projectDetail = location?.state?.projectDetail;

    const htmlToReactParser = new HtmlToReactParser();

    const handleApprove = (status) => {
        approve(dispatch, {
            data: projectDetail,
            status: status
        });
        navigate("/website-editor/home");
    };

    return (
        <Page title={"Project Detail Detail"} id={"viewProjectDetail"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Project Detail" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel style={{color: "blue"}}>Name</InputLabel>
                                                {projectDetail.name}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel style={{color: "blue"}}>Info</InputLabel>
                                                {htmlToReactParser.parse(projectDetail.info)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {
                                    userType === 'Web Site Editor' && projectDetail.owner !== userName ?
                                        <>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                margin="normal"
                                                onClick={() => handleApprove("APPROVE")}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                margin="normal"
                                                onClick={() => handleApprove("REJECT")}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                        : <></>
                                }
                                {
                                    (userType === 'Web Site Editor' && projectDetail.owner === userName) ?
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            margin="normal"
                                            onClick={() => handleApprove("REJECT")}
                                        >
                                            Undo
                                        </Button> : <></>
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default ViewProjectDetailDetail;
