import { useSelector } from "react-redux";
import PrivateHeaderMenu from "./PrivateHeaderMenu";
import PublicHeaderMenu from "./PublicHeaderMenu";

const Menus = () => {
  const userType = useSelector((state) => state.login.userType);

  if (userType == null || userType === "Web Site Editor") {
    return <PublicHeaderMenu></PublicHeaderMenu>;
  }

  if (
    userType === "Editor" ||
    userType === "Content Editor" ||
    userType === "User"
  ) {
    return <PrivateHeaderMenu></PrivateHeaderMenu>;
  }

  return <></>;
};

export default Menus;
