// project imports
import MainLayout from "layout/MainLayout";
import Home from "../views/public/home";
import Search from "../views/public/search";
import Consortiums from "../views/public/consortium";
import Project from "../views/public/project/index";
import Objectives from "../views/public/objectives";
import Actions from "../views/public/actions";
import ProjectResults from "../views/public/project_results/index";
import Download from "../views/public/download";
import NewsDetail from "../views/public/news/NewsDetail";
import NewsEvents from "../views/public/news_events";
import Error from "../views/public/general/error";
import EventDetail from "../views/public/events/EventDetail";
import Partners from "../views/public/partner";
import Login from "../views/private/login/Login";
import SignUp from "../views/private/login/SignUp";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "/objectives",
      element: <Objectives />,
    },
    {
      path: "/actions",
      element: <Actions />,
    },
    {
      path: "/project-results/:id",
      element: <ProjectResults />,
    },
    {
      path: "/project-results",
      element: <ProjectResults />,
    },
    {
      path: "/project",
      element: <Project />,
    },
    {
      path: "/news-events",
      element: <NewsEvents />,
    },
    {
      path: "/consortium",
      element: <Consortiums />,
    },
    {
      path: "/partners",
      element: <Partners />,
    },
    {
      path: "/downloads",
      element: <Download />,
    },
    {
      path: "/events/detail",
      element: <EventDetail />,
    },
    {
      path: "/news/detail",
      element: <NewsDetail />,
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/search/:searchText",
      element: <Search />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signUp",
      element: <SignUp />,
    },
  ],
};

export default MainRoutes;
