import { getMenu } from "./course_tree_util";
import IconInfo from "../../../assets/images/mooc/tree/info.png";

export const CourseMenus = [
  {
    name: "Course Basic Info - About",
    title: "about_the_course",
    link: "basic-info",
    selector: "about",
    icon: IconInfo,
    node: "1|1",
  },
  {
    name: "Course Basic Info - Structure of the Course",
    title: "structure_of_the_course",
    link: "basic-info",
    selector: "structure_and_modules",
    icon: IconInfo,
    node: "1|2",
  },
  {
    name: "Course Basic Info - Project Info",
    title: "project_info",
    link: "basic-info",
    selector: "project_info",
    icon: IconInfo,
    node: "1|3",
  },
  {
    name: "Course Basic Info - Requirements",
    title: "prerequisite_requirements",
    link: "basic-info",
    selector: "prerequisite_requirements",
    icon: IconInfo,
    node: "1|4",
  },
  {
    name: "Course Basic Info - Quizzes Tests Scores",
    title: "quizzes_tests_scores",
    link: "basic-info",
    selector: "quizzes_tests_scores",
    icon: IconInfo,
    node: "1|5",
  },
  {
    name: "Course Basic Info - Rules of Certificate",
    title: "rules_certificate",
    link: "basic-info",
    selector: "rules_certificate",
    icon: IconInfo,
    node: "1|6",
  },
  {
    name: "Course Basic Info - Course Authors",
    title: "authors",
    link: "basic-info",
    selector: "authors",
    icon: IconInfo,
    node: "1|7",
  },
];

export const CoursePreMenuTree = [
  {
    title: "course_basic_info",
    name: "Course Basic Info",
    icon: IconInfo,
    node: "1",
    children: [
      getMenu("Course Basic Info - About"),
      getMenu("Course Basic Info - Structure of the Course"),
      getMenu("Course Basic Info - Project Info"),
      getMenu("Course Basic Info - Requirements"),
      getMenu("Course Basic Info - Quizzes Tests Scores"),
      getMenu("Course Basic Info - Rules of Certificate"),
      getMenu("Course Basic Info - Course Authors"),
    ],
  },
];
