import Logo from "../../../../assets/images/footer/projectlinks/logo.png";
import { useNavigate } from "react-router-dom";

const ProjectLinks = () => {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      navigate("/login");
    }
  };

  const handleSignUpClick = () => {
    navigate("/signUp");
  };

  const handleEditorSignUpClick = () => {
    navigate("/signUp", {
      state: {
        editor: true,
      },
    });
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className={"projectLinks"}>
      <div className={"link"}>
        <div className={"logo"}>
          <img alt="" src={Logo} />
        </div>
        <div className={"rectangle"}>
          <p className={"title"}>
            In-Visible<br></br>Teaching & Learning Tools
          </p>
          <div className={"content"}>
            To access the Teaching & Learning Tools and download materials you
            need to register by
            <div
              className={"clickable anchor"}
              role={"button"}
              tabIndex={0}
              onClick={handleSignUpClick}
              onKeyUp={handleSignUpClick}
            >
              clicking here
            </div>
            and access the restricted area by{" "}
            <div
              className={"clickable anchor"}
              role={"button"}
              tabIndex={0}
              onClick={handleLoginClick}
              onKeyUp={handleLoginClick}
            >
              clicking here
            </div>
            . If you wish to contribute to the project as an editor with the
            possibility of editing content you need to register by{" "}
            <div
              className={"clickable anchor"}
              role={"button"}
              tabIndex={0}
              onClick={handleEditorSignUpClick}
              onKeyUp={handleEditorSignUpClick}
            >
              clicking here
            </div>{" "}
            and wait for an email confirmation that you have been enabled as an
            editor.
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectLinks;
