import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    Checkbox,
    InputLabel,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, get} from "../../../../store/actions/objectives";
import {getData} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {raiseError} from "../../../../store/actions/general/error";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetEditor} from "../../../../store/actions/general/editor";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";

const AddObjective = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const username = useSelector((state) => state.login.userId);
    const id = location?.state?.objective.id;
    const objective = useSelector((state) => state.objectives.objectivesFull[id]);
    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [imageFullWidth, setImageFullWidth] = useState(objective ? objective.image_full_width : '');
    const [order, setOrder] = useState(objective ? objective.item_order : 1);
    const [image, setImage] = useState(objective ? [objective.image] : []);

    useEffect(() => {
        if (id) {
            get(dispatch, id);
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (objective) {
            setImageFullWidth(objective.image_full_width);
            setOrder(objective.item_order);
            setImage([objective.image]);

            if (objective?.image) {
                getData(dispatch, objective.image);
            }
        }
    }, [objective, dispatch]);

    const add = () => {
        if (order.length === 0) {
            raiseError(dispatch, {
                message: "Empty order!"
            });
            return;
        }

        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {
            image_full_width: imageFullWidth,
            info: editorHTMLs["info"],
            item_order: order,
            owner: username,
            status: "ADD",
            images: []
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {
            id: objective.id,
            objectives_id: objective.id,
            images: Object.keys(oldFiles["image"]),
            image_full_width: imageFullWidth,
            info: editorHTMLs["info"],
            item_order: order,
            owner: username,
            status: "UPDATE"
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const handleImageFullWidth = (event) => {
        setImageFullWidth(event.target.checked);
    };

    const handleChangeOrder = (event) => {
        setOrder(event.target.value);
    };

    return (
        <Page title={"Add Objective"} id={"addObjective"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Objective" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <HTMLEditor id={"info"} html={objective?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    type={"number"}
                                                    fullWidth
                                                    label="Order"
                                                    id="order"
                                                    value={order}
                                                    onChange={handleChangeOrder}
                                                    error={order.length > 0 && order.length < 1}
                                                    helperText={
                                                        order && (order.length < 1) ? 'Enter a valid order.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader id={"image"} files={image}
                                                              isImage={true}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Full Width Image</InputLabel>
                                                <Checkbox value={imageFullWidth}
                                                          onChange={handleImageFullWidth}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {objective ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddObjective;
