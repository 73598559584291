import {GET_DATA} from "../../actions/general/image";

export const initialState = {
    imageData: {}
};

const images = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            let id = action.data.id;
            return {
                ...state,
                imageData: {...state.imageData, [id]: action.data}
            };
        default:
            return state;
    }
};

export default images;
