import "../../../../../assets/scss/components/page/private/Page.scss";
import React, { useEffect, useState } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  save,
  SAVE,
} from "../../../../../store/actions/mooc/course/module_part";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { raiseError } from "../../../../../store/actions/general/error";
import { reset as resetEditor } from "../../../../../store/actions/general/editor";
import { getId, getTitle } from "../../../private_menu_util";
import TextField from "@mui/material/TextField";
import { reset as resetFileUploader } from "../../../../../store/actions/general/file_uploader";
import HTMLEditor from "../../../../../ui-component/Editor";

const AddCourseModulePart = () => {
  const MENU = "MOOCs - Course Module Part";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const contents = useSelector((state) => state.main.contents);

  const username = useSelector((state) => state.login.userId);
  const module = location?.state?.module;
  const data = location?.state?.data;

  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);

  const [order, setOrder] = useState(data ? data.name : 1);
  const [name, setName] = useState(data ? data.name : "");

  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        resetEditor(dispatch);
        navigate("/editor/home");
        break;
      }
    }
  }, [loading?.completedOperation, dispatch, navigate]);

  useEffect(() => {
    if (data) {
      setOrder(data.order);
      setName(data.name);
    }
  }, [data, dispatch]);

  const add = () => {
    if (order.length === 0) {
      raiseError(dispatch, {
        message: "Empty order!",
      });
      return;
    }

    if (name.length === 0) {
      raiseError(dispatch, {
        message: "Empty name!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("info") &&
      editorHTMLs["info"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty info!",
      });
      return;
    }

    save(
      dispatch,
      {
        files: [],
      },
      {
        module_id: module.id,
        order: parseInt(order),
        name: name,
        info: editorHTMLs["info"],
        owner: username,
        status: "ADD",
      }
    );
    resetFileUploader(dispatch);
  };

  const edit = () => {
    if (order.length === 0) {
      raiseError(dispatch, {
        message: "Empty order!",
      });
      return;
    }

    if (name.length === 0) {
      raiseError(dispatch, {
        message: "Empty name!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("info") &&
      editorHTMLs["info"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty info!",
      });
      return;
    }

    save(
      dispatch,
      {
        files: [],
      },
      {
        main_id: data.id,
        module_id: data.module_id,
        order: parseInt(order),
        name: name,
        info: editorHTMLs["info"],
        owner: username,
        status: "UPDATE",
      }
    );

    resetFileUploader(dispatch);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  return (
    <Page
      title={"Edit " + contents[getTitle(MENU)]}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard
          title={"Edit " + contents[getTitle(MENU)]}
          style={{ width: "100%" }}
        >
          <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          fullWidth
                          type={"number"}
                          label="Order"
                          id="order"
                          value={order}
                          onChange={handleChangeOrder}
                          error={order === 0}
                          helperText={
                            order && order === 0 ? "Enter a valid order." : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          fullWidth
                          label="Name"
                          id="name"
                          value={name}
                          onChange={handleChangeName}
                          error={name.length > 0 && name.length < 3}
                          helperText={
                            name && name.length < 3 ? "Enter a valid name." : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>Info</InputLabel>
                        <HTMLEditor id={"info"} html={data?.info} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {data ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={add}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default AddCourseModulePart;
