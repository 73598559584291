import {
  GET_ALL_LANGUAGES,
  GET_SELECTED_LANGUAGE,
  LOAD_CONTENTS,
  SET_LANGUAGE,
  SET_MENU_STATUS,
  SET_SELECTED_HEADER_MENU,
} from "../../actions/general/main";

export const initialState = {
  languages: [],
  selectedLanguage: null,
  contents: {},
  selectedHeaderMenu: null,
  menuStatus: "closed",
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_LANGUAGES:
      return {
        ...state,
        languages: action.data,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data,
      };
    case GET_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.data,
      };
    case LOAD_CONTENTS:
      return {
        ...state,
        contents: action.data,
      };
    case SET_MENU_STATUS: {
      return {
        ...state,
        menuStatus: action.data,
      };
    }
    case SET_SELECTED_HEADER_MENU: {
      return {
        ...state,
        selectedHeaderMenu: action.data,
      };
    }
    default:
      return state;
  }
};

export default main;
