// project imports
import MainLayout from "layout/MainLayout";
import Home from "../views/private/user/home";
import ChronologicalCourses from "../views/private/user/idm/chronological_courses";
import TypologicalCourses from "../views/private/user/idm/typological_courses";
import IDMGuidelines from "../views/private/user/idm/guidelines";
import ThreeDModelsFiles from "../views/private/user/three_d_models/files";
import ThreeDModelsGuidelines from "../views/private/user/three_d_models/guidelines";
import ThreeDModelsGallery from "../views/private/user/three_d_models/gallery";
import GuidelinesArchArt from "../views/private/user/guidelines/arch_art";
import ImageCaptioningDatasetAndSystem from "../views/private/user/image_captioning_dataset_and_system";

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
  path: "/user/",
  element: <MainLayout />,
  children: [
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/image-captioning-dataset-and-system",
      element: <ImageCaptioningDatasetAndSystem />,
    },
    {
      path: "/idm",
      children: [
        {
          path: "/typological-courses",
          element: <TypologicalCourses />,
        },
        {
          path: "/chronological-courses",
          element: <ChronologicalCourses />,
        },
        {
          path: "/guidelines",
          element: <IDMGuidelines />,
        },
      ],
    },
    {
      path: "/3d-models",
      children: [
        {
          path: "/files",
          element: <ThreeDModelsFiles />,
        },
        {
          path: "/guidelines",
          element: <ThreeDModelsGuidelines />,
        },
        {
          path: "/gallery",
          element: <ThreeDModelsGallery />,
        },
      ],
    },
    {
      path: "/guidelines",
      children: [
        {
          path: "/arch-art",
          element: <GuidelinesArchArt />,
        },
      ],
    },
  ],
};

export default UserRoutes;
