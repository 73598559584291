import {
  APPROVE,
  GET,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/partners/partner";

export const initialState = {
  partner: {},
  waitingPartners: [],
};

const partner = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      return {
        ...state,
        partner: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingPartners: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default partner;
