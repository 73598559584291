import {post} from "../../../fw/web_service_caller";
export const GET_ALL_WEBSITE = '@approvals/GET_ALL_WEBSITE';

export const GET_ALL = '@approvals/GET_ALL';

export const getAll = (dispatch) => {
    post(dispatch, GET_ALL, 'getWaitingApprovals', null);
};

export const getAllWebSite = (dispatch) => {
    post(dispatch, GET_ALL_WEBSITE, 'getWaitingWebSiteApprovals', null);
};