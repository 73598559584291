import {post} from "../../../fw/web_service_caller";
export const GET_DATA = '@image/GET_DATA';

export const getData = (dispatch, id) => {
    post(dispatch, GET_DATA, 'getImageData', id);
};

export const getImagePath = (image) => {
    let data = {
        "common_file_id": image["common_file_id"]
    };
    return process.env.REACT_APP_FTP_SERVER_URL + "return-files?" + new URLSearchParams(data);
};