import "../../../../assets/scss/views/private/Dashboard.scss";
import "../../../../assets/scss/components/page/private/Page.scss";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "../../../../store/constant";
import MainCard from "../../../../ui-component/cards/MainCard";
import { useNavigate } from "react-router-dom";
import Section from "../../../../ui-component/page/public/section";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { ContentMenuTree } from "../../../public/public_menus";
import { getListLink } from "../../../public/public_menu_util";

const EditContent = () => {
  let navigate = useNavigate();
  const contents = useSelector((state) => state.main.contents);
  const userType = useSelector((state) => state.login.userType);

  if ("Web Site Editor" !== userType) {
    return <></>;
  }

  return (
    <Section centered={true}>
      <MainCard title="Edit Web Site Content" style={{ width: "100%" }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            {ContentMenuTree.map((row, i) => {
              return (
                <Accordion key={"webSiteEditorEditContent" + i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <strong>{contents ? contents[row.title] : ""}</strong>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table
                        size="medium"
                        aria-label="a dense table"
                        style={{ tableLayout: "fixed" }}
                      >
                        <TableBody>
                          {row.children.map((cell, j) => {
                            return (
                              <TableRow
                                key={"webSiteEditorEditContent" + i * 1000 + j}
                              >
                                <TableCell
                                  style={{
                                    borderBottom: "none",
                                    padding: "0.5em",
                                  }}
                                  align={"left"}
                                >
                                  <div
                                    className={"buttons clickable"}
                                    role={"button"}
                                    tabIndex={0}
                                    onClick={() =>
                                      navigate(getListLink(cell.name))
                                    }
                                    onKeyUp={() =>
                                      navigate(getListLink(cell.name))
                                    }
                                    style={{ padding: "0.5em" }}
                                  >
                                    <div className={"up"}>
                                      <div className={"headerItem"}>
                                        <p className={"label"}>
                                          {contents ? contents[cell.title] : ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </Grid>
      </MainCard>
    </Section>
  );
};

export default EditContent;
