import {post} from "../../../fw/web_service_caller";

export const SET_SEARCH_STATUS = '@search/SET_SEARCH_STATUS';
export const SEARCH = '@search/SEARCH';
export const GET_CATEGORIES = '@search/GET_CATEGORIES';

export const setSearchStatus = (dispatch, status) => {
    dispatch({type: SET_SEARCH_STATUS, data: status});
};

export const search = (dispatch, text) => {
    post(dispatch, SEARCH, 'search', text);
};

export const getCategories = (dispatch) => {
    post(dispatch, GET_CATEGORIES, 'getSearchCategories', {});
};
