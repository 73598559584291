import "../../../../../assets/scss/components/page/private/Page.scss";
import React, {useEffect, useState} from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {InputLabel, MenuItem, Select, Stack, TableBody, TableCell, TableRow,} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, SAVE,} from "../../../../../store/actions/mooc/course/module_part_file";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {raiseError} from "../../../../../store/actions/general/error";
import {reset as resetEditor} from "../../../../../store/actions/general/editor";
import {getId, getTitle} from "../../../private_menu_util";
import TextField from "@mui/material/TextField";
import {getData as getFileData} from "../../../../../store/actions/general/file";
import FileUploader from "../../../../../ui-component/FileUploader";
import HTMLEditor from "../../../../../ui-component/Editor";

const AddCourseModulePartFile = () => {
    const MENU = "MOOCs - Course Module Part File";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const contents = useSelector((state) => state.main.contents);

    const username = useSelector((state) => state.login.userId);
    const data = location?.state?.data;
    const modulePart = location?.state?.modulePart;

    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [order, setOrder] = useState(data ? data.name : 1);
    const [name, setName] = useState(data ? data.name : "");
    const [type, setType] = useState(data ? data.type : "Lecture Document");
    const [language, setLanguage] = useState(data ? data.type : "en");
    const [file, setFile] = useState(data ? [data.file] : []);
    const loading = useSelector((state) => state.loading);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case SAVE.method: {
                resetEditor(dispatch);
                navigate("/editor/home");
                break;
            }
        }
    }, [loading?.completedOperation, dispatch, navigate]);

    useEffect(() => {
        if (data) {
            setOrder(data.order);
            setName(data.name);
            setType(data.type);
            setLanguage(data.language);
            setFile([data.file]);

            if (data.file) {
                getFileData(dispatch, data.file);
            }
        }
    }, [data, dispatch]);

    const add = () => {
        if (order.length === 0) {
            raiseError(dispatch, {
                message: "Empty order!",
            });
            return;
        }

        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!",
            });
            return;
        }

        if (editorHTMLs["info"].length === 0) {
            raiseError(dispatch, {
                message: "Empty info!",
            });
            return;
        }

        if (type.length === 0) {
            raiseError(dispatch, {
                message: "Empty type!",
            });
            return;
        }

        if (language.length === 0) {
            raiseError(dispatch, {
                message: "Empty language!",
            });
            return;
        }

        save(
            dispatch,
            {
                file: Object.values(newFiles["file"]),
            },
            {
                module_part_id: modulePart.id,
                order: order,
                name: name,
                info: editorHTMLs["info"],
                type: type,
                language: language,
                owner: username,
                status: "ADD",
                files: [],
            }
        );
        resetEditor(dispatch);
    };

    const edit = () => {
        if (order.length === 0) {
            raiseError(dispatch, {
                message: "Empty order!",
            });
            return;
        }

        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!",
            });
            return;
        }

        if (editorHTMLs["info"].length === 0) {
            raiseError(dispatch, {
                message: "Empty info!",
            });
            return;
        }

        if (type.length === 0) {
            raiseError(dispatch, {
                message: "Empty type!",
            });
            return;
        }

        if (language.length === 0) {
            raiseError(dispatch, {
                message: "Empty language!",
            });
            return;
        }

        save(
            dispatch,
            {
                file: Object.values(newFiles["file"]),
            },
            {
                id: data.id,
                main_id: data.id,
                module_part_id: data.module_part_id,
                files: Object.keys(oldFiles["file"]),
                order: order,
                name: name,
                info: editorHTMLs["info"],
                type: type,
                language: language,
                owner: username,
                status: "UPDATE",
            }
        );

        resetEditor(dispatch);
    };

    const handleChangeOrder = (event) => {
        setOrder(event.target.value);
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    };

    return (
        <Page
            title={"Edit " + contents[getTitle(MENU)]}
            id={getId(MENU)}
            type={"style1"}
        >
            <Section centered={true}>
                <MainCard
                    title={"Edit " + contents[getTitle(MENU)]}
                    style={{width: "100%"}}
                >
                    <Grid container spacing={gridSpacing} columns={{xs: 12}}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <TextField
                                                    fullWidth
                                                    type={"number"}
                                                    label="Order"
                                                    id="order"
                                                    value={order}
                                                    onChange={handleChangeOrder}
                                                    error={order === 0}
                                                    helperText={
                                                        order && order === 0 ? "Enter a valid order." : ""
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && name.length < 3 ? "Enter a valid name." : ""
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <Select
                                                    fullWidth
                                                    id="type"
                                                    value={type}
                                                    label="Type"
                                                    onChange={handleChangeType}
                                                >
                                                    <MenuItem value={"Lecture Document"}>
                                                        Lecture Document
                                                    </MenuItem>
                                                    <MenuItem value={"Additional Material"}>
                                                        Additional Material
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <Select
                                                    fullWidth
                                                    id="language"
                                                    value={language}
                                                    label="Language"
                                                    onChange={handleChangeLanguage}
                                                >
                                                    <MenuItem value={"en"}>
                                                        English
                                                    </MenuItem>
                                                    <MenuItem value={"tr"}>
                                                        Turkish
                                                    </MenuItem>
                                                    <MenuItem value={"it"}>
                                                        Italian
                                                    </MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <HTMLEditor id={"info"} html={data?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <InputLabel>File</InputLabel>
                                                <FileUploader
                                                    id={"file"}
                                                    files={file}
                                                    isFile={true}
                                                    maxFileSize={30}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: "1rem"}}>
                                {data ? (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={edit}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddCourseModulePartFile;
