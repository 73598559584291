import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation, useNavigate } from "react-router-dom";
import { gridSpacing } from "../../../../store/constant";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import {
  IconButton,
  InputLabel,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllByUser,
  save,
} from "../../../../store/actions/mooc/course/user_quiz_result";
import { raiseError } from "../../../../store/actions/general/error";
import IconRefresh from "../../../../assets/images/operations/icon-refresh.png";
import IconComment from "../../../../assets/images/operations/icon-comment.png";

const CourseModulePartFile = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const location = useLocation();
  const modulePart = location?.state?.modulePart;
  const quiz = location?.state?.data;

  const userId = useSelector((state) => state.login.userId);
  const quizResults = useSelector(
    (state) => state.userQuizResult.userQuizResults
  );

  const saveResult = useSelector((state) => state.userQuizResult.saveResult);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  useEffect(() => {
    getAllByUser(dispatch, userId);
  }, [dispatch, userId]);

  useEffect(() => {
    if (saveResult > 0) {
      debugger;
      continueQuiz(saveResult);
    }
  }, [dispatch, modulePart, navigate, quiz, saveResult]);

  const continueQuiz = (userQuizId) => {
    navigate("/user/mooc/module-part-quiz-progress", {
      state: {
        data: quiz,
        modulePart: modulePart,
        userQuizId: userQuizId,
      },
    });
  };

  const handleClick = () => {
    let answers = {};
    for (let order of Object.keys(quiz.questions)) {
      answers[order] = [];
    }

    save(
      dispatch,
      {
        file: [],
      },
      {
        quiz_id: quiz.id,
        answers: answers,
        result: 0,
        owner: userId,
        status: "In Progress",
      }
    );
  };

  return (
    <Page
      title={"Quiz - " + modulePart.name}
      id={"courseModulePartQuiz"}
      type={"mooc"}
    >
      <Section>
        <MainCard style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <InputLabel style={{ color: "blue" }}>
                          Attempts allowed:
                        </InputLabel>
                      </TableCell>
                      <TableCell>
                        <InputLabel>5</InputLabel>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <InputLabel style={{ color: "blue" }}>
                          Grading method:
                        </InputLabel>
                      </TableCell>
                      <TableCell>
                        <InputLabel>Highest grade</InputLabel>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </MainCard>
        <MainCard
          style={{ width: "100%", marginTop: "1em" }}
          title={"Summary of your previous attempts"}
        >
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">State</TableCell>
                      <TableCell align="center">Review</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quizResults?.map((result, i) => {
                      return (
                        <TableRow>
                          <TableCell align="center">
                            <InputLabel>{result.updated_at}</InputLabel>
                          </TableCell>
                          <TableCell align="center">
                            <InputLabel>{result.status}</InputLabel>
                          </TableCell>
                          <TableCell align="center">
                            <InputLabel>{result.score}</InputLabel>
                          </TableCell>
                          <TableCell>
                            {result.status === "In Progress" ? (
                              <TableCell align="center">
                                <IconButton
                                  color="primary"
                                  aria-label={"Continue to the quiz"}
                                  onClick={(e) => continueQuiz(result.id)}
                                >
                                  <img
                                    src={IconRefresh}
                                    width="35rem"
                                    height="35rem"
                                    alt="continue"
                                  />
                                </IconButton>
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <IconButton
                                  color="primary"
                                  aria-label={"Results of the quiz"}
                                  onClick={(e) => continueQuiz(result.id)}
                                >
                                  <img
                                    src={IconComment}
                                    width="35rem"
                                    height="35rem"
                                    alt="results"
                                  />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          {quizResults.length < 5 ? (
            <Grid container spacing={gridSpacing} style={{ marginTop: "1em" }}>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  style={{
                    padding: "9px 16px",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={handleClick}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </MainCard>
      </Section>
    </Page>
  );
};

export default CourseModulePartFile;
