import {
  APPROVE,
  GET,
  GET_ALL_WAITING,
  SAVE,
} from "../../../actions/three_d_models/guidelines/three_d_models_guidelines";

export const initialState = {
  data: null,
  waitingList: [],
};

const threeDModelsGuidelines = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      return {
        ...state,
        data: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingList: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default threeDModelsGuidelines;
