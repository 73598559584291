import React, {useEffect} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Partners.scss";
import Page from "../../../ui-component/page/public";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import {getAll as getAllPartnerMembers} from "../../../store/actions/partners/partnerMembers";
import {get as getPartner} from "../../../store/actions/partners/partner";
import {getData, getImagePath} from "../../../store/actions/general/image";
import {Parser as HtmlToReactParser} from "html-to-react";

const Partner = ({name, info, link, logo}) => {
    const dispatch = useDispatch();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (logo) {
            getData(dispatch, logo);
        }
    }, []);

    const htmlToReactParser = new HtmlToReactParser();

    const handleClick = event => {
        window.open(link);
    };

    const handleKeyup = event => {
        if(event.code === "Enter" || event.code === "NumpadEnter") {
            window.open(link);
        }
    };

    return (
        <Section>
            <div role={"button"} tabIndex={0} className={"left-side clickable"} onClick={handleClick} onKeyUp={handleKeyup}>
                {imageData[logo] ? <img className={"image"} alt={imageData[logo].name} src={getImagePath(imageData[logo])}/> : <></>}
            </div>
            <div className={"right-side"}>
                <div className={"detail"}>
                    <a className={"title"} href={link} target={"_blank"}>{name}</a>
                    <div className={"info"}> {htmlToReactParser.parse(info)}</div>
                </div>
                <div className={"divider"}></div>
            </div>
        </Section>
    );
};

const Partners = () => {
    const htmlToReactParser = new HtmlToReactParser();

    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    useEffect(() => {
        getAllPartnerMembers(dispatch);
        getPartner(dispatch);
    }, []);

    const partner = useSelector((state) => state.partner.partner);
    const partnerMembers = useSelector((state) => state.partnerMember.partnerMembers);

    if (!partner) {
        return <></>;
    }

    if (!partnerMembers) {
        return <></>;
    }

    return (
        <Page id={"partners"} type={"style1"}>
            <Section>
                <div className={"left-side"}>
                    <div className={"title"}>
                        <p>{contents ? contents["partners_title"] : ""}</p>
                    </div>
                </div>
                <div className={"right-side"}>
                    <div className={"content"}>{htmlToReactParser.parse(partner.info)}</div>
                </div>
            </Section>
            {
                partnerMembers.map((partner, j) => {
                    return <Partner name={partner.name} info={partner.info} link={partner.url} logo={partner.image} key={j}></Partner>
                })
            }
            <Section>
                <div className={"left-side"}>
                    <div className={"title"}>
                        <p>{contents ? contents["partners_title"] : ""}</p>
                    </div>
                </div>
                <div className={"right-side"}>
                    <div className={"content"}>{htmlToReactParser.parse(partner.detail)}</div>
                </div>
            </Section>
        </Page>
    );
};

export default Partners;