import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  GET_BY_MODULE_PART,
  SAVE,
} from "../../../actions/mooc/course/module_part_file";

export const initialState = {
  courseModulePartFiles: [],
  courseModulePartFilesFull: {},
  waitingCourseModulePartFiles: [],
};

const courseModulePartFile = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        courseModulePartFilesFull: {
          ...state.courseModulePartFilesFull,
          [id]: action.data,
        },
      };
    case GET_ALL.name:
      return {
        ...state,
        courseModulePartFiles: action.data,
      };
    case GET_BY_MODULE_PART.name:
      return {
        ...state,
        courseModulePartFiles: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingCourseModulePartFiles: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default courseModulePartFile;
