import * as actionTypes from '../../actions/general/search';

export const initialState = {
    searchStatus: "inactive",
    results: [],
    categories: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const search = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_STATUS:
            return {
                ...state,
                searchStatus: action.data
            };
        case actionTypes.GET_CATEGORIES:
            return {
                ...state,
                categories: action.data
            };
        case actionTypes.SEARCH:
            return {
                ...state,
                results: action.data
            };
        default:
            return state;
    }
};

export default search;
