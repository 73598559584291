import React, { useEffect } from "react";
import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../../store/actions/mooc/course/module_part";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import IconModule from "../../../../assets/images/mooc/tree/module.png";
import Box from "@mui/material/Box";
import Image from "mui-image";
import { raiseError } from "../../../../store/actions/general/error";

const CourseModule = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  let navigate = useNavigate();
  const location = useLocation();
  const menu = location?.state?.menu;
  const module = menu?.selector;
  const moduleParts = useSelector(
    (state) => state.courseModulePart.courseModuleParts
  );

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  useEffect(() => {
    getAll(dispatch);
  }, [dispatch]);

  const handleClick = (modulePart) => {
    navigate("/user/mooc/module-part", {
      state: {
        modulePart: modulePart,
      },
    });
  };

  if (!module) {
    return <></>;
  }

  return (
    <Page
      title={menu.title}
      subTitle={"Location: " + module.location}
      id={"courseModule"}
      type={"mooc"}
    >
      <Section>
        <List>
          {moduleParts?.map((modulePart, i) =>
            modulePart.module_id === module.id ? (
              <ListItemButton key={i} onClick={() => handleClick(modulePart)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1em', textAlign: 'center'}} className={'clickable'}>
                <ListItemIcon>
                  <Box color="inherit" sx={{ mr: 1 }}>
                    <Image src={IconModule} style={{ width: "7em" }} />
                  </Box>
                </ListItemIcon>
                <ListItemText primary={modulePart.name} />
              </ListItemButton>
            ) : (
              <></>
            )
          )}
        </List>
      </Section>
    </Page>
  );
};

export default CourseModule;
