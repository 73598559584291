import { getFilePath } from "../../store/actions/general/file";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const DOCViewer = ({ file }) => {
  if (!file) {
    return <></>;
  }

  let docs = [
    {
      uri: getFilePath(file),
      fileName: file.file.name,
    },
  ];

    const NoRenderer = ({ document, fileName }) => {
        const fileText = fileName || document?.fileType || "";

        if (fileText) {
            return <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <a href={getFilePath(file)} className={'clickable'} style={{maxHeight: '2em', textAlign: 'center'}}>
                    <p><strong style={{fontSize: 'large'}}>Download</strong></p>
                    <p>{fileText}</p>
                </a>
            </div>;
        }

        return <div>No Renderer Error!</div>;
    };

  return (
    <div className={"pdfViewer"}>
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            initialActiveDocument={docs[1]}
            style={{ width: "100%", height: "100em" }}
            config={{
                noRenderer: {
                    overrideComponent: NoRenderer,
                },
            }}
          />
    </div>
  );
};

export default DOCViewer;
