// action - state management
import * as actionTypes from '../../actions/general/file_uploader';

export const initialState = {
    newFiles: {},
    oldFiles: {},
    filePreview: {}
};

// ==============================|| PATIENTS REDUCER ||============================== //

const fileUploader = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET: {
            let id = action.id;

            return {
                ...state,
                newFiles: {...state.newFiles, [id]: {}},
                oldFiles: {...state.oldFiles, [id]: {}},
                filePreview: {...state.filePreview, [id]: {}}
            };
        }
        case actionTypes.ADD_FILE: {
            let id = action.id;
            let operation = action.operation;
            let file_id = action.file_id;
            let file_data = action.file_data;

            if (operation === "old") {
                return {
                    ...state,
                    oldFiles: {...state.oldFiles, [id]: {...state.oldFiles[id], [file_id]: file_data}},
                };
            }

            if (operation === "new") {
                return {
                    ...state,
                    newFiles: {...state.newFiles, [id]: {...state.newFiles[id], [file_id]: file_data}},
                };
            }
            break;
        }
        case actionTypes.ADD_FILE_PREVIEW: {
            let id = action.id;
            let file_name = action.file_name;
            let file_data = action.file_data;

            return {
                ...state,
                filePreview: {...state.filePreview, [id]: {...state.filePreview[id], [file_name]: file_data}}
            };
        }
        case actionTypes.REMOVE_FILE: {
            let id = action.id;
            let operation = action.operation;
            let file_id = action.file_id;
            let file_name = action.file_name;

            if (operation === "old") {
                return {
                    ...state,
                    oldFiles: {
                        ...state.oldFiles, [id]: Object.keys(state.oldFiles[id]).reduce((r, e) => {
                            if (String(file_id) !== e) {
                                r[file_id] = state.oldFiles[id][e];
                            }

                            return r;
                        }, {})
                    },
                    filePreview: {
                        ...state.filePreview, [id]: Object.keys(state.filePreview[id]).reduce((r, e) => {
                            if (String(file_name) !== e) {
                                r[file_name] = state.filePreview[id][e];
                            }

                            return r;
                        }, {})
                    },
                };
            }

            if (operation === "new") {
                return {
                    ...state,
                    newFiles: {
                        ...state.newFiles, [id]: Object.keys(state.newFiles[id]).reduce((r, e) => {
                            if (String(file_id) !== e) {
                                r[file_id] = state.newFiles[id][e];
                            }

                            return r;
                        }, {})
                    },
                    filePreview: {
                        ...state.filePreview, [id]: Object.keys(state.filePreview[id]).reduce((r, e) => {
                            if (String(file_name) !== e) {
                                r[file_name] = state.filePreview[id][e];
                            }

                            return r;
                        }, {})
                    },
                };
            }
            break;
        }
        default:
            return state;
    }
};

export default fileUploader;
