import { useDispatch, useSelector } from "react-redux";
import MenuButton from "../../../../assets/images/header/menu.png";
import CloseButton from "../../../../assets/images/header/close.png";
import { setMenuStatus } from "../../../../store/actions/general/main";

const Menu = () => {
  const dispatch = useDispatch();

  const menuStatus = useSelector((state) => state.main.menuStatus);

  const handleOpenMenuClick = (event) => {
    setMenuStatus(dispatch, "open");
  };

  const handleOpenMenuKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      setMenuStatus(dispatch, "open");
    }
  };

  const handleCloseMenuClick = (event) => {
    setMenuStatus(dispatch, "closed");
  };

  const handleCloseMenuKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      setMenuStatus(dispatch, "closed");
    }
  };

  return (
    <>
      {menuStatus === "closed" ? (
        <div
          className={"clickable headerItem openMenu"}
          role={"button"}
          tabIndex={0}
          onClick={handleOpenMenuClick}
          onKeyUp={handleOpenMenuKeyDown}
        >
          <img src={MenuButton} alt={"Menu"}></img>
        </div>
      ) : (
        <></>
      )}
      {menuStatus === "open" ? (
        <div
          className={"clickable headerItem closeMenu"}
          role={"button"}
          tabIndex={0}
          onClick={handleCloseMenuClick}
          onKeyUp={handleCloseMenuKeyDown}
        >
          <img src={CloseButton} alt={"Close Menu"}></img>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Menu;
