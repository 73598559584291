import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

const Message = ({ operation, operationHandler, text, severity }) => {
    var bgcolor = "";
    severity === "error" ? bgcolor = 'red' : bgcolor = 'green'
    if (operation === true) {
        return (
            <Snackbar
                open={operation}
                autoHideDuration={8000}
                onClose={operationHandler}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={severity ? severity : 'success'} variant="filled" sx={{ width: '100%', color: 'white', fontSize: 'large', backgroundColor: bgcolor }} onClose={operationHandler}>
                    {text}
                </Alert>
            </Snackbar>
        );
    } else {
        return <></>;
    }
};

export default Message;
