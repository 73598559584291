// project imports
import MainLayout from "layout/MainLayout";
import ApproveUsers from "../views/private/editor-common/user/ApproveUsers";
import Dashboard from "../views/private/editor-common/dashboard";
import ApproveIDMTypologicalCourses from "../views/private/editor/idm/typological-courses/ApproveIDMTypologicalCourses";
import ViewIDMTypologicalCoursesDetail
  from "../views/private/editor/idm/typological-courses/ViewIDMTypologicalCoursesDetail";
import AddIDMTypologicalCourses from "../views/private/editor/idm/typological-courses/AddIDMTypologicalCourses";
import ApproveIDMTypologicalCoursesFiles
  from "../views/private/editor/idm/typological-courses/ApproveIDMTypologicalCoursesFiles";
import ViewIDMTypologicalCoursesFilesDetail
  from "../views/private/editor/idm/typological-courses/ViewIDMTypologicalCoursesFilesDetail";
import ListIDMTypologicalCoursesFiles
  from "../views/private/editor/idm/typological-courses/ListIDMTypologicalCoursesFiles";
import AddIDMTypologicalCoursesFiles
  from "../views/private/editor/idm/typological-courses/AddIDMTypologicalCoursesFiles";
import ApproveIDMChronologicalCourses
  from "../views/private/editor/idm/chronological-courses/ApproveIDMChronologicalCourses";
import ViewIDMChronologicalCoursesDetail
  from "../views/private/editor/idm/chronological-courses/ViewIDMChronologicalCoursesDetail";
import AddIDMChronologicalCourses from "../views/private/editor/idm/chronological-courses/AddIDMChronologicalCourses";
import ApproveIDMChronologicalCoursesFiles
  from "../views/private/editor/idm/chronological-courses/ApproveIDMChronologicalCoursesFiles";
import ViewIDMChronologicalCoursesFilesDetail
  from "../views/private/editor/idm/chronological-courses/ViewIDMChronologicalCoursesFilesDetail";
import ListIDMChronologicalCoursesFiles
  from "../views/private/editor/idm/chronological-courses/ListIDMChronologicalCoursesFiles";
import AddIDMChronologicalCoursesFiles
  from "../views/private/editor/idm/chronological-courses/AddIDMChronologicalCoursesFiles";
import ApproveIDMGuidelines from "../views/private/editor/idm/guidelines/ApproveIDMGuidelines";
import ViewIDMGuidelinesDetail from "../views/private/editor/idm/guidelines/ViewIDMGuidelinesDetail";
import AddIDMGuidelines from "../views/private/editor/idm/guidelines/AddIDMGuidelines";
import ApproveIDMGuidelinesFiles from "../views/private/editor/idm/guidelines/ApproveIDMGuidelinesFiles";
import ViewIDMGuidelinesFilesDetail from "../views/private/editor/idm/guidelines/ViewIDMGuidelinesFilesDetail";
import ListIDMGuidelinesFiles from "../views/private/editor/idm/guidelines/ListIDMGuidelinesFiles";
import AddIDMGuidelinesFiles from "../views/private/editor/idm/guidelines/AddIDMGuidelinesFiles";
import ApproveThreeDModelGuidelines
  from "../views/private/editor/three-d-models/guidelines/ApproveThreeDModelGuidelines";
import ApproveThreeDModelGuidelinesFiles
  from "../views/private/editor/three-d-models/guidelines/ApproveThreeDModelGuidelinesFiles";
import ViewThreeDModelGuidelinesFilesDetail
  from "../views/private/editor/three-d-models/guidelines/ViewThreeDModelGuidelinesFilesDetail";
import ViewThreeDModelGuidelinesDetail
  from "../views/private/editor/three-d-models/guidelines/ViewThreeDModelGuidelinesDetail";
import AddThreeDModelGuidelines from "../views/private/editor/three-d-models/guidelines/AddThreeDModelGuidelines";
import AddThreeDModelGuidelinesFiles
  from "../views/private/editor/three-d-models/guidelines/AddThreeDModelGuidelinesFiles";
import ListThreeDModelGuidelinesFiles
  from "../views/private/editor/three-d-models/guidelines/ListThreeDModelGuidelinesFiles";
import ApproveThreeDModelFiles from "../views/private/editor/three-d-models/files/ApproveThreeDModelFiles";
import ViewThreeDModelFilesDetail from "../views/private/editor/three-d-models/files/ViewThreeDModelFilesDetail";
import AddThreeDModelFiles from "../views/private/editor/three-d-models/files/AddThreeDModelFiles";
import ApproveThreeDModelFilesFiles from "../views/private/editor/three-d-models/files/ApproveThreeDModelFilesFiles";
import ViewThreeDModelFilesFilesDetail
  from "../views/private/editor/three-d-models/files/ViewThreeDModelFilesFilesDetail";
import AddThreeDModelFilesFiles from "../views/private/editor/three-d-models/files/AddThreeDModelFilesFiles";
import ListThreeDModelFilesFiles from "../views/private/editor/three-d-models/files/ListThreeDModelFilesFiles";
import ApproveThreeDModelGallery from "../views/private/editor/three-d-models/gallery/ApproveThreeDModelGallery";
import ViewThreeDModelGalleryDetail from "../views/private/editor/three-d-models/gallery/ViewThreeDModelGalleryDetail";
import AddThreeDModelGallery from "../views/private/editor/three-d-models/gallery/AddThreeDModelGallery";
import ApproveThreeDModelGalleryFiles
  from "../views/private/editor/three-d-models/gallery/ApproveThreeDModelGalleryFiles";
import ViewThreeDModelGalleryFilesDetail
  from "../views/private/editor/three-d-models/gallery/ViewThreeDModelGalleryFilesDetail";
import ListThreeDModelGalleryFiles from "../views/private/editor/three-d-models/gallery/ListThreeDModelGalleryFiles";
import AddThreeDModelGalleryFiles from "../views/private/editor/three-d-models/gallery/AddThreeDModelGalleryFiles";
import ApproveGuidelinesArchArtFiles from "../views/private/editor/guidelines/arch_art/ApproveGuidelinesArchArtFiles";
import ViewGuidelinesArchArtFilesDetail
  from "../views/private/editor/guidelines/arch_art/ViewGuidelinesArchArtFilesDetail";
import ListGuidelinesArchArtFiles from "../views/private/editor/guidelines/arch_art/ListGuidelinesArchArtFiles";
import AddGuidelinesArchArtFiles from "../views/private/editor/guidelines/arch_art/AddGuidelinesArchArtFiles";
import ApproveGuidelinesArchArt from "../views/private/editor/guidelines/arch_art/ApproveGuidelinesArchArt";
import ViewGuidelinesArchArtDetail from "../views/private/editor/guidelines/arch_art/ViewGuidelinesArchArtDetail";
import AddGuidelinesArchArt from "../views/private/editor/guidelines/arch_art/AddGuidelinesArchArt";
import ApproveCourse from "../views/private/editor/mooc/course/ApproveCourse";
import ViewCourseDetail from "../views/private/editor/mooc/course/ViewCourseDetail";
import ListCourse from "../views/private/editor/mooc/course/ListCourse";
import AddCourse from "../views/private/editor/mooc/course/AddCourse";
import {ListCourses as ListCoursesForCourseBasicInfo} from "../views/private/editor/mooc/course-basic-info/ListCourses";
import AddCourseBasicInfo from "../views/private/editor/mooc/course-basic-info/AddCourseBasicInfo";
import ApproveCourseBasicInfo from "../views/private/editor/mooc/course-basic-info/ApproveCourseBasicInfo";
import ViewCourseBasicInfoDetail from "../views/private/editor/mooc/course-basic-info/ViewCourseBasicInfoDetail";
import ApproveCourseModule from "../views/private/editor/mooc/module/ApproveCourseModule";
import ViewCourseModuleDetail from "../views/private/editor/mooc/module/ViewCourseModuleDetail";
import AddCourseModule from "../views/private/editor/mooc/module/AddCourseModule";
import ListCourseModule from "../views/private/editor/mooc/module/ListCourseModule";
import ListCourseModulePart from "../views/private/editor/mooc/module_part/ListCourseModulePart";
import AddCourseModulePart from "../views/private/editor/mooc/module_part/AddCourseModulePart";
import ApproveCourseModulePart from "../views/private/editor/mooc/module_part/ApproveCourseModulePart";
import ViewCourseModulePartDetail from "../views/private/editor/mooc/module_part/ViewCourseModulePartDetail";
import ApproveCourseModulePartFile from "../views/private/editor/mooc/module_part_file/ApproveCourseModulePartFile";
import ListCourseModulePartFile from "../views/private/editor/mooc/module_part_file/ListCourseModulePartFile";
import ViewCourseModulePartFileDetail
  from "../views/private/editor/mooc/module_part_file/ViewCourseModulePartFileDetail";
import AddCourseModulePartFile from "../views/private/editor/mooc/module_part_file/AddCourseModulePartFile";
import ApproveCourseModulePartQuiz from "../views/private/editor/mooc/module_part_quiz/ApproveCourseModulePartQuiz";
import ViewCourseModulePartQuizDetail
  from "../views/private/editor/mooc/module_part_quiz/ViewCourseModulePartQuizDetail";
import ListCourseModulePartQuiz from "../views/private/editor/mooc/module_part_quiz/ListCourseModulePartQuiz";
import AddCourseModulePartQuiz from "../views/private/editor/mooc/module_part_quiz/AddCourseModulePartQuiz"; // ==============================|| MAIN ROUTING ||============================== //
// ==============================|| MAIN ROUTING ||============================== //

// ==============================|| MAIN ROUTING ||============================== //

const EditorRoutes = {
  path: "/editor/",
  element: <MainLayout />,
  children: [
    {
      path: "/home",
      element: <Dashboard />,
    },
    {
      path: "/approveUsers",
      element: <ApproveUsers />,
    },
    {
      path: "/idm/",
      children: [
        {
          path: "/typological-courses/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMTypologicalCourses />,
            },
            {
              path: "/view",
              element: <ViewIDMTypologicalCoursesDetail />,
            },
            {
              path: "/list",
              element: <AddIDMTypologicalCourses />,
            },
          ],
        },
        {
          path: "/typological-courses-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMTypologicalCoursesFiles />,
            },
            {
              path: "/view",
              element: <ViewIDMTypologicalCoursesFilesDetail />,
            },
            {
              path: "/list",
              element: <ListIDMTypologicalCoursesFiles />,
            },
            {
              path: "/add",
              element: <AddIDMTypologicalCoursesFiles />,
            },
          ],
        },
        {
          path: "/chronological-courses/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMChronologicalCourses />,
            },
            {
              path: "/view",
              element: <ViewIDMChronologicalCoursesDetail />,
            },
            {
              path: "/list",
              element: <AddIDMChronologicalCourses />,
            },
          ],
        },
        {
          path: "/chronological-courses-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMChronologicalCoursesFiles />,
            },
            {
              path: "/view",
              element: <ViewIDMChronologicalCoursesFilesDetail />,
            },
            {
              path: "/list",
              element: <ListIDMChronologicalCoursesFiles />,
            },
            {
              path: "/add",
              element: <AddIDMChronologicalCoursesFiles />,
            },
          ],
        },
        {
          path: "/guidelines/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMGuidelines />,
            },
            {
              path: "/view",
              element: <ViewIDMGuidelinesDetail />,
            },
            {
              path: "/list",
              element: <AddIDMGuidelines />,
            },
          ],
        },
        {
          path: "/guidelines-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveIDMGuidelinesFiles />,
            },
            {
              path: "/view",
              element: <ViewIDMGuidelinesFilesDetail />,
            },
            {
              path: "/list",
              element: <ListIDMGuidelinesFiles />,
            },
            {
              path: "/add",
              element: <AddIDMGuidelinesFiles />,
            },
          ],
        },
      ],
    },
    {
      path: "/3d-models/",
      children: [
        {
          path: "/files/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelFiles />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelFilesDetail />,
            },
            {
              path: "/list",
              element: <AddThreeDModelFiles />,
            },
          ],
        },
        {
          path: "/files-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelFilesFiles />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelFilesFilesDetail />,
            },
            {
              path: "/list",
              element: <ListThreeDModelFilesFiles />,
            },
            {
              path: "/add",
              element: <AddThreeDModelFilesFiles />,
            },
          ],
        },
        {
          path: "/gallery/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelGallery />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelGalleryDetail />,
            },
            {
              path: "/list",
              element: <AddThreeDModelGallery />,
            },
          ],
        },
        {
          path: "/gallery-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelGalleryFiles />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelGalleryFilesDetail />,
            },
            {
              path: "/list",
              element: <ListThreeDModelGalleryFiles />,
            },
            {
              path: "/add",
              element: <AddThreeDModelGalleryFiles />,
            },
          ],
        },
        {
          path: "/guidelines/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelGuidelines />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelGuidelinesDetail />,
            },
            {
              path: "/list",
              element: <AddThreeDModelGuidelines />,
            },
          ],
        },
        {
          path: "/guidelines-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveThreeDModelGuidelinesFiles />,
            },
            {
              path: "/view",
              element: <ViewThreeDModelGuidelinesFilesDetail />,
            },
            {
              path: "/list",
              element: <ListThreeDModelGuidelinesFiles />,
            },
            {
              path: "/add",
              element: <AddThreeDModelGuidelinesFiles />,
            },
          ],
        },
      ],
    },
    {
      path: "/guidelines/",
      children: [
        {
          path: "/arch-art/",
          children: [
            {
              path: "/approve",
              element: <ApproveGuidelinesArchArt />,
            },
            {
              path: "/view",
              element: <ViewGuidelinesArchArtDetail />,
            },
            {
              path: "/list",
              element: <AddGuidelinesArchArt />,
            },
          ],
        },
        {
          path: "/arch-art-files/",
          children: [
            {
              path: "/approve",
              element: <ApproveGuidelinesArchArtFiles />,
            },
            {
              path: "/view",
              element: <ViewGuidelinesArchArtFilesDetail />,
            },
            {
              path: "/list",
              element: <ListGuidelinesArchArtFiles />,
            },
            {
              path: "/add",
              element: <AddGuidelinesArchArtFiles />,
            },
          ],
        },
      ],
    },
    {
      path: "/mooc/",
      children: [
        {
          path: "/courses/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourse />,
            },
            {
              path: "/view",
              element: <ViewCourseDetail />,
            },
            {
              path: "/list",
              element: <ListCourse />,
            },
            {
              path: "/add",
              element: <AddCourse />,
            },
          ],
        },
        {
          path: "/course_basic_info/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourseBasicInfo />,
            },
            {
              path: "/view",
              element: <ViewCourseBasicInfoDetail />,
            },
            {
              path: "/list",
              element: <ListCoursesForCourseBasicInfo />,
            },
            {
              path: "/add",
              element: <AddCourseBasicInfo />,
            },
          ],
        },
        {
          path: "/course_module/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourseModule />,
            },
            {
              path: "/view",
              element: <ViewCourseModuleDetail />,
            },
            {
              path: "/list",
              element: <ListCourseModule />,
            },
            {
              path: "/add",
              element: <AddCourseModule />,
            },
          ],
        },
        {
          path: "/course_module_part/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourseModulePart />,
            },
            {
              path: "/view",
              element: <ViewCourseModulePartDetail />,
            },
            {
              path: "/list",
              element: <ListCourseModulePart />,
            },
            {
              path: "/add",
              element: <AddCourseModulePart />,
            },
          ],
        },
        {
          path: "/course_module_part_file/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourseModulePartFile />,
            },
            {
              path: "/view",
              element: <ViewCourseModulePartFileDetail />,
            },
            {
              path: "/list",
              element: <ListCourseModulePartFile />,
            },
            {
              path: "/add",
              element: <AddCourseModulePartFile />,
            },
          ],
        },
        {
          path: "/course_module_part_quiz/",
          children: [
            {
              path: "/approve",
              element: <ApproveCourseModulePartQuiz />,
            },
            {
              path: "/view",
              element: <ViewCourseModulePartQuizDetail />,
            },
            {
              path: "/list",
              element: <ListCourseModulePartQuiz />,
            },
            {
              path: "/add",
              element: <AddCourseModulePartQuiz />,
            },
          ],
        },
      ],
    },
  ],
};

export default EditorRoutes;
