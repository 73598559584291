import LogoSmallImage from "../assets/images/logo/small.png";
import LogoLargeImage from "../assets/images/logo/large.png";
import LogoReverseImage from "../assets/images/logo/large-negative.png";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/actions/user/login";

const Logo = ({ reverse }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userType = useSelector((state) => state.login.userType);
  const username = useSelector((state) => state.login.username);
  const token = useSelector((state) => state.login.token);
  const handleClick = () => {
    switch (userType) {
      case null: {
        navigate("/");
        break;
      }
      case "Web Site Editor": {
        navigate("/");
        break;
      }
      case "Editor": {
        navigate("/");
        break;
      }
      case "Content Editor": {
        navigate("/");
        break;
      }
      case "User": {
        navigate("/");
        break;
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      switch (userType) {
        case null: {
          logout(dispatch, username, token);
          navigate("/");
          break;
        }
        case "Web Site Editor": {
          logout(dispatch, username, token);
          navigate("/");
          break;
        }
        case "Editor": {
          logout(dispatch, username, token);
          navigate("/");
          break;
        }
        case "User": {
          logout(dispatch, username, token);
          navigate("/");
          break;
        }
      }
    }
  };

  if (reverse) {
    return (
      <div className={"marchioBraille"}>
        <img alt="" src={LogoReverseImage} />
      </div>
    );
  }

  return (
    <div
      className={"marchioBraille clickable"}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role={"button"}
      tabIndex={0}
    >
      <img className={"largeLogo"} alt="" src={LogoLargeImage} />
      <img className={"smallLogo"} alt="" src={LogoSmallImage} />
    </div>
  );
};

export default Logo;
