import LogoutButton from "../../../../assets/images/header/logout.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../store/actions/user/login";

const Logout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const userType = useSelector((state) => state.login.userType);
  const username = useSelector((state) => state.login.username);
  const token = useSelector((state) => state.login.token);

  const handleLogoutClick = () => {
    logout(dispatch, username, token);
    navigate("/");
  };

  if (
    "Web Site Editor" !== userType &&
    "Editor" !== userType &&
      "Content Editor" !== userType &&
    "User" !== userType
  ) {
    return <></>;
  }

  return (
    <div
      className={"clickable headerItem search"}
      role={"button"}
      tabIndex={0}
      onClick={handleLogoutClick}
      onKeyUp={handleLogoutClick}
    >
      <img src={LogoutButton} alt={"Logout"}></img>
    </div>
  );
};

export default Logout;
