import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save} from "../../../../store/actions/project/projectDetails";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {raiseError} from "../../../../store/actions/general/error";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetEditor} from "../../../../store/actions/general/editor";

const AddProjectDetail = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const username = useSelector((state) => state.login.userId);
    const projectDetail = location?.state?.projectDetail;
    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);

    const [name, setName] = useState(projectDetail ? projectDetail.name : '');
    const [order, setOrder] = useState(projectDetail ? projectDetail.item_order: 1);

    useEffect(() => {
        if(projectDetail) {
            setName(projectDetail.name);
            setOrder(projectDetail.item_order);

            if (projectDetail) {
                setImage([projectDetail.image]);
            }
        }
    }, [projectDetail, dispatch]);

    const add = () => {
        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!"
            });
            return;
        }

        if (editorHTMLs["info"].length === 0) {
            raiseError(dispatch, {
                message: "Empty info!"
            });
            return;
        }

        if (order.length === 0) {
            raiseError(dispatch, {
                message: "Empty order!"
            });
            return;
        }

        save(dispatch, {}, {name: name, info: editorHTMLs["info"], item_order: order, owner: username, status: "ADD"});

        resetEditor(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        save(dispatch, {}, {
            id: projectDetail.id,
            project_details_id: projectDetail.id,
            name: name,
            info: editorHTMLs["info"],
            item_order: order,
            owner: username,
            status: "UPDATE"
        });

        resetEditor(dispatch);
        navigate("/website-editor/home");
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeOrder = (event) => {
        setOrder(event.target.value);
    };

    return (
        <Page title={"Add Project Detail"} id={"addProjectDetail"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Project Detail" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && (name.length < 3) ? 'Enter a valid name.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <HTMLEditor id={"info"} html={projectDetail?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    type={"number"}
                                                    fullWidth
                                                    label="Order"
                                                    id="order"
                                                    value={order}
                                                    onChange={handleChangeOrder}
                                                    error={order.length > 0 && order.length < 1}
                                                    helperText={
                                                        order && (order.length < 1) ? 'Enter a valid order.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {projectDetail ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddProjectDetail;
