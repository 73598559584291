import * as actionTypes from "../../actions/user/login";
import { setLoginData } from "../../../fw/web_service_caller";

export const initialState = {
  username: null,
  token: null,
  userId: null,
  userType: null,
  success: false,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const login = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN.name:
      if (action.data) {
        setLoginData(action.data.username, action.data.token);
      }
      return {
        ...state,
        username: action.data?.username,
        token: action.data?.token,
        userId: action.data?.user_id,
        userType: action.data?.user_type,
        success: action.success,
      };
    case actionTypes.LOGOUT.name:
      if (action.data) {
        setLoginData(null, null);
      }
      return {
        ...state,
        username: null,
        token: null,
        userId: null,
        userType: null,
        success: false,
      };
    case actionTypes.SIGNUP.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default login;
