import React, { useEffect, useState } from "react";
import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "../../../../store/actions/mooc/course/course_basic_info";
import { Parser as HtmlToReactParser } from "html-to-react";
import AudioPlayer from "../../../../ui-component/AudioPlayer";
import { raiseError } from "../../../../store/actions/general/error";

const CourseBasicInfo = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const location = useLocation();
  const contents = useSelector((state) => state.main.contents);
  const menu = location?.state?.menu;
  const course = useSelector((state) => state.mooc.course);
  const courseBasicInfosFull = useSelector(
    (state) => state.courseBasicInfo.courseBasicInfosFull
  );
  const htmlToReactParser = new HtmlToReactParser();

  const [data, setData] = useState("");
  const [audioId, setAudioId] = useState(null);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  useEffect(() => {
    if (course?.id) {
      get(dispatch, course.id);
    }
  }, [dispatch, course]);

  useEffect(() => {
    if (course) {
      if (courseBasicInfosFull[course.id]) {
        switch (menu.selector) {
          case "about": {
            setData(courseBasicInfosFull[course.id].about);
            setAudioId(courseBasicInfosFull[course.id].about_audio_id);
            break;
          }
          case "structure_and_modules": {
            setData(courseBasicInfosFull[course.id].structure_and_modules);
            setAudioId(
              courseBasicInfosFull[course.id].structure_and_modules_audio_id
            );
            break;
          }
          case "project_info": {
            setData(courseBasicInfosFull[course.id].project_info);
            setAudioId(courseBasicInfosFull[course.id].project_info_audio_id);
            break;
          }
          case "prerequisite_requirements": {
            setData(courseBasicInfosFull[course.id].prerequisite_requirements);
            setAudioId(
              courseBasicInfosFull[course.id].prerequisite_requirements_audio_id
            );
            break;
          }
          case "quizzes_tests_scores": {
            setData(courseBasicInfosFull[course.id].quizzes_tests_scores);
            setAudioId(
              courseBasicInfosFull[course.id].quizzes_tests_scores_audio_id
            );
            break;
          }
          case "rules_certificate": {
            setData(courseBasicInfosFull[course.id].certificate);
            setAudioId(courseBasicInfosFull[course.id].certificate_audio_id);
            break;
          }
          case "authors": {
            setData(courseBasicInfosFull[course.id].authors);
            setAudioId(courseBasicInfosFull[course.id].authors_audio_id);
            break;
          }
        }
      }
    }
  }, [course?.id, courseBasicInfosFull, menu]);

  if (!menu) {
    return <></>;
  }

  return (
    <Page title={contents[menu.title]} id={"courseBasicInfo"} type={"mooc"}>
      <AudioPlayer audio={audioId} />
      <Section>{htmlToReactParser.parse(data)}</Section>
    </Page>
  );
};

export default CourseBasicInfo;
