export default [
    {
        id: 1,
        name: "English",
        code: "EN"
    },
    {
        id: 2,
        name: "Italiano",
        code: "IT"
    }
];