import "../../../assets/scss/components/page/public/Page.scss";

let PageTitle = ({ title, subTitle }) => {
  return (
    <div className={"pageTitle"}>
      <div className={"containerTwo"}>
        <div className={"container"}>
          <p className={"title"}>{title}</p>
          <p className={"description"}>{subTitle}</p>
        </div>
      </div>
    </div>
  );
};

const Page = ({ title, subTitle, id, children, type }) => {
  return (
    <div className={"page " + type}>
      {title ? (
        type === "style3" ? (
          <PageTitle title={title} subTitle={subTitle} />
        ) : (
          <div className={"title"}>
            <div className={"title"}>{title}</div>
            <div className={"subTitle"}>{subTitle}</div>
          </div>
        )
      ) : (
        <></>
      )}
      <div className={"content " + id}>{children}</div>
    </div>
  );
};

export default Page;
