export const SET = '@loading/SET';
export const UNSET = '@loading/UNSET';
export const RESET = '@loading/RESET';

export const setLoading = (dispatch, operation) => {
    dispatch({ type: SET, data: operation});
};

export const unsetLoading = (dispatch, operation) => {
    dispatch({ type: UNSET, data: operation });
};

export const resetLoading = (dispatch) => {
    dispatch({ type: RESET});
};
