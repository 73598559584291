import { getMenu } from "./private_menu_util";

export const ContentMenus = [
  {
    name: "IDM - Typological Courses",
    title: "typological_courses",
    link: "idm/typological-courses",
  },
  {
    name: "IDM - Typological Courses Files",
    title: "typological_courses_files",
    link: "idm/typological-courses-files",
  },
  {
    name: "IDM - Chronological Courses",
    title: "chronological_courses",
    link: "idm/chronological-courses",
  },
  {
    name: "IDM - Chronological Courses Files",
    title: "chronological_courses_files",
    link: "idm/chronological-courses-files",
  },
  {
    name: "IDM - Guidelines",
    title: "idm_guidelines",
    link: "idm/guidelines",
  },
  {
    name: "IDM - Guidelines Files",
    title: "guidelines_files",
    link: "idm/guidelines-files",
  },
  {
    name: "3D Models - Files",
    title: "drawing_files",
    link: "3d-models/files",
  },
  {
    name: "3D Models - Files Files",
    title: "drawing_files_files",
    link: "3d-models/files-files",
  },
  {
    name: "3D Models - Guidelines",
    title: "3d_models_guidelines",
    link: "3d-models/guidelines",
  },
  {
    name: "3D Models - Guidelines Files",
    title: "3d_models_guidelines_files",
    link: "3d-models/guidelines-files",
  },
  {
    name: "3D Models - Gallery",
    title: "gallery",
    link: "3d-models/gallery",
  },
  {
    name: "3D Models - Gallery Files",
    title: "gallery_files",
    link: "3d-models/gallery-files",
  },
  {
    name: "Guidelines - Arch & Art",
    title: "make_architecture_arts_accessible_to_vib",
    link: "guidelines/arch-art",
  },
  {
    name: "Guidelines - Arch & Art Files",
    title: "make_architecture_arts_accessible_to_vib_files",
    link: "guidelines/arch-art-files",
  },
  {
    name: "Image Captioning Dataset and System",
    title: "image_captioning_dataset_and_system",
    link: "image-captioning-dataset-and-system",
  },
  {
    name: "MOOCs - Courses",
    title: "courses",
    link: "mooc/courses",
  },
  {
    name: "MOOCs - Course Basic Info",
    title: "course_basic_info",
    link: "mooc/course_basic_info",
  },
  {
    name: "MOOCs - Course Module",
    title: "course_module",
    link: "mooc/course_module",
  },
  {
    name: "MOOCs - Course Module Part",
    title: "course_module_part",
    link: "mooc/course_module_part",
  },
  {
    name: "MOOCs - Course Module Part File",
    title: "course_module_part_file",
    link: "mooc/course_module_part_file",
  },
  {
    name: "MOOCs - Course Module Part Quiz",
    title: "course_module_part_quiz",
    link: "mooc/course_module_part_quiz",
  },
  {
    name: "MOOCs",
    title: "moocs",
    link: "mooc/courses",
    relative: false,
  },
];

export const HeaderMenuTree = [
  {
    title: "he_inclusive_innovative_didactic_modules",
    name: "HE Inclusive and Innovative Didactic Modules",
    children: [
      getMenu("IDM - Typological Courses"),
      getMenu("IDM - Chronological Courses"),
      getMenu("IDM - Guidelines"),
    ],
  },
  {
    title: "3d_architectural_models",
    name: "3D Architectural Models",
    children: [
      getMenu("3D Models - Files"),
      getMenu("3D Models - Guidelines"),
      getMenu("3D Models - Gallery"),
    ],
  },
  {
    title: "guidelines",
    name: "Guidelines",
    children: [getMenu("Guidelines - Arch & Art")],
  },
  getMenu("Image Captioning Dataset and System"),
  getMenu("MOOCs"),
];

export const ContentMenuTree = [
  {
    title: "he_inclusive_innovative_didactic_modules",
    name: "HE Inclusive and Innovative Didactic Modules",
    children: [
      getMenu("IDM - Typological Courses"),
      getMenu("IDM - Typological Courses Files"),
      getMenu("IDM - Chronological Courses"),
      getMenu("IDM - Chronological Courses Files"),
      getMenu("IDM - Guidelines"),
      getMenu("IDM - Guidelines Files"),
    ],
  },
  {
    title: "3d_architectural_models",
    name: "3D Architectural Models",
    children: [
      getMenu("3D Models - Files"),
      getMenu("3D Models - Files Files"),
      getMenu("3D Models - Guidelines"),
      getMenu("3D Models - Guidelines Files"),
      getMenu("3D Models - Gallery"),
      getMenu("3D Models - Gallery Files"),
    ],
  },
  {
    title: "guidelines",
    name: "Guidelines",
    children: [
      getMenu("Guidelines - Arch & Art"),
      getMenu("Guidelines - Arch & Art Files"),
    ],
  },
  {
    title: "moocs",
    name: "MOOCs",
    children: [
      getMenu("MOOCs - Courses"),
      getMenu("MOOCs - Course Basic Info"),
      getMenu("MOOCs - Course Module"),
      getMenu("MOOCs - Course Module Part"),
      getMenu("MOOCs - Course Module Part File"),
      getMenu("MOOCs - Course Module Part Quiz"),
    ],
  },
];
