import Header from "./Header";
import "../../assets/scss/Main.scss";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { gridSpacing } from "../../store/constant";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import MenuTreeView from "./Tree/components/MenuTreeView";

const MainLayout = () => {
  const course = useSelector((state) => state.mooc.course);

  return (
    <div className={"homepage"}>
      <Header></Header>

      {course ? (
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} sm={4}>
            <MenuTreeView></MenuTreeView>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Outlet />
          </Grid>
        </Grid>
      ) : (
        <Outlet />
      )}
      <Footer></Footer>
    </div>
  );
};

export default MainLayout;
