import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../../actions/idm/typological_courses/idm_typological_courses_files";

export const initialState = {
  list: [],
  listFull: {},
  waitingList: [],
};

const idmTypologicalCoursesFiles = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        listFull: { ...state.listFull, [id]: action.data },
      };
    case GET_ALL.name:
      return {
        ...state,
        listFull: {},
        list: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingList: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default idmTypologicalCoursesFiles;
