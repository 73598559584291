import {combineReducers} from "redux";

// reducer import
import search from "./reducers/general/search";
import home from "./reducers/home/home";
import partnerMember from "./reducers/partners/partnerMember";
import actions from "./reducers/actions/actions";
import projectResults from "./reducers/project/projectResults";
import event from "./reducers/news_events/events";
import news from "./reducers/news_events/news";
import main from "./reducers/general/main";
import download from "./reducers/download";
import project from "./reducers/project/project";
import objectives from "./reducers/objectives";
import error from "./reducers/general/error";
import user from "./reducers/user/user";
import image from "./reducers/general/image";
import loading from "./reducers/general/loading";
import file from "./reducers/general/file";
import audio from "./reducers/general/audio";
import consortiumMember from "./reducers/consortium/consortiumMember";
import consortium from "./reducers/consortium/consortium";
import partner from "./reducers/partners/partner";
import gallery from "./reducers/home/gallery";
import projectDetails from "./reducers/project/projectDetails";
import actionItems from "./reducers/actions/actionItems";
import login from "./reducers/user/login";
import approvals from "./reducers/user/approvals";
import editor from "./reducers/general/editor";
import fileUploader from "./reducers/general/file_uploader";
import idmTypologicalCourses from "./reducers/idm/typological_courses/idm_typological_courses";
import idmTypologicalCoursesFiles from "./reducers/idm/typological_courses/idm_typological_courses_files";
import idmChronologicalCourses from "./reducers/idm/chronological_courses/idm_chronological_courses";
import idmChronologicalCoursesFiles from "./reducers/idm/chronological_courses/idm_chronological_courses_files";
import idmGuidelines from "./reducers/idm/guidelines/idm_guidelines";
import idmGuidelinesFiles from "./reducers/idm/guidelines/idm_guidelines_files";
import threeDModelsFilesFiles from "./reducers/three_d_models/files/three_d_models_files_files";
import threeDModelsFiles from "./reducers/three_d_models/files/three_d_models_files";
import threeDModelsGallery from "./reducers/three_d_models/gallery/three_d_models_gallery";
import threeDModelsGalleryFiles from "./reducers/three_d_models/gallery/three_d_models_gallery_files";
import threeDModelsGuidelines from "./reducers/three_d_models/guidelines/three_d_models_guidelines";
import threeDModelsGuidelinesFiles from "./reducers/three_d_models/guidelines/three_d_models_guidelines_files";
import guidelinesArchArt from "./reducers/guidelines/arch_art/guidelines_arch_art";
import guidelinesArchArtFiles from "./reducers/guidelines/arch_art/guidelines_arch_art_files";
import course from "./reducers/mooc/course/course";
import courseBasicInfo from "./reducers/mooc/course/course_basic_info";
import mooc from "./reducers/mooc/mooc";
import courseModule from "./reducers/mooc/course/module";
import courseModulePart from "./reducers/mooc/course/module_part";
import courseModulePartFile from "./reducers/mooc/course/module_part_file";
import courseModulePartQuiz from "./reducers/mooc/course/module_part_quiz";
import userQuizResult from "./reducers/mooc/course/user_quiz_result";
import loginCount from "./reducers/user/login_count";

// ==============================|| COMBINE REDUCER ||============================== //
const reducer = combineReducers({
  search: search,
  home: home,
  partnerMember: partnerMember,
  gallery: gallery,
  actions: actions,
  projectResults: projectResults,
  news: news,
  event: event,
  downloads: download,
  main: main,
  project: project,
  objectives: objectives,
  error: error,
  user: user,
  image: image,
  loading: loading,
  file: file,
  consortiumMember: consortiumMember,
  consortium: consortium,
  partner: partner,
  projectDetails: projectDetails,
  actionItems: actionItems,
  login: login,
  approvals: approvals,
  editor: editor,
  fileUploader: fileUploader,
  idmTypologicalCourses: idmTypologicalCourses,
  idmTypologicalCoursesFiles: idmTypologicalCoursesFiles,
  idmChronologicalCourses: idmChronologicalCourses,
  idmChronologicalCoursesFiles: idmChronologicalCoursesFiles,
  idmGuidelines: idmGuidelines,
  idmGuidelinesFiles: idmGuidelinesFiles,
  threeDModelsFiles: threeDModelsFiles,
  threeDModelsFilesFiles: threeDModelsFilesFiles,
  threeDModelsGallery: threeDModelsGallery,
  threeDModelsGalleryFiles: threeDModelsGalleryFiles,
  threeDModelsGuidelines: threeDModelsGuidelines,
  threeDModelsGuidelinesFiles: threeDModelsGuidelinesFiles,
  guidelinesArchArt: guidelinesArchArt,
  guidelinesArchArtFiles: guidelinesArchArtFiles,
  course: course,
  courseBasicInfo: courseBasicInfo,
  courseModule: courseModule,
  courseModulePart: courseModulePart,
  courseModulePartFile: courseModulePartFile,
  courseModulePartQuiz: courseModulePartQuiz,
  userQuizResult: userQuizResult,
  mooc: mooc,
  audio: audio,
  loginCount: loginCount,
});

export default reducer;
