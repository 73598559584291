import contents from "./data/contents";
import { languagesService } from "./languages";

const HtmlToReact = require("html-to-react");
const HtmlToReactParser = require("html-to-react").Parser;
const htmlToReactParser = new HtmlToReactParser();

class Content {
  constructor(id, name, languageId, load, text, html) {
    this.id = id;
    this.name = name;
    this.languageId = languageId;
    this.load = load;
    this.text = text;
    this.html = html;
  }
}

class ContentService {
  constructor() {
    this.contents = [];
    for (let c of contents) {
      let content = new Content(
        c.id,
        c.content_name,
        c.language_id,
        c.load,
        c.text,
        c.html ? c.html : false
      );
      if (content.html) {
        content.text = this._get_component(c.text);
      }
      this.contents.push(content);
    }
  }

  _get_component(text) {
    const isValidNode = function () {
      return true;
    };

    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(
      React
    );
    const processingInstructions = [
      {
        // Anything else
        shouldProcessNode: function (node) {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];

    return htmlToReactParser.parseWithInstructions(
      text,
      isValidNode,
      processingInstructions
    );
  }

  getContent(name, language_id) {
    for (let c of this.contents) {
      if (c.name == name && c.languageId == language_id) {
        return c.text;
      }
    }

    language_id = languagesService.getDefaultLanguage().id;
    return this.getContent(name, language_id);
  }
}

export const contentService = new ContentService();
