import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getAll} from "../../../../store/actions/consortium/consortiumMember";
import {getData as getImageData, getImagePath} from "../../../../store/actions/general/image";

function Consortium({name, image, handleKeyUp, handleClick}) {
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        getImageData(dispatch, image);
    }, [image]);

    return (
        <div className="item clickable" role={"button"} tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
            {imageData[image] ? <img alt={name} src={getImagePath(imageData[image])} style={{maxWidth: "20em"}}/> : <></>}
            <div className="info">
                <p className="name">{name}</p>
            </div>
        </div>
    )
}

const Consortiums = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const handleKeyUp = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/consortium");
        }
    };

    const handleClick = () => {
        navigate("/consortium");
    };

    const consortiumMembers = useSelector((state) => state.consortiumMember.consortiumMembers);

    useEffect(() => {
        getAll(dispatch);
    }, []);

    const parts = [];

    for (let idx in consortiumMembers) {
        let id = parseInt(parseInt(idx) / 3);
        if (parts[id] === undefined) {
            parts[id] = []
        }

        parts[id].push(consortiumMembers[idx]);
    }

    return (
        <div className={"consortiums"}>
            {
                parts.map((part, i) => {
                    return (
                        <div className={"row"} key={i}>
                            {
                                part.map((consortium, j) => {
                                    return <Consortium image={consortium.image} name={consortium.name}
                                                       key={j}
                                                       handleKeyUp={handleKeyUp} handleClick={handleClick}></Consortium>
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Consortiums;