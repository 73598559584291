import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    InputLabel, MenuItem, Select,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, get} from "../../../../store/actions/download";
import {getData as getFileData} from "../../../../store/actions/general/file";
import {getData as getImageData} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {raiseError} from "../../../../store/actions/general/error";
import {reset as resetEditor} from "../../../../store/actions/general/editor";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";

const AddDownload = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const username = useSelector((state) => state.login.userId);
    const id = location?.state?.download?.id;
    const download = useSelector((state) => state.downloads.downloadsFull[id]);
    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [name, setName] = useState(download ? download.name : '');
    const [type, setType] = useState(download ? download.type : 'Project Deliverables');
    const [file, setFile] = useState(download ? [download.file] : []);
    const [image, setImage] = useState(download ? [download.image] : []);

    useEffect(() => {
        if (id) {
            get(dispatch, id);
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (download) {
            setName(download.name);
            setType(download.type);
            setFile([download.file]);
            setImage([download.image]);

            if (download.file) {
                getFileData(dispatch, download.file);
            }

            if (download.image) {
                getImageData(dispatch, download.image);
            }
        }
    }, [download, dispatch]);

    const add = () => {
        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!"
            });
            return;
        }

        if (editorHTMLs["info"].length === 0) {
            raiseError(dispatch, {
                message: "Empty info!"
            });
            return;
        }

        if (type.length === 0) {
            raiseError(dispatch, {
                message: "Empty type!"
            });
            return;
        }

        save(dispatch, {
            "file": Object.values(newFiles["file"]),
            "image": Object.values(newFiles["image"])
        }, {
            name: name,
            info: editorHTMLs["info"],
            type: type,
            owner: username,
            status: "ADD",
            files: [],
            images: []
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        save(dispatch, {
            "file": Object.values(newFiles["file"]),
            "image": Object.values(newFiles["image"])
        }, {
            id: download.id,
            download_id: download.id,
            files: Object.keys(oldFiles["file"]),
            images: Object.keys(oldFiles["image"]),
            name: name,
            info: editorHTMLs["info"],
            type: type,
            owner: username,
            status: "UPDATE"
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    return (
        <Page title={"Add Download"} id={"addDownload"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Download" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing} columns={{xs: 12}}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && (name.length < 3) ? 'Enter a valid name.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <Select
                                                    fullWidth
                                                    id="type"
                                                    value={type}
                                                    label="Type"
                                                    onChange={handleChangeType}
                                                >
                                                    <MenuItem value={"Project Deliverables"}>Project
                                                        Deliverables</MenuItem>
                                                    <MenuItem value={"Scientific Publications"}>Scientific
                                                        Publications</MenuItem>
                                                    <MenuItem value={"Press Releases"}>Press Releases</MenuItem>
                                                    <MenuItem value={"Videos"}>Videos</MenuItem>
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <HTMLEditor id={"info"} html={download?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>File</InputLabel>
                                                <FileUploader id={"file"} files={file}
                                                              isFile={true} maxFileSize={10}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader id={"image"} files={image}
                                                              isFile={false} maxFileSize={10}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {download ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddDownload;
