import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/project/project";

export const initialState = {
  projects: [],
  projectsFull: {},
  waitingProjects: [],
};

const project = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        projectsFull: { ...state.projectsFull, [id]: action.data },
      };
    case GET_ALL.name:
      return {
        ...state,
        projectsFull: {},
        projects: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingProjects: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default project;
