import {GET_DATA} from "../../actions/general/file";

export const initialState = {
    fileData: {}
};

const files = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            let id = action.data.id;
            return {
                ...state,
                fileData: {...state.fileData, [id]: action.data}
            };
        default:
            return state;
    }
};

export default files;
