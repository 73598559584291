import {useRoutes} from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import EditorRoutes from "./EditorRoutes";
import UserRoutes from "./UserRoutes";
import WebSiteEditorRoutes from "./WebSiteEditorRoutes";
import MOOCRoutes from "./MoocRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes(
    [MainRoutes, EditorRoutes, UserRoutes, WebSiteEditorRoutes, MOOCRoutes],
    "/"
  );
}
