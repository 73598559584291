import languages from "./data/languages";

class Language {
    constructor(id, name, code) {
        this.id = id;
        this.name = name;
        this.code = code;
    }
}

class LanguagesService {
    constructor() {
    }

    getAll() {
        let results = [];
        for (let l of languages) {
            results.push(new Language(l.id, l.name, l.code));
        }
        return results;
    }

    get(name) {
        for (let language of this.getAll()) {
            if (language.name == name) {
                return language;
            }
        }
        return null;
    }

    getSelectedLanguage() {
        return this.getDefaultLanguage();
    }

    getDefaultLanguage() {
        return this.get("English");
    }
}

export const languagesService = new LanguagesService();