import { post, postFile } from "../fw/web_service_caller";
import { getAll as getAllApprovals } from "./actions/user/approvals";

export const getAction = (name, method) => {
  let method_name = null;
  switch (method) {
    case CommonActions.GET: {
      method_name = name + "/get";
      break;
    }
    case CommonActions.GET_ALL: {
      method_name = name + "/getAll";
      break;
    }
    case CommonActions.GET_ALL_WAITING: {
      method_name = name + "/getAllWaiting";
      break;
    }
    case CommonActions.SAVE: {
      method_name = name + "/save";
      break;
    }
    case CommonActions.DELETE: {
      method_name = name + "/delete";
      break;
    }
    case CommonActions.APPROVE: {
      method_name = name + "/approve";
      break;
    }
    default: {
      method_name = name + "/" + method;
    }
  }

  return {
    name: "@" + name + "/" + method,
    method: method_name,
  };
};

export const CommonActions = {
  GET: "GET",
  GET_ALL: "GET_ALL",
  GET_ALL_WAITING: "GET_ALL_WAITING",
  SAVE: "SAVE",
  APPROVE: "APPROVE",
  DELETE: "DELETE",
};

export const _getAll = (dispatch, action, data = {}) => {
  post(dispatch, action.name, action.method, data);
};

export const _get = (dispatch, action, data) => {
  post(dispatch, action.name, action.method, data);
};

export const _getAllWaiting = (dispatch, action) => {
  post(dispatch, action.name, action.method, {});
};

export const _save = (dispatch, action, files, data) => {
  postFile(dispatch, action.name, action.method, files, data);
};

export const _delete = (dispatch, action, data) => {
  post(dispatch, action.name, action.method, data);
};

export const _approve = (dispatch, action, data) => {
  post(
    (response) => {
      dispatch(response);
      getAllApprovals(dispatch);
    },
    action.name,
    action.method,
    data
  );
};
