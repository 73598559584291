import React, {useEffect, useState} from "react";
import "../../../../../assets/scss/components/page/public/Page.scss";
import "../../../../../assets/scss/Common.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../../../ui-component/page/public/section";
import Page from "../../../../../ui-component/page/public";
import {get as getMain} from "../../../../../store/actions/three_d_models/gallery/three_d_models_gallery";
import {
    getAll as getImages,
} from "../../../../../store/actions/three_d_models/gallery/three_d_models_gallery_files";
import {Parser as HtmlToReactParser} from "html-to-react";
import Gallery from "../../../../../ui-component/Gallery";
import {getImagePath, getData as getImageData} from "../../../../../store/actions/general/image";

const ThreeDModelsGallery = () => {
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const main = useSelector((state) => state.threeDModelsGallery.data);
    const list = useSelector((state) => state.threeDModelsGalleryFiles.list);
    const htmlToReactParser = new HtmlToReactParser();
    const imageData = useSelector((state) => state.image.imageData);
    const [galleryUrls, setGalleryUrls] = useState([]);

    useEffect(() => {
        getMain(dispatch);
        getImages(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (list) {
            for (let item of list) {
                getImageData(dispatch, item.image);
            }
        }
    }, [list, dispatch]);

    useEffect(() => {
        if(list) {
            let results = []
            for (let item of list) {
                if (imageData[item.image]) {
                    results.push({
                        "image": getImagePath(imageData[item.image]),
                        "text": item.info
                    });
                }
            }
            setGalleryUrls(results);
        }
    }, [imageData]);

    if (!list || !main) {
        return <></>;
    }

    return (
        <Page
            id={"three3ModelsGallery"}
            type={"style1"}
            title={contents ? contents["gallery"] : ""}
        >
            {main.info.trim().length > 0 ?
                <Section>
                    <div className={"left-side"}>
                        <p className={"title"}></p>
                    </div>
                    <div className={"right-side"}>
                        <div className={"content"}>{htmlToReactParser.parse(main.info)}</div>
                    </div>
                </Section> : <></>
            }
            <Section fullWidth={true}>
                <Gallery items={galleryUrls} dynamicHeight={true} isHtml={true}></Gallery>
            </Section>
        </Page>
    );
};

export default ThreeDModelsGallery;
