import React, {useEffect, useState} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/EventDetail.scss";
import Page from "../../../ui-component/page/public";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import {useLocation} from "react-router-dom";
import {getData as getImageData, getImagePath} from "../../../store/actions/general/image";
import {getData as getFileData, getFilePath} from "../../../store/actions/general/file";
import {Parser as HtmlToReactParser} from "html-to-react";
import Gallery from "../../../ui-component/Gallery";
import {getSimpleDate} from "../../../util/date_util";
import {get} from '../../../store/actions/news_events/events'

const EventDetail = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const event = location?.state?.event;
    const [galleryUrls, setGalleryUrls] = useState([]);
    const [presentationData, setPresentationData] = useState([]);

    const contents = useSelector((state) => state.main.contents);
    const imageData = useSelector((state) => state.image.imageData);
    const fileData = useSelector((state) => state.file.fileData);
    const eventData = useSelector((state) => state.event.event[event.id]);
    const htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        if (event.id != null) {
            get(dispatch, event.id);
        }
    }, [event?.id]);

    useEffect(() => {
        if (eventData?.agenda != null) {
            getFileData(dispatch, eventData.agenda);
        }
    }, [eventData?.agenda]);

    useEffect(() => {
        if (eventData?.gallery) {
            for (let item of eventData.gallery) {
                getImageData(dispatch, item);
            }
        }
    }, [eventData?.gallery]);

    useEffect(() => {
        let galleries = []
        if (eventData?.gallery) {
            for (let item of eventData.gallery) {
                if (imageData[item]) {
                    galleries.push({
                        "image": getImagePath(imageData[item])
                    });
                }
            }
        }
        setGalleryUrls(galleries);
    }, [imageData]);

    useEffect(() => {
        if (eventData?.presentations) {
            for (let item of eventData.presentations) {
                getFileData(dispatch, item);
            }
        }
    }, [eventData?.presentations]);

    useEffect(() => {
        let presentations = []
        if (eventData?.presentations) {
            for (let item of eventData.presentations) {
                presentations.push(fileData[item]);
            }
        }
        setPresentationData(presentations);
    }, [fileData]);

    if (!eventData) {
        return <></>;
    }

    return (
        <Page id={"eventDetail"} type={"style1"} title={eventData.name}
              subTitle={"(" + getSimpleDate(eventData.start_date) + " - " + getSimpleDate(eventData.end_date) + ")"}>
            {
                galleryUrls.length === 0 ? <></> :
                    <Section fullWidth={true}>
                        <Gallery items={galleryUrls} timeInterval={6000}></Gallery>
                    </Section>
            }
            <Section>
                <div className={"left-side"}>
                    <div className={"info"}>{htmlToReactParser.parse(eventData?.info)}</div>
                </div>
                <div className={"right-side"}>
                    <div className={"agenda"}>
                        {fileData[eventData.agenda] ?
                            <a href={getFilePath(fileData[eventData.agenda])} download={fileData[eventData.agenda].name}
                               target={"_blank"}>{contents ? contents["event_agenda_text"] : ""}</a> : <></>
                        }
                    </div>
                    <div className={"presentations"}>
                        <p>{contents ? contents["event_presentations_title"] : ""}</p>
                        {
                            presentationData.length === 0 ? <></> : presentationData?.map((presentation, i) => {
                                if (!presentation || presentation.name == null) {
                                    return <></>
                                }

                                return <a href={getFilePath(presentation)} target={"_blank"} download={presentation.name}
                                          key={i}>{presentation?.name?.replace(".pdf", "")}
                                    <span> ({presentation?.type?.replace("application/", "")}, {presentation?.size} KB)</span></a>
                            })
                        }
                    </div>
                    <div className={"divider"}></div>
                    <div className={"detail"}>
                        <div className={"details"}>
                            <div className={"title"}>{contents ? contents["event_details_title"] : ""}</div>
                            <div className={"subTitle"}>{contents ? contents["event_start_title"] : ""}:</div>
                            <div className={"value"}>{getSimpleDate(eventData.start_date)}</div>
                            <div className={"subTitle"}>{contents ? contents["event_end_title"] : ""}:</div>
                            <div className={"value"}>{getSimpleDate(eventData.end_date)}</div>
                            <div className={"subTitle"}>{contents ? contents["event_event_category_title"] : ""}:</div>
                            <div className={"value"}>{eventData.category}</div>
                        </div>
                        <div className={"organizer"}>
                            <div className={"title"}>{contents ? contents["event_organizer_title"] : ""}</div>
                            <div className={"value"}>{eventData.organizer}</div>
                        </div>
                        <div className={"venues"}>
                            <div className={"title"}>{contents ? contents["event_venues_title"] : ""}</div>
                            {htmlToReactParser.parse(eventData?.venues)}
                        </div>
                    </div>
                </div>
            </Section>
        </Page>
    );
};

export default EventDetail;