import "../../../../../assets/scss/components/page/private/Page.scss";
import React, {useEffect, useState} from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, InputLabel, MenuItem, Select, Stack, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, SAVE,} from "../../../../../store/actions/mooc/course/module_part_quiz";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {raiseError} from "../../../../../store/actions/general/error";
import {reset as resetEditor} from "../../../../../store/actions/general/editor";
import {getId, getTitle} from "../../../private_menu_util";
import TextField from "@mui/material/TextField";

const AddCourseModulePartQuiz = () => {
    const MENU = "MOOCs - Course Module Part Quiz";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const contents = useSelector((state) => state.main.contents);

    const username = useSelector((state) => state.login.userId);
    const data = location?.state?.data;
    const modulePart = location?.state?.modulePart;

    const [language, setLanguage] = useState(data ? data.type : "en");
    const [questions, setQuestions] = useState(data ? data.questions : {});
    const [choices, setChoices] = useState(data ? data.choices : {});
    const [answers, setAnswers] = useState(data ? data.answers : {});
    const [marks, setMarks] = useState(data ? data.marks : {});

    const loading = useSelector((state) => state.loading);

    useEffect(() => {
        if (data) {
            setLanguage(data.language);
        }
    }, [data]);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case SAVE.method: {
                resetEditor(dispatch);
                navigate("/editor/home");
                break;
            }
        }
    }, [loading?.completedOperation, dispatch, navigate]);

    const validate = () => {
        if (language.length === 0) {
            raiseError(dispatch, {
                message: "Empty language!",
            });
            return;
        }

        for (let order of Object.keys(questions)) {
            let question = questions[order];

            if (question.trim().length === 0) {
                raiseError(dispatch, {
                    message: "Empty Question!",
                });
                return false;
            }

            let mark = marks[order];
            if (mark <= 0) {
                raiseError(dispatch, {
                    message: "Incorrect Mark! Should be greater than 0.",
                });
                return false;
            }

            for (let choice of choices[order]) {
                if (choice.trim().length === 0) {
                    raiseError(dispatch, {
                        message: "Incorrect Choices! All choices should be filled.",
                    });
                    return false;
                }
            }

            let answerMarked = false;
            for (let answer of answers[order]) {
                answerMarked = answerMarked || answer;
            }

            if (!answerMarked) {
                raiseError(dispatch, {
                    message: "Incorrect Answers! At least one answer should be marked.",
                });
                return false;
            }
        }

        return true;
    };

    const add = () => {
        let result = validate();

        if (!result) {
            return;
        }

        save(
            dispatch,
            {
                file: [],
            },
            {
                module_part_id: modulePart.id,
                language: language,
                questions: questions,
                choices: choices,
                answers: answers,
                marks: marks,
                owner: username,
                status: "ADD",
            }
        );
        resetEditor(dispatch);
    };

    const edit = () => {
        let result = validate();
        if (!result) {
            return;
        }

        save(
            dispatch,
            {
                file: [],
            },
            {
                id: data.id,
                main_id: data.id,
                module_part_id: data.module_part_id,
                language: language,
                questions: questions,
                choices: choices,
                answers: answers,
                marks: marks,
                owner: username,
                status: "UPDATE",
            }
        );
    };

    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value);
    };

    function handleChangeQuestion(order, value) {
        setQuestions({
            ...questions,
            [order]: value,
        });
    }


    function handleChangeMark(order, value) {
        setMarks({
            ...marks,
            [order]: value,
        });
    }

    function handleChangeChoice(order, choice, value) {
        let sub = choices[order];
        sub[choice] = value;
        setChoices({
            ...choices,
            [order]: sub,
        });
    }

    function handleChangeAnswer(order, choice, value) {
        let sub = answers[order];
        sub[choice] = value;
        setAnswers({
            ...answers,
            [order]: sub,
        });
    }

    return (
        <Page
            title={"Edit " + contents[getTitle(MENU)]}
            id={getId(MENU)}
            type={"style1"}
        >
            <Section centered={true}>
                <MainCard
                    title={"Edit " + contents[getTitle(MENU)]}
                    style={{width: "100%"}}
                >
                    <Grid container spacing={gridSpacing} columns={{xs: 12}}>
                        <Grid item xs={12} sm={12}>
                            <Select
                                fullWidth
                                id="language"
                                value={language}
                                label="Language"
                                onChange={handleChangeLanguage}
                            >
                                <MenuItem value={"en"}>
                                    English
                                </MenuItem>
                                <MenuItem value={"tr"}>
                                    Turkish
                                </MenuItem>
                                <MenuItem value={"it"}>
                                    Italian
                                </MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant="contained"
                                style={{
                                    padding: "9px 16px",
                                    width: "100%",
                                    height: "100%",
                                }}
                                onClick={() => {
                                    let order = Object.keys(questions).length;
                                    setQuestions({
                                        ...questions,
                                        [order]: "",
                                    });
                                    setChoices({
                                        ...choices,
                                        [order]: ["", "", "", ""],
                                    });
                                    setAnswers({
                                        ...answers,
                                        [order]: [false, false, false, false],
                                    });
                                    setMarks({
                                        ...marks,
                                        [order]: 1,
                                    });
                                }}
                            >
                                Add Question
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(questions).length === 0 ? (
                                <></>
                            ) : (
                                <>
                                    <TableContainer component={Paper}>
                                        <Table size="medium" aria-label="a dense table">
                                            <TableBody>
                                                {Object.keys(questions)?.map((order, i) => {
                                                    return (
                                                        <Table size="medium" aria-label="a dense table">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <InputLabel style={{color: "blue"}}>
                                                                        Question {parseInt(order) + 1}
                                                                    </InputLabel>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Question"
                                                                            id="question"
                                                                            value={questions[order]}
                                                                            onChange={(e) =>
                                                                                handleChangeQuestion(
                                                                                    order,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            error={questions[order].length < 3}
                                                                            helperText={
                                                                                questions[order] &&
                                                                                questions[order].length < 3
                                                                                    ? "Enter a valid Question."
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <TextField
                                                                            fullWidth
                                                                            label="Mark"
                                                                            id="mark"
                                                                            value={marks[order]}
                                                                            onChange={(e) =>
                                                                                handleChangeMark(order, e.target.value)
                                                                            }
                                                                            error={marks[order].length === 0}
                                                                            helperText={
                                                                                marks[order] &&
                                                                                marks[order].length === 0
                                                                                    ? "Enter a valid Mark."
                                                                                    : ""
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <Table
                                                                            size="medium"
                                                                            aria-label="a dense table"
                                                                        >
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">
                                                                                        Choice
                                                                                    </TableCell>
                                                                                    <TableCell align="left">
                                                                                        Is Answer
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {choices[order]?.map((choice, j) => {
                                                                                    return (
                                                                                        <TableRow>
                                                                                            <TableCell
                                                                                                component={"td"}
                                                                                                scope="row"
                                                                                            >
                                                                                                <TextField
                                                                                                    fullWidth
                                                                                                    label={"Choice " + (j + 1)}
                                                                                                    id="choice"
                                                                                                    value={choice}
                                                                                                    onChange={(e) =>
                                                                                                        handleChangeChoice(
                                                                                                            order,
                                                                                                            j,
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    }
                                                                                                    error={choice.length === 0}
                                                                                                    helperText={
                                                                                                        choice &&
                                                                                                        choice.length === 0
                                                                                                            ? "Enter a valid Choice."
                                                                                                            : ""
                                                                                                    }
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Checkbox
                                                                                                    checked={answers[order][j]}
                                                                                                    onChange={(e) =>
                                                                                                        handleChangeAnswer(
                                                                                                            order,
                                                                                                            j,
                                                                                                            e.target.checked
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}
                            <Stack direction="row" spacing={2} style={{marginTop: "1rem"}}>
                                {data ? (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={edit}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddCourseModulePartQuiz;
