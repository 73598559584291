import {
  APPROVE,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/home/gallery";

export const initialState = {
  galleries: [],
  waitingGalleries: [],
};

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL.name:
      return {
        ...state,
        galleries: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingGalleries: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default gallery;
