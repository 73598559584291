import "../../../../assets/scss/components/page/private/Page.scss";
import React, {useEffect, useState} from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, InputLabel, Stack, TableBody, TableCell, TableRow,} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, SAVE} from "../../../../store/actions/news_events/news";
import {getData as getImageData, getData,} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {raiseError} from "../../../../store/actions/general/error";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetEditor} from "../../../../store/actions/general/editor";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";
import {getId, getTitle} from "../../../public/public_menu_util";

const AddNews = () => {
    const MENU = "News";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const contents = useSelector((state) => state.main.contents);

    const username = useSelector((state) => state.login.userId);
    const data = location?.state?.data;

    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [name, setName] = useState(data ? data.name : "");
    const [date, setDate] = useState(
        data ? dayjs(data.date, "YYYY-MM-DD HH:mm:ss.SSS") : dayjs()
    );
    const [image, setImage] = useState(data ? [data.image] : []);
    const [gallery, setGallery] = useState(data ? data.gallery : []);
    const [highlighted, setHighlighted] = useState(data ? data.highlighted : false);
    const loading = useSelector((state) => state.loading);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case SAVE.method: {
                resetEditor(dispatch);
                resetFileUploader(dispatch);
                navigate("/website-editor/home");
                break;
            }
        }
    }, [loading?.completedOperation, dispatch, navigate]);

    useEffect(() => {
        if (data) {
            setName(data.name);
            setDate(dayjs(data.date, "YYYY-MM-DD HH:mm:ss.SSS"));
            setImage([data.image]);
            setGallery(data.gallery);
            setHighlighted(data.highlighted);

            if (data.image) {
                getData(dispatch, data.image);
            }

            if (data.gallery) {
                for (let item of data.gallery) {
                    getImageData(dispatch, item);
                }
            }
        }
    }, [data, dispatch]);

    let validate = () => {
        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!",
            });

            return false;
        }

        if (
            !Object.keys(editorHTMLs).includes("info") &&
            editorHTMLs["info"].length === 0
        ) {
            raiseError(dispatch, {
                message: "Empty info!",
            });
            return false;
        }

        if (
            (Object.values(oldFiles["image"]).length === 0 && Object.values(newFiles["image"]).length === 0)
        ) {
            raiseError(dispatch, {
                message: "Empty image!",
            });
            return false;
        }

        return true;
    }

    const add = () => {
        let valid = validate();
        if (!valid) {
            return
        }

        save(
            dispatch,
            {
                image: Object.values(newFiles["image"]),
                gallery: Object.values(newFiles["gallery"]),
            },
            {
                name: name,
                info: editorHTMLs["info"],
                date: date,
                highlighted: highlighted,
                highlight_text: editorHTMLs["highlight_text"],
                owner: username,
                status: "ADD",
                images: [],
                gallery: [],
            }
        );
    };

    const edit = () => {
        let valid = validate();
        if (!valid) {
            return
        }

        save(
            dispatch,
            {
                image: Object.values(newFiles["image"]),
                gallery: Object.values(newFiles["gallery"]),
            },
            {
                id: data.id,
                main_id: data.id,
                images: Object.keys(oldFiles["image"]),
                gallery: Object.keys(oldFiles["gallery"]),
                name: name,
                info: editorHTMLs["info"],
                highlighted: highlighted,
                highlight_text: editorHTMLs["highlight_text"],
                date: date,
                owner: username,
                status: "UPDATE",
            }
        );
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeDate = (event) => {
        setDate(event);
    };

    const handleHighlighted = (event) => {
        setHighlighted(event.target.checked);
    };

    return (
        <Page
            title={"Add " + contents[getTitle(MENU)]}
            id={getId(MENU)}
            type={"style1"}
        >
            <Section centered={true}>
                <MainCard
                    title={"Add/Edit " + contents[getTitle(MENU)]}
                    style={{width: "100%"}}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && name.length < 3 ? "Enter a valid name." : ""
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <InputLabel>Info</InputLabel>
                                                <HTMLEditor id={"info"} html={data?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Highlighted</InputLabel>
                                                <Checkbox value={highlighted} checked={highlighted}
                                                          onChange={handleHighlighted}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <InputLabel>Highlight Text</InputLabel>
                                                <HTMLEditor id={"highlight_text"} html={data?.highlight_text}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader
                                                    id={"image"}
                                                    files={image}
                                                    isImage={true}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={date}
                                                        label={"Date"}
                                                        onChange={handleChangeDate}
                                                        format={"DD/MM/YYYY"}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: "none"}}>
                                                <InputLabel>Gallery</InputLabel>
                                                <FileUploader
                                                    id={"gallery"}
                                                    files={gallery}
                                                    isImage={true}
                                                    maxFileAllowed={30}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: "1rem"}}>
                                {data ? (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={edit}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddNews;
