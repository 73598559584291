import "../../../../../assets/scss/components/page/private/Page.scss";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { approve } from "../../../../../store/actions/three_d_models/files/three_d_models_files_files";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { getId, getTitle } from "../../../private_menu_util";
import React, { useEffect } from "react";
import {
  getData as getFileData,
  getFilePath,
} from "../../../../../store/actions/general/file";
import Image from "mui-image";
import {getImagePath} from "../../../../../store/actions/general/image";
import file from "../../../../../store/reducers/general/file";

const HtmlToReactParser = require("html-to-react").Parser;

const ViewThreeDModelFilesFilesDetail = () => {
  const MENU = "3D Models - Files Files";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);
  const location = useLocation();
  const data = location?.state?.data;
  const fileData = useSelector((state) => state.file.fileData);

  const htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    if (data.presentation) {
      getFileData(dispatch, data.presentation);
    }

    if (data.files) {
      for (let item of data.files) {
        getFileData(dispatch, item);
      }
    }
  }, [data?.files, data?.presentation]);

  const handleApprove = (status) => {
    approve(dispatch, {
      data: data,
      status: status,
    });
    navigate("/editor/home");
  };

  return (
    <Page
      title={contents[getTitle(MENU)] + " Detail"}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard title={contents[getTitle(MENU)]} style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Name</InputLabel>
                        {data.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Type</InputLabel>
                        {data.type}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Presentation</InputLabel>
                        {fileData[data?.presentation] ? (
                            <Link
                                href={getFilePath(fileData[data?.presentation])}
                                color="inherit"
                                target={"_blank"}
                            >
                              {fileData[data?.presentation].file.name}
                            </Link>
                        ) : (
                            <></>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Info</InputLabel>
                        {htmlToReactParser.parse(data.info)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>
                          Files
                        </InputLabel>
                        {data?.files?.map((file, i) => {
                          if (!file) {
                            return <></>;
                          }

                          let data = fileData[file];
                          if (!data) {
                            return <></>;
                          }

                          return (
                              <Link
                                  href={getFilePath(data)}
                                  color="inherit"
                                  target={"_blank"}
                                  key={"files_" + i}
                              >
                                {data.file.name}
                                <br />
                              </Link>
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {(userType === "Web Site Editor" || userType === "Editor") &&
                data.owner !== userName ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("APPROVE")}
                    >
                      Approve
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("REJECT")}
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {data.owner === userName ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={() => handleApprove("REJECT")}
                  >
                    Undo
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default ViewThreeDModelFilesFilesDetail;
