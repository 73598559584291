// action - state management
import * as actionTypes from '../../actions/general/editor';

export const initialState = {
    editorHTMLs: {}
};

// ==============================|| PATIENTS REDUCER ||============================== //

const editor = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET:
            return {
                ...state,
                editorHTMLs: {}
            };
        case actionTypes.SET_HTML: {
            let id = action.id;
            return {
                ...state,
                editorHTMLs: {...state.editorHTMLs, [id]: action.html}
            };
        }
        default:
            return state;
    }
};

export default editor;
