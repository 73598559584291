import React, {useEffect} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Objectives.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import {getAll, get} from "../../../store/actions/objectives";
import {getData, getImagePath} from "../../../store/actions/general/image";
import {Parser as HtmlToReactParser} from "html-to-react";

function Objective({obj}) {
    const dispatch = useDispatch();

    const htmlToReactParser = new HtmlToReactParser();
    const contents = useSelector((state) => state.main.contents);
    const imageData = useSelector((state) => state.image.imageData);
    const objectivesFull = useSelector((state) => state.objectives.objectivesFull);

    let objective = objectivesFull[obj?.id];

    useEffect(() => {
        if (obj?.image) {
            getData(dispatch, obj?.image);
        }
    }, [obj?.image]);

    useEffect(() => {
        if (obj?.id) {
            get(dispatch, obj?.id);
        }
    }, [obj?.id]);

    if (!objective) {
        return <></>
    }

    if (imageData[objective?.image]) {
        if (objective.image_full_width) {
            return (
                <Section fullWidth>
                    {imageData[objective?.image] ? <img
                        alt={imageData[objective?.image].name}
                        className={"image"}
                        src={getImagePath(imageData[objective?.image])}
                    /> : <></>}
                </Section>
            )
        } else {
            return (
                <Section>
                    <div className={"left-side"}>
                    </div>
                    <div className={"right-side"}>
                        {imageData[objective?.image] ? <img
                            alt={imageData[objective?.image].name}
                            className={"image"}
                            src={getImagePath(imageData[objective?.image])} width={"100%"}
                        /> : <></>}
                    </div>
                </Section>
            )
        }
    }

    return (
        <Section>
            {
                objective.item_order === 1 ?
                    <div className={"left-side"}>
                        <div className={"title"}>
                            <p>{contents ? contents["objectives"] : ''}</p>
                        </div>
                    </div> : <div className={"left-side"}>
                    </div>
            }
            <div className={"right-side"}>
                <div className={"content"}>{htmlToReactParser.parse(objective.info)}</div>
            </div>
        </Section>);

}

const Objectives = () => {
    const dispatch = useDispatch();

    const objectives = useSelector((state) => state.objectives.objectives);

    useEffect(() => {
        getAll(dispatch);
    }, []);


    if (!objectives) {
        return <></>;
    }

    let values = Object.values(objectives).sort((a, b) => {
        return a.item_order - b.item_order
    });

    return (
        <Page id={"objectives"} type={"style1"}>
            {
                values.map((objective, i) => {
                    return <Objective obj={objective} key={i}></Objective>
                })
            }
        </Page>
    );
};

export default Objectives;
