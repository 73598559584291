import React, { useEffect } from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Parser as HtmlToReactParser } from "html-to-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getData as getImageData,
  getImagePath,
} from "../../../../../store/actions/general/image";
import { useNavigate } from "react-router-dom";
import { setCourse } from "../../../../../store/actions/mooc/mooc";

const Course = ({ course }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const imageData = useSelector((state) => state.image.imageData);
  const htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    if (course.image) {
      getImageData(dispatch, course.image);
    }
  }, [dispatch, course]);

  const handleClick = () => {
    setCourse(dispatch, course);
    navigate("/user/mooc/course", {
      state: {
        course: course,
      },
    });
  };

  return (
    <div
      className={"clickable"}
      onKeyUp={handleClick}
      onClick={handleClick}
      role={"button"}
      tabIndex={0}
    >
      <Card sx={{ maxWidth: 345 }}>
        {imageData[course?.image] ? (
          <CardMedia
            component="img"
            alt="green iguana"
            height="180"
            image={getImagePath(imageData[course?.image])}
          />
        ) : (
          <></>
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            textAlign={"center"}
          >
            {course.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {htmlToReactParser.parse(course.info)}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Course;
