import React, {useEffect, useState} from "react";
import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {List, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import IconModuleFile from "../../../../assets/images/mooc/tree/module_file.png";
import IconModuleDocument from "../../../../assets/images/mooc/tree/module_document.png";
import IconModuleQuiz from "../../../../assets/images/mooc/tree/module_quiz.png";
import IconEnglishFlag from "../../../../assets/images/mooc/tree/flags_en.png";
import IconItalyFlag from "../../../../assets/images/mooc/tree/flags_it.png";
import IconTurkeyFlag from "../../../../assets/images/mooc/tree/flags_tr.png";
import {getByModulePart as getAllFiles} from "../../../../store/actions/mooc/course/module_part_file";
import {getByModulePart as getAllQuizzes} from "../../../../store/actions/mooc/course/module_part_quiz";
import Box from "@mui/material/Box";
import Image from "mui-image";
import {Parser as HtmlToReactParser} from "html-to-react";
import {getData} from "../../../../store/actions/general/file";
import {raiseError} from "../../../../store/actions/general/error";

const CourseModulePart = () => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.login.userType);
    let navigate = useNavigate();
    const location = useLocation();
    const modulePart = location?.state?.modulePart;
    const fileData = useSelector((state) => state.file.fileData);
    const htmlToReactParser = new HtmlToReactParser();

    const fileList = useSelector(
        (state) => state.courseModulePartFile.courseModulePartFiles
    );

    const quizList = useSelector(
        (state) => state.courseModulePartQuiz.courseModulePartQuizzes
    );

    const [lectureDocuments, setLectureDocuments] = useState([]);
    const [additionalMaterials, setAdditionalMaterials] = useState([]);
    const [quizzes, setQuizzes] = useState([]);

    useEffect(() => {
        if (!userType) {
            raiseError(dispatch, {
                type: "page",
                message: "You don't have permission to view this page.\nPlease login.",
            });
        }
    }, [dispatch, userType]);

    useEffect(() => {
        if (modulePart) {
            getAllFiles(dispatch, modulePart.id);
            getAllQuizzes(dispatch, modulePart.id);
        }
    }, [dispatch, modulePart]);

    useEffect(() => {
        for (let file of lectureDocuments) {
            getData(dispatch, file.file);
        }
    }, [dispatch, lectureDocuments]);

    useEffect(() => {
        for (let file of additionalMaterials) {
            getData(dispatch, file.file);
        }
    }, [dispatch, additionalMaterials]);

    useEffect(() => {
        let data = [];
        if (quizList) {
            for (let quiz of quizList) {
                if (quiz.module_part_id === modulePart.id) {
                    data.push(quiz);
                }
            }
        }
        setQuizzes(data);
    }, [dispatch, modulePart, quizList]);

    useEffect(() => {
        if (fileList) {
            let lectureDocuments = [];
            let additionalMaterials = [];
            for (let file of fileList) {
                if (file.module_part_id === modulePart.id) {
                    switch (file.type) {
                        case "Lecture Document": {
                            lectureDocuments.push(file);
                            break;
                        }
                        case "Additional Material": {
                            additionalMaterials.push(file);
                            break;
                        }
                    }
                }
            }

            setLectureDocuments(lectureDocuments);
            setAdditionalMaterials(additionalMaterials);
        }
    }, [dispatch, modulePart, fileList]);

    const handleFileClick = (title, data) => {
        navigate("/user/mooc/module-part-file", {
            state: {
                file: data,
                title: title,
            },
        });
    };

    const handleQuizClick = (quiz) => {
        navigate("/user/mooc/module-part-quiz", {
            state: {
                data: quiz,
                modulePart: modulePart,
            },
        });
    };

    if (!module) {
        return <></>;
    }

    return (
        <Page title={modulePart.name} id={"courseModulePart"} type={"mooc"}>
            <Section>{htmlToReactParser.parse(modulePart.info)}</Section>

            {lectureDocuments.length === 0 ? (
                <></>
            ) : (
                <Section title={"Lecture Notes"}>
                    <List style={{width: '100%'}}>
                        {lectureDocuments?.map((doc, i) => {
                            let data = fileData[doc.file];
                            if (!data) {
                                return <></>;
                            }

                            return (
                                <ListItemButton
                                    key={"Lecture_Notes_" + i}
                                    onClick={() => handleFileClick(doc.name, data)}
                                    className={'clickable'}
                                    style={{display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center'
                                    }}
                                >
                                    <ListItemIcon>
                                        <Box color="inherit" sx={{mr: 1}}>
                                            <Image src={IconModuleFile} style={{width: "7em"}}/>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={doc.name}
                                        secondary={htmlToReactParser.parse(doc.info)}
                                        style={{textAlign: 'center', marginBottom: 0}}
                                    />
                                    <ListItemIcon style={{marginTop: '-1.5em'}}>
                                        <Box color="inherit" sx={{mr: 1}}>
                                            {doc.language === 'en' ? <Image src={IconEnglishFlag}
                                                                            style={{
                                                                                width: "2em",
                                                                                marginLeft: '1em'
                                                                            }}/> : doc.language === 'tr' ?
                                                <Image src={IconTurkeyFlag}
                                                       style={{
                                                           width: "2em",
                                                           marginLeft: '1em'
                                                       }}/> : doc.language === 'it' ?
                                                    <Image src={IconItalyFlag}
                                                           style={{width: "2em", marginLeft: '1em'}}/> : <></>}

                                        </Box>
                                    </ListItemIcon>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Section>
            )}
            {additionalMaterials.length === 0 ? (
                <></>
            ) : (
                <Section title={"Additional Materials"}>
                    <List>
                        {additionalMaterials?.map((doc, i) => {
                            let data = fileData[doc.file];
                            if (!data) {
                                return <></>;
                            }

                            return (
                                <ListItemButton
                                    key={"Additional_Materials_" + i}
                                    onClick={() => handleFileClick(doc.name, data)}
                                    className={"clickable"}
                                    style={{display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ListItemIcon>
                                        <Box color="inherit" sx={{mr: 1}}>
                                            <Image
                                                src={IconModuleDocument}
                                                style={{width: "7em"}}
                                            />
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={doc.name}
                                        secondary={htmlToReactParser.parse(doc.info)}
                                    />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Section>
            )}
            {quizzes.length === 0 ? (
                <></>
            ) : (
                <Section title={"Quizzes"}>
                    <List>
                        {quizzes?.map((quiz, i) => {
                            return (
                                <ListItemButton
                                    key={"Quiz_" + i}
                                    onClick={() => handleQuizClick(quiz)}
                                    className={"clickable"}
                                    style={{display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ListItemIcon>
                                        <Box color="inherit" sx={{mr: 1}}>
                                            <Image src={IconModuleQuiz} style={{width: "7em"}}/>
                                        </Box>
                                    </ListItemIcon>
                                    <ListItemText primary={"Quiz"}/>
                                    {quiz.language === 'en' ? <Image src={IconEnglishFlag}
                                                                     style={{
                                                                         width: "2em"
                                                                     }}/> : quiz.language === 'tr' ?
                                        <Image src={IconTurkeyFlag}
                                               style={{
                                                   width: "2em",
                                               }}/> : quiz.language === 'it' ?
                                            <Image src={IconItalyFlag}
                                                   style={{width: "2em"}}/> : <></>}
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Section>
            )}
        </Page>
    );
};

export default CourseModulePart;
