import {post} from "../../../fw/web_service_caller";
export const GET_DATA = '@file/GET_DATA';

export const getData = (dispatch, id) => {
    post(dispatch, GET_DATA, 'getFileData', id);
};

export const getFilePath = (file) => {
    let data = {
        "common_file_id": file["common_file_id"]
    };
    return process.env.REACT_APP_FTP_SERVER_URL + "return-files?" + new URLSearchParams(data);
};