import "../../../../../assets/scss/components/page/private/Page.scss";
import { useEffect } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  save,
  SAVE,
} from "../../../../../store/actions/mooc/course/course_basic_info";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { raiseError } from "../../../../../store/actions/general/error";
import { reset as resetEditor } from "../../../../../store/actions/general/editor";
import { getId, getTitle } from "../../../private_menu_util";
import HTMLEditor from "../../../../../ui-component/Editor";

const AddCourseBasicInfo = () => {
  const MENU = "MOOCs - Course Basic Info";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const contents = useSelector((state) => state.main.contents);
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);

  const username = useSelector((state) => state.login.userId);
  const data = location?.state?.data;

  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        resetEditor(dispatch);
        navigate("/editor/home");
        break;
      }
    }
  }, [loading?.completedOperation, dispatch, navigate]);

  const edit = () => {
    if (
      !Object.keys(editorHTMLs).includes("about") &&
      editorHTMLs["about"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - About the Course!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("structure_and_modules") &&
      editorHTMLs["structure_and_modules"].length === 0
    ) {
      raiseError(dispatch, {
        message:
          "Empty - Structure of Course and Short Description of Modules!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("project_info") &&
      editorHTMLs["project_info"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - Project Info!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("prerequisite_requirements") &&
      editorHTMLs["prerequisite_requirements"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - Prerequisite Requirements!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("quizzes_tests_scores") &&
      editorHTMLs["quizzes_tests_scores"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - Info about Quizzes, Tests and Scores!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("certificate") &&
      editorHTMLs["certificate"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - Rules of Certificate of Accomplishment!",
      });
      return;
    }

    if (
      !Object.keys(editorHTMLs).includes("authors") &&
      editorHTMLs["authors"].length === 0
    ) {
      raiseError(dispatch, {
        message: "Empty - Course Authors!",
      });
      return;
    }

    save(
      dispatch,
      {
        file: [],
      },
      {
        main_id: data.id,
        course_id: data.course_id,
        about: editorHTMLs["about"],
        structure_and_modules: editorHTMLs["structure_and_modules"],
        project_info: editorHTMLs["project_info"],
        prerequisite_requirements: editorHTMLs["prerequisite_requirements"],
        quizzes_tests_scores: editorHTMLs["quizzes_tests_scores"],
        certificate: editorHTMLs["certificate"],
        authors: editorHTMLs["authors"],
        owner: username,
        status: "UPDATE",
        files: [],
      }
    );

    resetEditor(dispatch);
  };

  return (
    <Page
      title={"Edit " + contents[getTitle(MENU)]}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard
          title={"Edit " + contents[getTitle(MENU)]}
          style={{ width: "100%" }}
        >
          <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>About the Course</InputLabel>
                        <HTMLEditor id={"about"} html={data?.about} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>
                          Structure of Course and Short Description of Modules
                        </InputLabel>
                        <HTMLEditor
                          id={"structure_and_modules"}
                          html={data?.structure_and_modules}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>Project Info</InputLabel>
                        <HTMLEditor
                          id={"project_info"}
                          html={data?.project_info}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>Prerequisite Requirements</InputLabel>
                        <HTMLEditor
                          id={"prerequisite_requirements"}
                          html={data?.prerequisite_requirements}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>
                          Info about Quizzes, Tests and Scores
                        </InputLabel>
                        <HTMLEditor
                          id={"quizzes_tests_scores"}
                          html={data?.quizzes_tests_scores}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>
                          Rules of Certificate of Accomplishment
                        </InputLabel>
                        <HTMLEditor
                          id={"certificate"}
                          html={data?.certificate}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>Course Authors</InputLabel>
                        <HTMLEditor id={"authors"} html={data?.authors} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {data ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default AddCourseBasicInfo;
