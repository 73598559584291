import "../../../../assets/scss/components/page/private/Page.scss";
import React, { useEffect, useState } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  del,
  getAll,
  MAKE_EDITOR,
  makeEditor,
} from "../../../../store/actions/user/user";
import { raiseError } from "../../../../store/actions/general/error";
import IconDelete from "../../../../assets/images/operations/icon-delete.png";
import IconMakeWebSiteEditor from "../../../../assets/images/operations/icon-make-web-editor.png";
import IconMakeEditor from "../../../../assets/images/operations/icon-make-editor.png";
import IconMakeContentEditor from "../../../../assets/images/operations/icon-make-content-editor.png";
import IconMakeUser from "../../../../assets/images/operations/icon-make-user.png";
import IconApprove from "../../../../assets/images/operations/icon-approve.png";
import Notice from "../../../../ui-component/Notice";
import Message from "../../../../ui-component/Message";

const ApproveUsers = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const users = useSelector((state) => state.user.list);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case MAKE_EDITOR.method: {
        setSaveSuccess(true);
        getAll(dispatch);
        break;
      }
    }
  }, [loading?.completedOperation]);

  useEffect(() => {
    if (!("Web Site Editor" === userType || "Editor" === userType)) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    } else {
      getAll(dispatch);
    }
  }, [userType]);

  const [open, setOpen] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  const handleDeleteOpen = (userId) => {
    setCurrentUser(userId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const _makeEditor = (id, type) => {
    makeEditor(dispatch, {
      id: id,
      type: type,
    });
  };

  const _deleteUser = (id) => {
    setOpen(false);
    del(dispatch, {
      id: id,
    });
  };

  if ("Web Site Editor" === userType || "Editor" === userType) {
    return (
      <Page title={"Approve Users"} id={"approveUsers"} type={"style1"}>
        <Section fullWidth={true} centered={true} display={saveSuccess}>
          <Message
            operation={saveSuccess}
            operationHandler={handleSuccessClose}
            text="User Change Approved/Rejected Successfully."
          ></Message>
        </Section>
        <Section centered={true}>
          <MainCard title="Users" style={{ width: "100%" }}>
            <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="center">Action</TableCell>
                        <TableCell align="center">Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user) => (
                        <TableRow
                          key={user.username}
                          className={"list"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {user.username}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {user.type}
                          </TableCell>
                          <TableCell align="center">
                            {user.status === "A" &&
                            (user.type === "Content Editor" ||
                              user.type === "Editor") &&
                            (userType === "Web Site Editor" ||
                              userType === "Editor") ? (
                              <>
                                <Tooltip
                                  title="Remove Rights"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="remove rights"
                                    onClick={() => _makeEditor(user.id, "User")}
                                  >
                                    <img
                                      src={IconMakeUser}
                                      width="35rem"
                                      height="35rem"
                                      alt="Remove Rights"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            {user.type === "Web Site Editor" &&
                            userType === "Web Site Editor" ? (
                              <>
                                <Tooltip
                                  title="Remove Rights"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="remove rights"
                                    onClick={() => _makeEditor(user.id, "User")}
                                  >
                                    <img
                                      src={IconMakeUser}
                                      width="35rem"
                                      height="35rem"
                                      alt="Remove Rights"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            {user.status === "P" ? (
                              <>
                                <Tooltip title="Approve" disableInteractive>
                                  <IconButton
                                    color="primary"
                                    aria-label="approve"
                                    onClick={() =>
                                      _makeEditor(user.id, user.type)
                                    }
                                  >
                                    <img
                                      src={IconApprove}
                                      width="35rem"
                                      height="35rem"
                                      alt="Approve"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            {user.status === "A" &&
                            user.type === "User" &&
                            userType === "Web Site Editor" ? (
                              <>
                                <Tooltip
                                  title="Make Content Editor"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="make content editor"
                                    onClick={() =>
                                      _makeEditor(user.id, "Content Editor")
                                    }
                                  >
                                    <img
                                      src={IconMakeContentEditor}
                                      width="35rem"
                                      height="35rem"
                                      alt="Make Content Editor"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Make Editor" disableInteractive>
                                  <IconButton
                                    color="primary"
                                    aria-label="make editor"
                                    onClick={() =>
                                      _makeEditor(user.id, "Editor")
                                    }
                                  >
                                    <img
                                      src={IconMakeEditor}
                                      width="35rem"
                                      height="35rem"
                                      alt="Make Editor"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Make Web Site Editor"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="make web site editor"
                                    onClick={() =>
                                      _makeEditor(user.id, "Web Site Editor")
                                    }
                                  >
                                    <img
                                      src={IconMakeWebSiteEditor}
                                      width="35rem"
                                      height="35rem"
                                      alt="Make Web Site Editor"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}
                            {user.status === "A" &&
                            user.type === "User" &&
                            userType === "Editor" ? (
                              <>
                                <Tooltip
                                  title="Remove Rights"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="remove rights"
                                    onClick={() => _makeEditor(user.id, "User")}
                                  >
                                    <img
                                      src={IconMakeUser}
                                      width="35rem"
                                      height="35rem"
                                      alt="Remove Rights"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title="Make Content Editor"
                                  disableInteractive
                                >
                                  <IconButton
                                    color="primary"
                                    aria-label="make content editor"
                                    onClick={() =>
                                      _makeEditor(user.id, "Content Editor")
                                    }
                                  >
                                    <img
                                      src={IconMakeContentEditor}
                                      width="35rem"
                                      height="35rem"
                                      alt="Make Content Editor"
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Make Editor" disableInteractive>
                                  <IconButton
                                    color="primary"
                                    aria-label="make editor"
                                    onClick={() =>
                                      _makeEditor(user.id, "Editor")
                                    }
                                  >
                                    <img
                                      src={IconMakeEditor}
                                      width="35rem"
                                      height="35rem"
                                      alt="Make Editor"
                                    />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Delete" disableInteractive>
                              <IconButton
                                color="primary"
                                aria-label="delete user"
                                onClick={() => handleDeleteOpen(user.id)}
                              >
                                <img
                                  src={IconDelete}
                                  width="35rem"
                                  height="35rem"
                                  alt="delete"
                                />
                              </IconButton>
                            </Tooltip>
                            <Notice
                              open={open}
                              openHandler={handleClose}
                              linkTo={"/editor/approveUsers"}
                              operation={() => _deleteUser(currentUser)}
                              text={"Delete the user!"}
                              comment={"This action is permanent."}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </MainCard>
        </Section>
      </Page>
    );
  }
};

export default ApproveUsers;
