import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAll} from "../../../../store/actions/home/gallery";
import Gallery from "../../../../ui-component/Gallery";
import {getData as getImageData, getImagePath} from "../../../../store/actions/general/image";

const HomeCarousel = () => {
    const dispatch = useDispatch();

    const galleries = useSelector((state) => state.gallery.galleries);
    const imageData = useSelector((state) => state.image.imageData);
    const [galleryUrls, setGalleryUrls] = useState([]);

    useEffect(() => {
        getAll(dispatch);
    }, []);

    useEffect(() => {
        if(galleries) {
            for (let item of galleries) {
                getImageData(dispatch, item.image);
            }
        }
    }, [galleries]);

    useEffect(() => {
        if(galleries) {
            let results = []
            for (let item of galleries) {
                if (imageData[item.image]) {
                    results.push({
                        "image": getImagePath(imageData[item.image]),
                        "text": item.text
                    });
                }
            }
            setGalleryUrls(results);
        }
    }, [imageData]);

    return (
        <Gallery items={galleryUrls} dynamicHeight={false}></Gallery>
    );
};

export default HomeCarousel;