import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../../actions/mooc/course/course";

export const initialState = {
  courses: [],
  coursesFull: {},
  waitingCourses: [],
};

const course = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        coursesFull: { ...state.coursesFull, [id]: action.data },
      };
    case GET_ALL.name:
      return {
        ...state,
        coursesFull: {},
        courses: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingCourses: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default course;
