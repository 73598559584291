import * as actionTypes from "../../actions/user/user";

export const initialState = {
  list: [],
  data: null,
  getSuccess: false,
  deleteSuccess: false,
  updateSuccess: false,
};

// ==============================|| PATIENTS REDUCER ||============================== //

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL.name:
      return {
        ...state,
        list: action.data,
        getSuccess: action.success,
      };
    case actionTypes.GET.name:
      return {
        ...state,
        data: action.data,
        getSuccess: action.success,
      };
    case actionTypes.DELETE.name:
      return {
        ...state,
        deleteSuccess: action.success,
      };
    case actionTypes.APPROVE.name:
      return {
        ...state,
        updateSuccess: action.success,
      };
    case actionTypes.MAKE_EDITOR.name:
      return {
        ...state,
        updateSuccess: action.success,
      };
    case actionTypes.UPDATE_INFO.name:
      return {
        ...state,
        updateSuccess: action.success,
      };
    default:
      return state;
  }
};

export default user;
