// action - state management
import * as actionTypes from '../../actions/general/error';

export const initialState = {
    error: null
};

// ==============================|| PATIENTS REDUCER ||============================== //

const error = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ERROR:
            return {
                ...state,
                error: action.data
            };
        default:
            return state;
    }
};

export default error;
