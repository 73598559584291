// project imports
import Courses from "../views/private/user/mooc/Courses";
import Course from "../views/private/user/mooc/Course";
import CourseBasicInfo from "../views/private/user/mooc/CourseBasicInfo";
import MoocLayout from "../layout/MoocLayout";
import CourseModule from "../views/private/user/mooc/CourseModule";
import CourseModulePart from "../views/private/user/mooc/CourseModulePart";
import CourseModulePartFile from "../views/private/user/mooc/CourseModulePartFile";
import CourseAdditionalMaterials from "../views/private/user/mooc/CourseAdditionalMaterials";
import CourseModulePartQuiz from "../views/private/user/mooc/CourseModulePartQuiz";
import CourseModulePartQuizProgress from "../views/private/user/mooc/CourseModulePartQuizProgress";

// ==============================|| MAIN ROUTING ||============================== //

const MOOCRoutes = {
  path: "/user/mooc/",
  element: <MoocLayout />,
  children: [
    {
      path: "/courses",
      element: <Courses />,
    },
    {
      path: "/course",
      element: <Course />,
    },
    {
      path: "/basic-info",
      element: <CourseBasicInfo />,
    },
    {
      path: "/module",
      element: <CourseModule />,
    },
    {
      path: "/module-part",
      element: <CourseModulePart />,
    },
    {
      path: "/module-part-file",
      element: <CourseModulePartFile />,
    },
    {
      path: "/additional-materials",
      element: <CourseAdditionalMaterials />,
    },
    {
      path: "/module-part-quiz",
      element: <CourseModulePartQuiz />,
    },
    {
      path: "/module-part-quiz-progress",
      element: <CourseModulePartQuizProgress />,
    },
  ],
};

export default MOOCRoutes;
