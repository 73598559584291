import React, { useEffect } from "react";
import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { getId, getTitle } from "../../private_menu_util";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../../store/actions/mooc/course/course";
import { setCourse } from "../../../../store/actions/mooc/mooc";
import Course from "./components/Course";

const Courses = () => {
  const MENU = "MOOCs - Courses";

  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);
  const courses = useSelector((state) => state.course.courses);

  useEffect(() => {
    setCourse(dispatch, null);
    getAll(dispatch);
  }, [dispatch]);

  return (
    <Page title={contents[getTitle(MENU)]} id={getId(MENU)} type={"style1"}>
      <Section centered={true}>
        {courses?.map((course, idx) => {
          return <Course course={course} key={course.id}></Course>;
        })}
      </Section>
    </Page>
  );
};

export default Courses;
