import { Editor } from "react-draft-wysiwyg";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { useDispatch } from "react-redux";
import { setHTML } from "../store/actions/general/editor";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const HTMLEditor = ({ id, html }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (html) {
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
      );
      setState(EditorState.createWithContent(contentState));
      setHTML(dispatch, id, html);
    }
  }, [html, dispatch]);

  if (!id) {
    return <h3>Empty editor id!</h3>;
  }

  const onEditorStateChange = (editorState) => {
    setState(editorState);
    setHTML(
      dispatch,
      id,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  return (
    <Editor
      editorState={state}
      onEditorStateChange={onEditorStateChange}
      stripPastedStyles={true}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "list",
          "textAlign",
          "link",
          "image",
          "remove",
          "history",
        ],
      }}
      onE
    />
  );
};

export default HTMLEditor;
