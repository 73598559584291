import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  InputLabel,
  Radio,
  Tab,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "../../../../store/constant";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { raiseError } from "../../../../store/actions/general/error";
import { useDispatch, useSelector } from "react-redux";
import {
  get,
  getAllByUser,
  save,
} from "../../../../store/actions/mooc/course/user_quiz_result";
import Button from "@mui/material/Button";
import AudioPlayer from "../../../../ui-component/AudioPlayer";

const CourseModulePartFile = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const userId = useSelector((state) => state.login.userId);

  const location = useLocation();
  const modulePart = location?.state?.modulePart;
  const quiz = location?.state?.data;
  const userQuizId = location?.state?.userQuizId;

  const userQuizResultsFull = useSelector(
    (state) => state.userQuizResult.userQuizResultsFull
  );

  const [value, setValue] = useState(Object.keys(quiz.questions)[0]);
  const [userQuizResult, setUserQuizResult] = useState(null);
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  useEffect(() => {
    if (userQuizId) {
      get(dispatch, userQuizId);
    }
  }, [dispatch, userQuizId]);

  useEffect(() => {
    if (Object.keys(userQuizResultsFull).includes(String(userQuizId))) {
      setUserQuizResult(userQuizResultsFull[userQuizId]);
      setAnswers(userQuizResultsFull[userQuizId].answers);
    }
  }, [userQuizId, userQuizResultsFull]);

  const handleQuestionChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAnswerRadioChange = (order, i) => {
    setAnswers({ ...answers, [parseInt(order)]: [i] });
  };

  const handleAnswerCheckboxChange = (order, i, value) => {
    let data = answers[order];
    if (value) {
      data.push(i);
    } else {
      let index = data.indexOf(i);
      if (index > -1) {
        data.splice(index, 1);
      }
    }
    setAnswers({ ...answers, [order]: data });
  };

  const handleSave = () => {
    save(
      dispatch,
      {
        file: [],
      },
      {
        id: userQuizId,
        quiz_id: quiz.id,
        answers: answers,
        result: 0,
        owner: userId,
        status: "In Progress",
      }
    );
  };

  const validate = () => {
    for (let order of Object.keys(quiz.questions)) {
      let mark = quiz.answers[order].filter(Boolean).length;
      let userSelected = answers[order].length;

      if (mark !== userSelected) {
        raiseError(dispatch, {
          message:
            "You should select " +
            String(mark) +
            " choices on Question " +
            String(parseInt(order) + 1) +
            "!",
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    let result = validate();

    if (!result) {
      return;
    }

    save(
      (data) => {
        getAllByUser(dispatch, userId);
        dispatch(data);
        navigate("/user/mooc/module-part-quiz", {
          state: {
            data: quiz,
            modulePart: modulePart,
          },
        });
      },
      {
        file: [],
      },
      {
        id: userQuizId,
        quiz_id: quiz.id,
        answers: answers,
        result: 0,
        owner: userId,
        status: "Submitted",
      }
    );
  };

  if (Object.keys(answers).length === 0) {
    return <></>;
  }

  return (
    <Page
      title={"Quiz - " + modulePart.name}
      id={"courseModulePartQuiz"}
      type={"mooc"}
    >
      {userQuizResult.status === "Submitted" ? (
        <Section>
          <InputLabel style={{ color: "blue" }}>
            Total Score: {userQuizResult.score}
          </InputLabel>
        </Section>
      ) : (
        <></>
      )}
      <Section>
        <TabContext
          value={value}
          onChange={handleQuestionChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Quiz navigation"
          orientation="vertical"
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleQuestionChange}
              aria-label="lab API tabs example"
            >
              {Object.keys(quiz.questions).map((order, i) => {
                if (answers[order].length > 0) {
                  return (
                    <Tab
                      label={String(parseInt(order) + 1)}
                      value={order}
                      style={{ backgroundColor: "lightgray" }}
                    ></Tab>
                  );
                } else {
                  return (
                    <Tab
                      label={String(parseInt(order) + 1)}
                      value={order}
                    ></Tab>
                  );
                }
              })}
            </TabList>
          </Box>
          {Object.keys(quiz.questions).map((order, i) => {
            return (
              <TabPanel value={order} style={{ width: "100%" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer
                      component={Paper}
                      style={{ backgroundColor: "#ededed" }}
                    >
                      <TableBody style={{ width: "100%" }}>
                        <TableRow style={{ width: "100%" }}>
                          {
                            (quiz.audio_files != null && quiz.audio_files.length > 0) ?
                                <AudioPlayer audio={quiz.audio_files[order]}></AudioPlayer> : <></>
                          }
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <InputLabel style={{ color: "blue" }}>
                              Question {parseInt(order) + 1}
                            </InputLabel>
                            <InputLabel style={{ backgroundColor: "yellow" }}>
                              {answers[order].length === 0 ? (
                                <strong>Not answered yet.</strong>
                              ) : (
                                <></>
                              )}
                            </InputLabel>
                            <InputLabel>
                              Marked out of {parseFloat(quiz.marks[order])}.00.
                            </InputLabel>
                            {Object.keys(userQuizResult.scores).includes(
                              String(order)
                            ) ? (
                              userQuizResult.scores[order] === 0 ? (
                                <InputLabel style={{ color: "red" }}>
                                  <strong>
                                    Score: {userQuizResult.scores[order]}
                                  </strong>
                                </InputLabel>
                              ) : (
                                <InputLabel style={{ color: "green" }}>
                                  <strong>
                                    Score: {userQuizResult.scores[order]}
                                  </strong>
                                </InputLabel>
                              )
                            ) : (
                              <></>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table size="medium" aria-label="a dense table">
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ border: "none" }}>
                              <InputLabel>{quiz.questions[order]}</InputLabel>
                              {quiz.answers[order].filter(Boolean).length >
                              1 ? (
                                <InputLabel>
                                  <i>
                                    (Select{" "}
                                    {quiz.answers[order].filter(Boolean).length}{" "}
                                    answers.){" "}
                                  </i>
                                </InputLabel>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                          {quiz.choices[order].map((choice, i) => {
                            return (
                              <TableRow key={"Choice_" + i}>
                                <TableCell
                                  style={{
                                    border: "none",
                                  }}
                                >
                                  {quiz.answers[order].filter(Boolean).length >
                                  1 ? (
                                    Object.keys(
                                      userQuizResult.results
                                    ).includes(String(order)) ? (
                                      answers[order].indexOf(i) > -1 &&
                                      userQuizResult.results[order][
                                        answers[order].indexOf(i)
                                      ] ? (
                                        answers[order].includes(i) ? (
                                          <>
                                            <Checkbox
                                              checked={answers[
                                                parseInt(order)
                                              ].includes(i)}
                                              onChange={(event) =>
                                                handleAnswerCheckboxChange(
                                                  order,
                                                  i,
                                                  event.target.checked
                                                )
                                              }
                                              disabled={
                                                userQuizResult.status ===
                                                "Submitted"
                                              }
                                              style={{ color: "green" }}
                                            ></Checkbox>
                                            <InputLabel
                                              style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                color: "green",
                                              }}
                                            >
                                              {choice}
                                              <br></br>
                                              <i>(Correct answer)</i>
                                            </InputLabel>
                                          </>
                                        ) : (
                                          <>
                                            <Checkbox
                                              checked={answers[
                                                parseInt(order)
                                              ].includes(i)}
                                              onChange={(event) =>
                                                handleAnswerCheckboxChange(
                                                  order,
                                                  i,
                                                  event.target.checked
                                                )
                                              }
                                              disabled={
                                                userQuizResult.status ===
                                                "Submitted"
                                              }
                                            ></Checkbox>
                                            <InputLabel
                                              style={{
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              {choice}
                                            </InputLabel>
                                          </>
                                        )
                                      ) : answers[order].includes(i) ? (
                                        <>
                                          <Checkbox
                                            checked={answers[
                                              parseInt(order)
                                            ].includes(i)}
                                            onChange={(event) =>
                                              handleAnswerCheckboxChange(
                                                order,
                                                i,
                                                event.target.checked
                                              )
                                            }
                                            disabled={
                                              userQuizResult.status ===
                                              "Submitted"
                                            }
                                            style={{ color: "red" }}
                                          ></Checkbox>
                                          <InputLabel
                                            style={{
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                              color: "red",
                                            }}
                                          >
                                            {choice}
                                            <br></br>
                                            <i>(Wrong answer)</i>
                                          </InputLabel>
                                        </>
                                      ) : (
                                        <>
                                          <Checkbox
                                            checked={answers[
                                              parseInt(order)
                                            ].includes(i)}
                                            onChange={(event) =>
                                              handleAnswerCheckboxChange(
                                                order,
                                                i,
                                                event.target.checked
                                              )
                                            }
                                            disabled={
                                              userQuizResult.status ===
                                              "Submitted"
                                            }
                                          ></Checkbox>
                                          <InputLabel
                                            style={{
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {choice}
                                          </InputLabel>
                                        </>
                                      )
                                    ) : (
                                      <>
                                        <Checkbox
                                          checked={answers[
                                            parseInt(order)
                                          ].includes(i)}
                                          onChange={(event) =>
                                            handleAnswerCheckboxChange(
                                              order,
                                              i,
                                              event.target.checked
                                            )
                                          }
                                          disabled={
                                            userQuizResult.status ===
                                            "Submitted"
                                          }
                                        ></Checkbox>
                                        <InputLabel
                                          style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {choice}
                                        </InputLabel>
                                      </>
                                    )
                                  ) : Object.keys(
                                      userQuizResult.results
                                    ).includes(String(order)) ? (
                                    answers[order].indexOf(i) > -1 &&
                                    userQuizResult.results[order][
                                      answers[order].indexOf(i)
                                    ] ? (
                                      answers[order].includes(i) ? (
                                        <>
                                          <Radio
                                            name={
                                              "radio-buttons-" + String(order)
                                            }
                                            checked={answers[
                                              parseInt(order)
                                            ].includes(i)}
                                            onChange={(event) =>
                                              handleAnswerRadioChange(order, i)
                                            }
                                            disabled={
                                              userQuizResult.status ===
                                              "Submitted"
                                            }
                                            style={{
                                              display: "inline-block",
                                              color: "green",
                                            }}
                                          ></Radio>
                                          <InputLabel
                                            style={{
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                              color: "green",
                                            }}
                                          >
                                            {choice}
                                            <br></br>
                                            <i>(Correct answer)</i>
                                          </InputLabel>
                                        </>
                                      ) : (
                                        <>
                                          <Radio
                                            name={
                                              "radio-buttons-" + String(order)
                                            }
                                            checked={answers[
                                              parseInt(order)
                                            ].includes(i)}
                                            onChange={(event) =>
                                              handleAnswerRadioChange(order, i)
                                            }
                                            disabled={
                                              userQuizResult.status ===
                                              "Submitted"
                                            }
                                            style={{
                                              display: "inline-block",
                                            }}
                                          ></Radio>
                                          <InputLabel
                                            style={{
                                              display: "inline-block",
                                              verticalAlign: "middle",
                                            }}
                                          >
                                            {choice}
                                          </InputLabel>
                                        </>
                                      )
                                    ) : answers[order].includes(i) ? (
                                      <>
                                        <Radio
                                          name={
                                            "radio-buttons-" + String(order)
                                          }
                                          checked={answers[
                                            parseInt(order)
                                          ].includes(i)}
                                          onChange={(event) =>
                                            handleAnswerRadioChange(order, i)
                                          }
                                          disabled={
                                            userQuizResult.status ===
                                            "Submitted"
                                          }
                                          style={{
                                            display: "inline-block",
                                            color: "red",
                                          }}
                                        ></Radio>
                                        <InputLabel
                                          style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                            color: "red",
                                          }}
                                        >
                                          {choice}
                                          <br></br>
                                          <i>(Wrong answer)</i>
                                        </InputLabel>
                                      </>
                                    ) : (
                                      <>
                                        <Radio
                                          name={
                                            "radio-buttons-" + String(order)
                                          }
                                          checked={answers[
                                            parseInt(order)
                                          ].includes(i)}
                                          onChange={(event) =>
                                            handleAnswerRadioChange(order, i)
                                          }
                                          disabled={
                                            userQuizResult.status ===
                                            "Submitted"
                                          }
                                          style={{
                                            display: "inline-block",
                                          }}
                                        ></Radio>
                                        <InputLabel
                                          style={{
                                            display: "inline-block",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {choice}
                                        </InputLabel>
                                      </>
                                    )
                                  ) : (
                                    <>
                                      <Radio
                                        name={"radio-buttons-" + String(order)}
                                        checked={answers[
                                          parseInt(order)
                                        ].includes(i)}
                                        onChange={(event) =>
                                          handleAnswerRadioChange(order, i)
                                        }
                                        disabled={
                                          userQuizResult.status === "Submitted"
                                        }
                                        style={{
                                          display: "inline-block",
                                        }}
                                      ></Radio>
                                      <InputLabel
                                        style={{
                                          display: "inline-block",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {choice}
                                      </InputLabel>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                {userQuizResult.status === "In Progress" ? (
                  <Grid
                    container
                    spacing={gridSpacing}
                    style={{ marginTop: "1em" }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        style={{
                          padding: "9px 16px",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="contained"
                        style={{
                          padding: "9px 16px",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </TabPanel>
            );
          })}
        </TabContext>
      </Section>
    </Page>
  );
};

export default CourseModulePartFile;
