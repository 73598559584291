import "../../../../../assets/scss/components/page/private/Page.scss";
import { useEffect } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import { Stack, TableBody, TableCell, TableRow } from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  get,
  save,
  SAVE,
} from "../../../../../store/actions/three_d_models/files/three_d_models_files";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { raiseError } from "../../../../../store/actions/general/error";
import { reset as resetEditor } from "../../../../../store/actions/general/editor";
import HTMLEditor from "../../../../../ui-component/Editor";
import { getId, getTitle } from "../../../private_menu_util";

const AddThreeDModelFiles = () => {
  const MENU = "3D Models - Files";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const username = useSelector((state) => state.login.userId);
  const data = useSelector((state) => state.threeDModelsFiles.data);
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        resetEditor(dispatch);
        navigate("/editor/home");
        break;
      }
    }
  }, [loading?.completedOperation, dispatch, navigate]);

  useEffect(() => {
    get(dispatch);
  }, [dispatch]);

  const add = () => {
    if (editorHTMLs["info"].length === 0) {
      raiseError(dispatch, {
        message: "Empty info!",
      });
      return;
    }

    save(
      dispatch,
      {
        file: [],
      },
      {
        info: editorHTMLs["info"],
        owner: username,
        status: "ADD",
      }
    );
  };

  const edit = () => {
    save(
      dispatch,
      {
        file: [],
      },
      {
        id: data.id,
        main_id: data.id,
        info: editorHTMLs["info"],
        owner: username,
        status: "UPDATE",
      }
    );
  };

  return (
    <Page
      title={"Add " + contents[getTitle(MENU)]}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard
          title={"Add/Edit " + contents[getTitle(MENU)]}
          style={{ width: "100%" }}
        >
          <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <HTMLEditor id={"info"} html={data?.info} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {data ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={add}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default AddThreeDModelFiles;
