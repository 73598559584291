import { SET_COURSE, SET_MENU } from "../../actions/mooc/mooc";

export const initialState = {
  course: null,
  menu: ["1", "1_1"],
};

const mooc = (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE:
      return {
        ...state,
        course: action.data,
      };
    case SET_MENU:
      return {
        ...state,
        menu: action.data,
      };
    default:
      return state;
  }
};

export default mooc;
