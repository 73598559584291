import {GET, GET_ALL_WAITING, SAVE, APPROVE} from "../../../actions/idm/typological_courses/idm_typological_courses";

export const initialState = {
    data: null,
    waitingList: []
};

const idmTypologicalCourses = (state = initialState, action) => {
    switch (action.type) {
        case GET.name:
            return {
                ...state,
                data: action.data
            };
        case GET_ALL_WAITING.name:
            return {
                ...state,
                waitingList: action.data
            };
        case SAVE.name:
            return {
                ...state
            };
        case APPROVE.name:
            return {
                ...state
            };
        default:
            return state;
    }
};

export default idmTypologicalCourses;
