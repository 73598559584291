import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../../actions/mooc/course/module";

export const initialState = {
  courseModules: [],
  courseModulesFull: {},
  waitingCourseModules: [],
};

const courseModule = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        courseModulesFull: {
          ...state.courseModulesFull,
          [id]: action.data,
        },
      };
    case GET_ALL.name:
      return {
        ...state,
        courseModules: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingCourseModules: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default courseModule;
