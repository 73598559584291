import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    InputLabel,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save, get} from "../../../../store/actions/consortium/consortiumMember";
import {getData} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {raiseError} from "../../../../store/actions/general/error";
import {reset as resetEditor} from "../../../../store/actions/general/editor";
import HTMLEditor from "../../../../ui-component/Editor";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";

const AddConsortiumMember = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const username = useSelector((state) => state.login.userId);
    const id = location?.state?.consortiumMember?.id;
    const consortiumMember = useSelector((state) => state.consortiumMember.consortiumMembersFull[id]);
    const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [name, setName] = useState(consortiumMember ? consortiumMember.name : '');
    const [url, setUrl] = useState(consortiumMember ? consortiumMember.url : '');
    const [image, setImage] = useState(consortiumMember ? [consortiumMember.image] : []);

    useEffect(() => {
        if (consortiumMember) {
            setName(consortiumMember.name);
            setUrl(consortiumMember.url);
            setImage([consortiumMember.image]);

            if (consortiumMember?.image) {
                getData(dispatch, consortiumMember.image);
            }
        }
    }, [consortiumMember, dispatch]);

    useEffect(() => {
        if (id) {
            get(dispatch, id);
        }
    }, [id, dispatch]);

    const add = () => {
        if (name.length === 0) {
            raiseError(dispatch, {
                message: "Empty name!"
            });
            return;
        }

        if (url.length === 0) {
            raiseError(dispatch, {
                message: "Empty url!"
            });
            return;
        }

        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {name: name, url: url, info: editorHTMLs["info"], owner: username, status: "ADD", images: []});

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {
            id: consortiumMember.id,
            consortium_member_id: consortiumMember.id,
            images: Object.keys(oldFiles["image"]),
            name: name,
            url: url,
            info: editorHTMLs["info"],
            owner: username,
            status: "UPDATE"
        });

        resetEditor(dispatch);
        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeUrl = (event) => {
        setUrl(event.target.value);
    };

    return (
        <Page title={"Add Consortium Member"} id={"addConsortiumMember"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Consortium Member" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    id="name"
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    error={name.length > 0 && name.length < 3}
                                                    helperText={
                                                        name && (name.length < 3) ? 'Enter a valid name.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Url"
                                                    id="url"
                                                    value={url}
                                                    onChange={handleChangeUrl}
                                                    error={url.length > 0 && url.length < 3}
                                                    helperText={
                                                        url && (url.length < 3) ? 'Enter a valid url.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <HTMLEditor id={"info"} html={consortiumMember?.info}/>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader id={"image"} files={image}
                                                              isImage={true}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {consortiumMember ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddConsortiumMember;
