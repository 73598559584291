import React, { useEffect } from "react";
import "../../../assets/scss/header/Header.scss";
import Logo from "../../../ui-component/Logo";
import Search from "./components/Search";
import MenuToggle from "./components/MenuToggle";
import Logout from "./components/Logout";
import { loadContents, setLanguage } from "../../../store/actions/general/main";
import { useDispatch, useSelector } from "react-redux";
import Admin from "./components/Admin";
import Menus from "./components/Menus";
import Login from "./components/Login";

const Header = () => {
  const dispatch = useDispatch();
  const menuStatus = useSelector((state) => state.main.menuStatus);

  useEffect(() => {
    let language = 1;
    setLanguage(dispatch, language);
    loadContents(dispatch, language);
  }, [dispatch]);

  return (
    <div className={"header"}>
      <div className={"header"}>
        <div className={"upperSection"}>
          <Logo className={"marchioBraille"} />
          <div className={"largeMenuNavigations"}>
            <div className={"items"}>
              <Menus></Menus>
            </div>
          </div>
          <div className={"rightSection"}>
            <div className={"buttons"} style={{ order: 2 }}>
              <div className={"up"}>
                <Search />
                <Admin />
                <Login />
                <Logout />
                <MenuToggle />
              </div>
            </div>
          </div>
        </div>
        <div className={"bottomSection"}></div>
      </div>
      {menuStatus === "open" ? (
        <div className={"smallMenuNavigations"}>
          <div className={"items"}>
            <Menus></Menus>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Header;
