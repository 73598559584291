import LoginButton from "../../../../assets/images/header/login.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Login = () => {
  let navigate = useNavigate();

  const userType = useSelector((state) => state.login.userType);

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  if (userType != null) {
    return <></>;
  }

  return (
    <div
      className={"clickable headerItem search"}
      role={"button"}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={handleClick}
    >
      <img src={LoginButton} alt={"Login"}></img>
    </div>
  );
};

export default Login;
