import "../../../assets/scss/footer/Footer.scss";
import instagram from "../../../assets/images/footer/instagram.png";
import facebook from "../../../assets/images/footer/facebook.png";
import euFlag from "../../../assets/images/footer/EU-flag.png";
import Logo from "../../../ui-component/Logo";
import { useSelector } from "react-redux";

const Footer = () => {
  const contents = useSelector((state) => state.main.contents);

  return (
    <div className={"footer"}>
      <div className={"footer"}>
        <div className={"main"}>
          <div className={"container"}>
            <div className={"boilerPlate"}>
              <div className={"upper"}>
                <Logo reverse={true} />
                <div className={"right-side"}>
                  <div className={"socialMedia"}>
                    <div>
                      <a
                        href={"https://facebook.com/erasmusplusinvisible"}
                        target={"_blank"}
                      >
                        <img alt="" src={facebook} />
                      </a>
                      <a
                        href={"https://instagram.com/erasmusplus_invisible"}
                        target={"_blank"}
                      >
                        <img alt="" src={instagram} />
                      </a>
                    </div>
                  </div>
                  <p className={"contact-us"}>contact | invisible-eplus@mail.com</p>
                </div>
              </div>
            </div>
            <div className={"boilerPlateContainer"}>
              <div className={"up"}>
                <img alt="" src={euFlag} />
                <p>{contents ? contents["footer_about"] : ""}</p>
              </div>
              <div className={"down"}>
                <p>{contents ? contents["footer_detail"] : ""}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"disclaimer"}>
          <div className={"container"}>
            <div className={"footerLinks"}>
              <p>{contents ? contents["footer_legal"] : ""}</p>
              <p>{contents ? contents["footer_cookie"] : ""}</p>
              <p>{contents ? contents["footer_privacy"] : ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
