import "../../../../assets/scss/overlays/SearchOverlay.scss";
import Button from "../../../../assets/images/search/button.png";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCategories, setSearchStatus, search} from "../../../../store/actions/general/search";
import {getData, getImagePath} from "../../../../store/actions/general/image";
import IconNoImage from '../../../../assets/images/common/no_image.png';

function SearchStory({image, title, onSelection}) {
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (image) {
            getData(dispatch, image);
        }
    }, [image, dispatch]);

    return (
        <div className={"storyCard clickable"} onKeyUp={onSelection} onClick={onSelection} role={"button"} tabIndex={0}>
            {
                imageData[image] ? <img src={getImagePath(imageData[image])} alt={title}/> :
                    <img src={IconNoImage} alt={title}/>
            }
            <div className={"textContainer"}>
                <div className={"frame"}>
                    <p>{title}</p>
                </div>
            </div>
        </div>
    );
}

const SearchOverlay = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const contents = useSelector((state) => state.main.contents);
    const searchStatus = useSelector((state) => state.search.searchStatus);
    const results = useSelector((state) => state.search.results);
    const categories = useSelector((state) => state.search.categories);

    const [filteredResults, setFilteredResults] = useState(results);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        getCategories(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setFilteredResults(results);
    }, [results]);


    function filterResults(searchText) {
        if (searchText.trim().length > 0) {
            setSearchTypeActive(searchText.trim().length > 0);
            search(dispatch, searchText);
        }
    }

    const handleClick = event => {
        switch (event.target.className) {
            case "background": {
                setSearchStatus(dispatch, "inactive");
            }
            default: {
                filterResults(searchText);
            }
        }

    };

    const handleSearchClick = () => {
        filterResults(searchText);
    };

    const handleSearchKeyUp = event => {
        if (event.code === "Escape") {
            setSearchStatus(dispatch, "inactive");
        } else {
            let text = event.target?.value.toLowerCase();
            setSearchText(text);
            filterResults(text);
        }
    };

    const handleResultSelected = (link) => {
        setSearchStatus(dispatch, "inactive");
        if (!link) {
            link = "/search";
        }
        navigate(link);
    }

    const handleCategoryClick = category => {
        if (!category) {
            category = "search/" + searchText;
        }

        navigate(category);
        setSearchStatus(dispatch, "inactive");
    };

    const [searchTypeActive, setSearchTypeActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    if (searchStatus !== "active") {
        return (
            <>
            </>
        )
    }

    if (filteredResults && filteredResults.length > 5) {
        setFilteredResults(filteredResults.slice(0, 5));
    }

    return (
        <div className={"searchOverlay"}>
            <div className={"container"}>
                <div className={"searchModal"}>
                    <div className={"searchBox"}>
                        <div className={"container"}>
                            <div className={"frame"}>
                                <input className={"searchText"} onKeyUp={handleSearchKeyUp} onFocus={handleSearchClick}
                                       placeholder={contents ? contents["search_placeholder"] : ""}/>
                                {searchActive ? <></> :
                                    <>
                                        <div className={"button"}>
                                            <img src={Button} alt={contents ? contents["search"] : ""}/>
                                        </div>
                                        <p className={"label"}>{contents ? contents["search_placeholder_2"] : ""}</p>
                                    </>
                                }
                            </div>
                            <div className={"button clickable"} onKeyUp={handleSearchKeyUp} onFocus={handleSearchClick}
                                 role={"button"} tabIndex={0}>
                                <p className={"label"}>{contents ? contents["search"] : ""}</p>
                            </div>
                        </div>
                    </div>
                    <div className={"divider"}/>
                    {searchTypeActive ?
                        <div className={"quickResults"}>
                            <div className={"container"}>
                                <p className={"title"}>{contents ? contents["search_quick_results"] : ""}</p>
                                <div className={"storiesList"}>
                                    {
                                        filteredResults.map((result, i) => {
                                            return <SearchStory key={i} image={result.image} title={result.title}
                                                                onSelection={() => handleResultSelected(result.action.link)}/>
                                        })
                                    }
                                    <div className={"clickable"} role={"button"} tabIndex={0}
                                         onClick={() => handleCategoryClick()} onKeyUp={() => handleCategoryClick()}>
                                        <p className={"item"}>{contents ? contents["search_all_results"] : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div className={"searchResults"}>
                            <div className={"container"}>
                                <p className={"title"}>Categories</p>
                                <div className={"categoriesList"}>
                                    {
                                        categories.map((category, i) => {
                                            return (
                                                <div key={i} className={"clickable"} role={"button"} tabIndex={0}
                                                     onClick={() => handleCategoryClick(category.link)}
                                                     onKeyUp={() => handleCategoryClick(category.link)}>
                                                    <p className={"item"}>{category.text}</p>
                                                </div>
                                            );
                                        })
                                    }
                                    <div className={"clickable"} role={"button"} tabIndex={0}
                                         onClick={() => handleCategoryClick()} onKeyUp={() => handleCategoryClick()}>
                                        <p className={"item"}>{contents ? contents["search_all_results"] : ""}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={"background"} onClick={handleClick} onKeyUp={handleClick} role={"button"}
                 tabIndex={0}></div>
        </div>
    );
};

export default SearchOverlay;