import React, {useEffect} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Project.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import {getAll, get} from "../../../store/actions/project/project";
import {getData, getImagePath} from "../../../store/actions/general/image";
import {Parser as HtmlToReactParser} from "html-to-react";

function Project({obj}) {
    const dispatch = useDispatch();

    const htmlToReactParser = new HtmlToReactParser();
    const contents = useSelector((state) => state.main.contents);
    const imageData = useSelector((state) => state.image.imageData);
    const projectsFull = useSelector((state) => state.project.projectsFull);

    let project = projectsFull[obj?.id];

    useEffect(() => {
        if (obj?.image) {
            getData(dispatch, obj?.image);
        }
    }, [obj?.image]);

    useEffect(() => {
        if (obj?.id) {
            get(dispatch, obj?.id);
        }
    }, [obj?.id]);

    if (!project) {
        return <></>
    }

    if (imageData[project?.image]) {
        if (project.image_full_width) {
            return (
                <>                <Section fullWidth>
                    {imageData[project?.image] ? <img
                        alt={imageData[project?.image].name}
                        className={"image"}
                        src={getImagePath(imageData[project?.image])}
                    /> : <></>}
                </Section>
                    {
                        project.info ? <Section>
                                {
                                    project.item_order === 1 ?
                                        <div className={"left-side"}>
                                            <div className={"title"}>
                                                <p>{contents ? contents["project"] : ''}</p>
                                            </div>
                                        </div> : <div className={"left-side"}>
                                        </div>
                                }
                                <div className={"right-side"}>
                                    <div className={"content"}>{htmlToReactParser.parse(project.info)}</div>
                                </div>
                            </Section>
                            : <></>
                    }</>
            )
        } else {
            return (<>
                    <Section>
                        <div className={"left-side"}>
                        </div>
                        <div className={"right-side"}>
                            {imageData[project?.image] ? <img
                                alt={imageData[project?.image].name}
                                className={"image"}
                                src={getImagePath(imageData[project?.image])} width={"100%"}
                            /> : <></>}
                            {project.info ?
                                <div className={"content"}>{htmlToReactParser.parse(project.info)}</div> : <></>}
                        </div>
                    </Section>
                    {
                        project.info ? <Section>
                                {
                                    project.item_order === 1 ?
                                        <div className={"left-side"}>
                                            <div className={"title"}>
                                                <p>{contents ? contents["project"] : ''}</p>
                                            </div>
                                        </div> : <div className={"left-side"}>
                                        </div>
                                }
                                <div className={"right-side"}>
                                    <div className={"content"}>{htmlToReactParser.parse(project.info)}</div>
                                </div>
                            </Section>
                            : <></>
                    }</>
            )
        }
    }

    return (
        <Section>
            {project.info ? <>
                    {
                        project.item_order === 1 ?
                            <div className={"left-side"}>
                                <div className={"title"}>
                                    <p>{contents ? contents["project"] : ''}</p>
                                </div>
                            </div> : <div className={"left-side"}>
                            </div>
                    }
                    <div className={"right-side"}>
                        <div className={"content"}>{htmlToReactParser.parse(project.info)}</div>
                    </div>
                </>
                : <></>}
        </Section>);

}

const Projects = () => {
    const dispatch = useDispatch();

    const projects = useSelector((state) => state.project.projects);

    useEffect(() => {
        getAll(dispatch);
    }, []);


    if (!projects) {
        return <></>;
    }

    let values = Object.values(projects).sort((a, b) => {
        return a.item_order - b.item_order
    });

    return (
        <Page id={"projects"} type={"style1"}>
            {
                values.map((project, i) => {
                    return <Project obj={project} key={i}></Project>
                })
            }
        </Page>
    );
};

export default Projects;
