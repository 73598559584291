import { ContentMenus } from "./private_menus";

export const getMenu = (name) => {
  for (let menu of ContentMenus) {
    if (menu.name === name) {
      return menu;
    }
  }

  console.error("Menu not found for", name);
  return null;
};

export const getAddLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return "/editor/" + menu.link + "/add";
};

export const getListLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return "/editor/" + menu.link + "/list";
};

export const getViewLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return "/editor/" + menu.link + "/view";
};

export const getApproveLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return "/editor/" + menu.link + "/approve";
};

export const getLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  //if (menu.relative) {
  //  return "/user/" + menu.link;
  //}

  //return "/" + menu.link;
  return "/user/" + menu.link;
};

export const getTitle = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return menu.title;
};

export const getId = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  let menuName = menu.name.replace(" ", "");
  return menuName.charAt(0).toLowerCase() + menuName.slice(1);
};
