import { mainService } from "../../services/main";
import { languagesService } from "../../services/languages";

export const GET_ALL_LANGUAGES = "@main/GET_ALL_LANGUAGES";
export const GET_SELECTED_LANGUAGE = "@main/GET_SELECTED_LANGUAGE";
export const SET_LANGUAGE = "@main/SET_LANGUAGE";
export const LOAD_CONTENTS = "@main/LOAD_CONTENTS";
export const SET_MENU_STATUS = "@main/SET_MENU_STATUS";

export const SET_SELECTED_HEADER_MENU = "@main/SET_SELECTED_HEADER_MENU";

export const getLanguages = (dispatch) => {
  dispatch({ type: GET_ALL_LANGUAGES, data: languagesService.getAll() });
};

export const setLanguage = (dispatch, name) => {
  let language = languagesService.get(name);
  dispatch({ type: SET_LANGUAGE, data: language });
};

export const getSelectedLanguage = (dispatch) => {
  dispatch({
    type: GET_SELECTED_LANGUAGE,
    data: languagesService.getSelectedLanguage(),
  });
};

export const loadContents = (dispatch, languageId) => {
  dispatch({ type: LOAD_CONTENTS, data: mainService.loadContents(languageId) });
};

export const setMenuStatus = (dispatch, status) => {
  dispatch({ type: SET_MENU_STATUS, data: status });
};

export const setSelectedHeaderMenu = (dispatch, menu) => {
  dispatch({ type: SET_SELECTED_HEADER_MENU, data: menu });
};
