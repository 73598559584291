import "../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { get, save } from "../../../../store/actions/actions/actionItems";
import { getData } from "../../../../store/actions/general/image";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { raiseError } from "../../../../store/actions/general/error";
import HTMLEditor from "../../../../ui-component/Editor";
import { reset as resetEditor } from "../../../../store/actions/general/editor";
import { reset as resetFileUploader } from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";

const AddActionItem = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const username = useSelector((state) => state.login.userId);
  const id = location?.state?.actionItem.id;
  const actionItem = useSelector(
    (state) => state.actionItems.actionItemsFull[id]
  );
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
  const newFiles = useSelector((state) => state.fileUploader.newFiles);
  const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

  const [name, setName] = useState(actionItem ? actionItem.name : "");
  const [order, setOrder] = useState(actionItem ? actionItem.item_order : 1);
  const [image, setImage] = useState(actionItem ? [actionItem.image] : []);

  useEffect(() => {
    if (id) {
      get(dispatch, id);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (actionItem) {
      setName(actionItem.name);
      setOrder(actionItem.item_order);
      setImage([actionItem.image]);

      if (actionItem?.image) {
        getData(dispatch, actionItem.image);
      }
    }
  }, [actionItem, dispatch]);

  const add = () => {
    if (name.length === 0) {
      raiseError(dispatch, {
        message: "Empty name!",
      });
      return;
    }

    if (editorHTMLs["info"].length === 0) {
      raiseError(dispatch, {
        message: "Empty info!",
      });
      return;
    }

    if (order.length === 0) {
      raiseError(dispatch, {
        message: "Empty order!",
      });
      return;
    }

    save(
      dispatch,
      {
        image: Object.values(newFiles["image"]),
      },
      {
        name: name,
        info: editorHTMLs["info"],
        item_order: order,
        owner: username,
        status: "ADD",
        images: [],
      }
    );

    resetEditor(dispatch);
    resetFileUploader(dispatch);
    navigate("/website-editor/home");
  };

  const edit = () => {
    save(
      dispatch,
      {
        image: Object.values(newFiles["image"]),
      },
      {
        id: actionItem.id,
        action_items_id: actionItem.id,
        images: Object.keys(oldFiles["image"]),
        name: name,
        info: editorHTMLs["info"],
        item_order: order,
        owner: username,
        status: "UPDATE",
      }
    );

    resetEditor(dispatch);
    resetFileUploader(dispatch);
    navigate("/website-editor/home");
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  return (
    <Page title={"Add Action Item"} id={"addActionItem"} type={"style1"}>
      <Section centered={true}>
        <MainCard title="Add/Edit Action Item" style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          fullWidth
                          label="Name"
                          id="name"
                          value={name}
                          onChange={handleChangeName}
                          error={name.length > 0 && name.length < 3}
                          helperText={
                            name && name.length < 3 ? "Enter a valid name." : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <HTMLEditor id={"info"} html={actionItem?.info} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          type={"number"}
                          fullWidth
                          label="Order"
                          id="order"
                          value={order}
                          onChange={handleChangeOrder}
                          error={order.length > 0 && order.length < 1}
                          helperText={
                            order && order.length < 1
                              ? "Enter a valid order."
                              : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>Image</InputLabel>
                        <FileUploader
                          id={"image"}
                          files={image}
                          isImage={true}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {actionItem ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={add}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default AddActionItem;
