import "../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import IconInfo from "../../../../assets/images/operations/icon-info.png";
import IconApprove from "../../../../assets/images/operations/icon-approve.png";
import IconReject from "../../../../assets/images/operations/icon-reject.png";
import IconUndo from "../../../../assets/images/operations/icon-delete.png";
import { useNavigate } from "react-router-dom";
import {
  approve,
  APPROVE,
  get,
  getAllWaiting,
} from "../../../../store/actions/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../../ui-component/Message";

const ApproveActions = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);
  const actions = useSelector((state) => state.actions.waitingActions);

  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case APPROVE.method: {
        setSaveSuccess(true);
        getAllWaiting(dispatch);
        get(dispatch);
        break;
      }
    }
  }, [loading?.completedOperation, dispatch]);

  useEffect(() => {
    getAllWaiting(dispatch);
  }, [dispatch]);

  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  const handleApprove = (actions, status) => {
    approve(dispatch, {
      data: actions,
      status: status,
    });
    navigate("/website-editor/home");
  };

  return (
    <Page title={"Approve Actions"} id={"approveActions"} type={"style1"}>
      <Section fullWidth={true} centered={true} display={saveSuccess}>
        <Message
          operation={saveSuccess}
          operationHandler={handleSuccessClose}
          text="Actions Change Approved/Rejected Successfully."
        ></Message>
      </Section>
      <Section centered={true}>
        <MainCard title="Actionss" style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Owner</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {actions?.map((n) => (
                      <TableRow
                        key={n.id}
                        className={"list"}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {n.owner}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.status}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Info" disableInteractive>
                            <IconButton
                              color="primary"
                              aria-label="info actions"
                              onClick={() =>
                                navigate("/website-editor/actions/view", {
                                  state: {
                                    actions: n,
                                  },
                                })
                              }
                            >
                              <img
                                src={IconInfo}
                                width="35rem"
                                height="35rem"
                                alt="info"
                              />
                            </IconButton>
                          </Tooltip>
                          {userType === "Web Site Editor" ? (
                            <>
                              <Tooltip title="Approve" disableInteractive>
                                <IconButton
                                  color="primary"
                                  aria-label="approve actions"
                                  onClick={() => handleApprove(n, "APPROVE")}
                                >
                                  <img
                                    src={IconApprove}
                                    width="35rem"
                                    height="35rem"
                                    alt="approve"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Reject" disableInteractive>
                                <IconButton
                                  color="primary"
                                  aria-label="reject actions"
                                  onClick={() => handleApprove(n, "REJECT")}
                                >
                                  <img
                                    src={IconReject}
                                    width="35rem"
                                    height="35rem"
                                    alt="reject"
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          )}
                          {userType === "U" && n.owner === userName ? (
                            <Tooltip title="Undo" disableInteractive>
                              <IconButton
                                color="primary"
                                aria-label="undo actions"
                                onClick={() => handleApprove(n, "REJECT")}
                              >
                                <img
                                  src={IconUndo}
                                  width="35rem"
                                  height="35rem"
                                  alt="undo"
                                />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default ApproveActions;
