import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {IconButton, TableBody, TableCell, TableHead, TableRow, Tooltip,} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import IconInfo from "../../../../assets/images/operations/icon-info.png";
import IconApprove from "../../../../assets/images/operations/icon-approve.png";
import IconReject from "../../../../assets/images/operations/icon-reject.png";
import IconUndo from "../../../../assets/images/operations/icon-delete.png";
import {useNavigate} from "react-router-dom";
import {approve, APPROVE, getAll, getAllWaiting,} from "../../../../store/actions/news_events/news";
import {useDispatch, useSelector} from "react-redux";
import Message from "../../../../ui-component/Message";
import {getId, getTitle, getViewLink} from "../../../public/public_menu_util";

const ApproveNews = () => {
    const MENU = "News";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const userType = useSelector((state) => state.login.userType);
    const userName = useSelector((state) => state.login.username);
    const dataList = useSelector((state) => state.news.waitingNews);

    const loading = useSelector((state) => state.loading);

    useEffect(() => {
        switch (loading?.completedOperation) {
            case APPROVE.method: {
                setSaveSuccess(true);
                getAllWaiting(dispatch);
                getAll(dispatch);
                break;
            }
        }
    }, [loading?.completedOperation, dispatch]);

    useEffect(() => {
        getAllWaiting(dispatch);
    }, [dispatch]);

    const [saveSuccess, setSaveSuccess] = useState(false);

    const handleSuccessClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSaveSuccess(false);
    };

    const handleApprove = (data, status) => {
        approve(dispatch, {
            data: data,
            status: status,
        });
        navigate("/editor/home");
    };

    return (
        <Page
            title={"Approve " + contents[getTitle(MENU)]}
            id={getId(MENU)}
            type={"style1"}
        >
            <Section fullWidth={true} centered={true} display={saveSuccess}>
                <Message
                    operation={saveSuccess}
                    operationHandler={handleSuccessClose}
                    text={
                        contents[getTitle(MENU)] + " Change Approved/Rejected Successfully."
                    }
                ></Message>
            </Section>
            <Section centered={true}>
                <MainCard title={contents[getTitle(MENU)]} style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing} xs={12} sm={12}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Name</TableCell>
                                            <TableCell align="left">Owner</TableCell>
                                            <TableCell align="left">Status</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dataList?.map((d) => (
                                            <TableRow
                                                key={d.name}
                                                className={"list"}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {border: 0},
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {d.name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {d.owner}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {d.status}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Tooltip title="Info" disableInteractive>
                                                        <IconButton
                                                            color="primary"
                                                            aria-label={"Info the " + getTitle(MENU) + "!"}
                                                            onClick={() =>
                                                                navigate(getViewLink(MENU), {
                                                                    state: {
                                                                        data: d,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <img
                                                                src={IconInfo}
                                                                width="35rem"
                                                                height="35rem"
                                                                alt="info"
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {(userType === "Web Site Editor" ||
                                                        userType === "Editor") &&
                                                    d.owner !== userName ? (
                                                        <>
                                                            <Tooltip title="Approve" disableInteractive>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-label={
                                                                        "Approve the " + getTitle(MENU) + "!"
                                                                    }
                                                                    onClick={() => handleApprove(d, "APPROVE")}
                                                                >
                                                                    <img
                                                                        src={IconApprove}
                                                                        width="35rem"
                                                                        height="35rem"
                                                                        alt="approve"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Reject" disableInteractive>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-label={
                                                                        "Reject the " + getTitle(MENU) + "!"
                                                                    }
                                                                    onClick={() => handleApprove(d, "REJECT")}
                                                                >
                                                                    <img
                                                                        src={IconReject}
                                                                        width="35rem"
                                                                        height="35rem"
                                                                        alt="reject"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {d.owner === userName ? (
                                                        <Tooltip title="Undo" disableInteractive>
                                                            <IconButton
                                                                color="primary"
                                                                aria-label={"Undo the " + getTitle(MENU) + "!"}
                                                                onClick={() => handleApprove(d, "REJECT")}
                                                            >
                                                                <img
                                                                    src={IconUndo}
                                                                    width="35rem"
                                                                    height="35rem"
                                                                    alt="undo"
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default ApproveNews;
