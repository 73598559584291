import Header from './Header';
import "../../assets/scss/Main.scss";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import SearchModal from "./Search/Modal";

const MainLayout = () => {
    return (
        <div className={"homepage"}>
            <Header></Header>
            <Outlet />
            <Footer></Footer>
            <SearchModal></SearchModal>
        </div>
    );
};

export default MainLayout;
