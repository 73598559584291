const TeachingPlatformBanner = () => {
  return (
    <div className={"teachingPlatformBanner"}>
      <div className={"link"}>
        <div className={"rectangle"}>
          <p className={"title"}>In-Visible Teaching & Learning Tools</p>
          <div className={"content"}>
            Welcome, in this section of the site you can access and download the
            Teaching & Learning Tools. If you wish to contribute to the project
            as an editor with the possibility of editing content you need to
            write an e-mail to this address, indicating in the subject line
            'Editor's application' and summarising your data in the body of the
            e-mail.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeachingPlatformBanner;
