import "../../assets/scss/components/Common.scss";
import UnSelectedCheckboxIcon from "../../assets/images/common/checkbox_unselected.png";
import SelectedCheckboxIcon from "../../assets/images/common/checkbox_selected.png";
import {useState} from "react";
import * as PropTypes from "prop-types";


const Checkbox = ({id, label, checked, setStatusFunction}) => {
    const [_checked, setChecked] = useState(checked);

    const handleClick = event => {
        setChecked(!_checked);
        setStatusFunction(id, !_checked);
    };

    return (
        <div className={"selectorLabel clickable"} onKeyUp={handleClick} onClick={handleClick} role={"button"} tabIndex={0}>
            <img className={"checkbox"} src={_checked ? SelectedCheckboxIcon : UnSelectedCheckboxIcon} alt={"Checkbox"}/>
            <p className={"label"}>{label}</p>
        </div>
    );
};
Checkbox.propTypes = {
    id: PropTypes.any,
    label: PropTypes.any
};

export default Checkbox;
