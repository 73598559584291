import { _get, _getAll, _save, CommonActions, getAction } from "../../../util";

const NAME = "userQuizResult";

export const GET = getAction(NAME, CommonActions.GET);

export const GET_ALL = getAction(NAME, CommonActions.GET_ALL);
export const SAVE = getAction(NAME, CommonActions.SAVE);

export const GET_ALL_BY_USER = {
  name: "GET_ALL_BY_USER",
  method: NAME + "/getAllByUser",
};

export const getAll = (dispatch) => {
  _getAll(dispatch, GET_ALL);
};

export const getAllByUser = (dispatch, user_id) => {
  _getAll(dispatch, GET_ALL_BY_USER, user_id);
};

export const get = (dispatch, id) => {
  _get(dispatch, GET, id);
};

export const save = (dispatch, files, data) => {
  _save(dispatch, SAVE, files, data);
};
