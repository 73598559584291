import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAudioPath, getData as getFileData,} from "../store/actions/general/audio";
import "react-h5-audio-player/lib/styles.css";
import H5AudioPlayer from "react-h5-audio-player";

const AudioPlayer = ({audio}) => {
    const dispatch = useDispatch();
    const audioData = useSelector((state) => state.audio.audioData);

    useEffect(() => {
        if (audio) {
            getFileData(dispatch, audio);
        }
    }, [audio, dispatch]);

    if (!audio || !audioData[audio]) {
        return <></>;
    }

    return (
        <div className={"audioPlayer"} style={{ width: "100%" }}>
            <H5AudioPlayer
                src={getAudioPath(audioData[audio])}
                style={{ minWidth: "250px" }}
                autoPlay={false}
                controls={true}
                showJumpControls={false}
                customAdditionalControls={[]}
                layout={"horizontal"}
                onError={(error) => console.error(error)}
            />
        </div>
    );
};

export default AudioPlayer;
