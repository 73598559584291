import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll as getProjectDetails } from "../../../../store/actions/project/projectDetails";
import {
  get as getProjectResult,
  getAll as getProjectResults,
} from "../../../../store/actions/project/projectResults";
import {
  getData as getImageData,
  getImagePath,
} from "../../../../store/actions/general/image";
import { Parser as HtmlToReactParser } from "html-to-react";

function Detail({ title, text }) {
  const htmlToReactParser = new HtmlToReactParser();

  return (
    <>
      <div className={"item"}>
        <p className={"left-side"}>{title}</p>
        <span className={"right-side"}>{htmlToReactParser.parse(text)}</span>
      </div>
    </>
  );
}

function SubDetail({ id, image, title, text, hideDetails }) {
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state.image.imageData);

  useEffect(() => {
    getImageData(dispatch, image);
  }, [image]);

  const htmlToReactParser = new HtmlToReactParser();

  const handleKeyUp = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      window.open("/project-results/" + id, "_self");
    }
  };

  const handleClick = () => {
    window.open("/project-results/" + id, "_self");
  };

  return (
    <div
      className={"item clickable"}
      role={"button"}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
    >
      <div className={"card"}>
        <div className={"up"}>
          {imageData[image] ? (
            <img
              alt={imageData[image].name}
              src={getImagePath(imageData[image])}
            />
          ) : (
            <></>
          )}
        </div>
        <div className={"down"}>
          <p className={"title"}>{title}</p>
          {hideDetails ? (
            <></>
          ) : (
            <div className={"text"}>{htmlToReactParser.parse(text)}</div>
          )}
        </div>
      </div>
    </div>
  );
}

const Details = ({ hideDetails }) => {
  const dispatch = useDispatch();

  const projectDetails = useSelector(
    (state) => state.projectDetails.projectDetails
  );
  const projectResults = useSelector(
    (state) => state.projectResults.projectResults
  );
  const projectResultsFull = useSelector(
    (state) => state.projectResults.projectResultsFull
  );

  useEffect(() => {
    getProjectDetails(dispatch);
    getProjectResults(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (projectResults) {
      for (let projectResult of projectResults) {
        getProjectResult(dispatch, projectResult.id);
      }
    }
  }, [projectResults, dispatch]);

  if (!projectDetails) {
    return <></>;
  }

  if (!projectResultsFull) {
    return <></>;
  }

  const parts = [];
  if (projectResults) {
    for (let idx in projectResults) {
      let id = parseInt(parseInt(idx) / 3);
      if (parts[id] === undefined) {
        parts[id] = [];
      }

      parts[id].push(projectResults[idx]);
    }
  }

  return (
    <div className={"details"}>
      <div className={"circle1"}></div>
      <div className={"circle2"}></div>
      {hideDetails ? (
        <></>
      ) : (
        projectDetails?.map((projectDetail, i) => {
          return (
            <Detail
              title={projectDetail.name}
              text={projectDetail.info}
              key={"detail" + i}
            />
          );
        })
      )}
      {parts.length === 0 ? (
        <></>
      ) : (
        <div className={"subDetail"}>
          {parts.map((part, i) => {
            return (
              <div className={"row"} key={"subDetail" + i}>
                {part.map((item, j) => {
                  item = projectResultsFull[item.id];
                  if (!item) {
                    return <div key={"subDetail" + i * 10 + j}></div>;
                  }
                  return (
                    <SubDetail
                      id={3 * i + j}
                      key={"subDetail" + i * 10 + j}
                      title={item.name}
                      text={item.info}
                      image={item.image}
                      hideDetails={hideDetails}
                    ></SubDetail>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Details;
