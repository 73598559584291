import React, {useEffect, useState} from "react";
import "../../../../../assets/scss/components/page/public/Page.scss";
import "../../../../../assets/scss/Common.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../../../ui-component/page/public/section";
import Page from "../../../../../ui-component/page/public";
import {get as getMain} from "../../../../../store/actions/three_d_models/files/three_d_models_files";
import {
    get as getFile,
    getAll as getFiles,
} from "../../../../../store/actions/three_d_models/files/three_d_models_files_files";
import {Parser as HtmlToReactParser} from "html-to-react";
import {
    getData as getFileData,
    getFilePath,
} from "../../../../../store/actions/general/file";

const ThreeDModelsFiles = () => {
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const main = useSelector((state) => state.threeDModelsFiles.data);
    const list = useSelector((state) => state.threeDModelsFilesFiles.list);
    const htmlToReactParser = new HtmlToReactParser();
    const fileData = useSelector((state) => state.file.fileData);

    const architectureTypes = ["Ancient Architecture", "Modern Architecture"];

    useEffect(() => {
        getMain(dispatch);
        getFiles(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (list) {
            for (let data of list) {
                getFile(dispatch, data.id);
            }
        }
    }, [list, dispatch]);

    useEffect(() => {
        if (list) {
            for (let item of list) {
                for (let data of item.files) {
                    getFileData(dispatch, data);
                }

                if (item.presentation) {
                    getFileData(dispatch, item.presentation);
                }
            }
        }
    }, [list, dispatch]);

    if (!list || !main) {
        return <></>;
    }

    return (
        <Page
            id={"three3ModelsFiles"}
            type={"style1"}
            title={contents ? contents["drawing_files"] : ""}
        >
            {architectureTypes.map((type) => (
                <Section key={type}>
                    <div className={"left-side"}>
                        <p className={"title"}>{type}</p>
                    </div>
                    <div className={"right-side"}>
                        <div className={"files"}>
                            {list.map((file, i) => {
                                if (!file || file.type !== type) {
                                    return null;
                                }

                                return (
                                    <div className={"file"} key={i} style={{marginBottom: "2em"}}>
                                        <div style={{fontStyle: 'italic'}}>
                                            {htmlToReactParser.parse(file?.name)}
                                        </div>
                                        {file?.info && file?.info.length > 0 ? <div style={{lineHeight: '100%'}}>
                                            {htmlToReactParser.parse(file?.info)}
                                        </div> : <></>}
                                        {file.presentation && fileData[file.presentation] ? <div><a
                                            className={"title"}
                                            href={getFilePath(fileData[file.presentation])}
                                            target={"_blank"}
                                            style={{display: 'inline-block'}}
                                        >
                                            {fileData[file.presentation].file.name}
                                        </a>
                                            <div className={"subTitle"} style={{
                                                fontSize: 'smaller',
                                                display: 'inline-block',
                                                paddingLeft: '0.5em'
                                            }}>
                                                ({(fileData[file.presentation].file.size / (1024 * 1024)).toFixed(2)} KB)
                                            </div>
                                        </div> : <></>}
                                        {file?.files?.map((f, i) => {
                                            if (!fileData[f]) {
                                                return <></>;
                                            }

                                            return (<div><a
                                                className={"title"}
                                                href={getFilePath(fileData[f])}
                                                target={"_blank"}
                                                style={{display: 'inline-block'}}
                                            >
                                                {fileData[f].file.name}
                                            </a>
                                                <div className={"subTitle"} style={{
                                                    fontSize: 'smaller',
                                                    display: 'inline-block',
                                                    paddingLeft: '0.5em'
                                                }}>
                                                    ({(fileData[f].file.size / (1024 * 1024)).toFixed(2)} KB)
                                                </div>
                                            </div>)
                                        })
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Section>
            ))}
        </Page>
    );
};

export default ThreeDModelsFiles;
