import "../../../../../assets/scss/components/page/private/Page.scss";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { approve } from "../../../../../store/actions/three_d_models/guidelines/three_d_models_guidelines";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { getId, getTitle } from "../../../private_menu_util";

const HtmlToReactParser = require("html-to-react").Parser;

const ViewThreeDModelGuidelinesDetail = () => {
  const MENU = "3D Models - Guidelines";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);
  const location = useLocation();
  const data = location?.state?.data;

  const htmlToReactParser = new HtmlToReactParser();

  const handleApprove = (status) => {
    approve(dispatch, {
      data: data,
      status: status,
    });
    navigate("/editor/home");
  };

  return (
    <Page
      title={contents[getTitle(MENU)] + " Detail"}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard title={contents[getTitle(MENU)]} style={{ width: "100%" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel style={{ color: "blue" }}>Info</InputLabel>
                        {htmlToReactParser.parse(data.info)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {(userType === "Web Site Editor" || userType === "Editor") &&
                data.owner !== userName ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("APPROVE")}
                    >
                      Approve
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      margin="normal"
                      onClick={() => handleApprove("REJECT")}
                    >
                      Reject
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                {data.owner === userName ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={() => handleApprove("REJECT")}
                  >
                    Undo
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default ViewThreeDModelGuidelinesDetail;
