import "../../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  getAll,
  SAVE,
} from "../../../../../store/actions/mooc/course/course_basic_info";
import { get as getCourse } from "../../../../../store/actions/mooc/course/course";
import IconEdit from "../../../../../assets/images/operations/icon-edit.png";
import { useNavigate } from "react-router-dom";
import { raiseError } from "../../../../../store/actions/general/error";
import Message from "../../../../../ui-component/Message";
import { getAddLink, getId, getTitle } from "../../../private_menu_util";

export const ListCourses = () => {
  const MENU = "MOOCs - Course Basic Info";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);

  const userType = useSelector((state) => state.login.userType);
  const coursesFull = useSelector((state) => state.course.coursesFull);
  const dataList = useSelector(
    (state) => state.courseBasicInfo.courseBasicInfos
  );
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        setSaveSuccess(true);
        getAll(dispatch);
        break;
      }
    }
  }, [loading?.completedOperation]);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    } else {
      getAll(dispatch);
    }
  }, [dispatch, userType]);

  useEffect(() => {
    for (let data of dataList) {
      getCourse(dispatch, data.course_id);
    }
  }, [dispatch, dataList]);

  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  if (userType) {
    return (
      <Page title={contents[getTitle(MENU)]} id={getId(MENU)} type={"style1"}>
        <Section fullWidth={true} centered={true} display={saveSuccess}>
          <Message
            operation={setSaveSuccess}
            operationHandler={handleSuccessClose}
            text={
              contents[getTitle(MENU)] +
              " Change Request Submitted Successfully."
            }
          ></Message>
        </Section>
        <Section centered={true}>
          <MainCard style={{ width: "100%" }}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataList?.map((d) => (
                        <TableRow
                          key={d.name}
                          className={"list"}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {coursesFull[d.course_id]
                              ? coursesFull[d.course_id].name
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Edit" disableInteractive>
                              <IconButton
                                color="primary"
                                aria-label={
                                  "Update the " + contents[getTitle(MENU)] + "!"
                                }
                                onClick={() =>
                                  navigate(getAddLink(MENU), {
                                    state: {
                                      data: d,
                                    },
                                  })
                                }
                              >
                                <img
                                  src={IconEdit}
                                  width="35rem"
                                  height="35rem"
                                  alt="edit"
                                />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </MainCard>
        </Section>
      </Page>
    );
  }
};
