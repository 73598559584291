import React, { useEffect, useState } from "react";
import "../../../assets/scss/views/private/Dashboard.scss";
import "../../../assets/scss/components/page/private/Page.scss";
import { useDispatch } from "react-redux";
import Page from "../../../ui-component/page/private";
import MainCard from "../../../ui-component/cards/MainCard";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  MenuItem,
  Select,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate } from "react-router-dom";
import { signUp } from "../../../store/actions/user/login";
import Section from "../../../ui-component/page/public/section";
import { raiseError } from "../../../store/actions/general/error";

const SignUp = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  let editor = location?.state?.editor;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("User");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("Sign Up");

  useEffect(() => {
    if (editor) {
      setType("Content Editor");
    }
  }, [editor]);

  useEffect(() => {
    setTitle(type + " Sign Up");
  }, [type]);

  const handleSubmit = () => {
    if (username.length === 0) {
      raiseError(dispatch, {
        message: "Empty username!",
      });
      return;
    }

    if (password.length === 0) {
      raiseError(dispatch, {
        message: "Empty password!",
      });
      return;
    }

    if (name.length === 0) {
      raiseError(dispatch, {
        message: "Empty name!",
      });
      return;
    }

    if (email.length === 0) {
      raiseError(dispatch, {
        message: "Empty email!",
      });
      return;
    }

    let mailFormat =
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    if (!email.match(mailFormat)) {
      raiseError(dispatch, {
        message: "Invalid email format!",
      });
      return;
    }

    signUp(dispatch, name, email, username, password, type);
    navigate("/login", {
      state: {
        notify: true,
        editor: editor,
      },
    });
  };

  const handleChangeUsername = (event) => {
    setUserName(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" || e.key === 13) {
      signUp(dispatch, name, email, username, password, type);
    }
  };

  return (
    <Page id={"home"} type={"style1"}>
      <Section centered={true}>
        <MainCard title={title} style={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      label="Username"
                      id="username"
                      onChange={handleChangeUsername}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      style={{ marginBottom: "1rem" }}
                      type="password"
                      label="Password"
                      id="password"
                      onChange={handleChangePassword}
                      onKeyUp={handleKeyUp}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      label="Name"
                      id="name"
                      onChange={handleChangeName}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }}>
                    <TextField
                      fullWidth
                      label="Email"
                      id="email"
                      onChange={handleChangeEmail}
                    />
                  </TableCell>
                </TableRow>
                {editor ? (
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}>
                      <Select
                        fullWidth
                        id="type"
                        value={type}
                        label="Type"
                        onChange={handleChangeType}
                      >
                        <MenuItem value={"Content Editor"}>
                          Content Editor
                        </MenuItem>
                        <MenuItem value={"Editor"}>Editor</MenuItem>
                        <MenuItem value={"Web Site Editor"}>
                          Web Site Editor
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
            {
              <LoadingButton
                color="primary"
                type="submit"
                onClick={handleSubmit}
                loadingPosition="start"
                startIcon={<SendIcon />}
                variant="contained"
              >
                Sign Up
              </LoadingButton>
            }
          </Stack>
        </MainCard>
      </Section>
    </Page>
  );
};

export default SignUp;
