import "../../../../assets/scss/components/page/public/Page.scss";

let Row = ({children}) => {
    return (
        <div className={"row"}>
            {children}
        </div>
    );
};

export default Row;
