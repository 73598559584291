import { CourseMenus } from "./course_tree";

export const getMenu = (name) => {
  for (let menu of CourseMenus) {
    if (menu.name === name) {
      return menu;
    }
  }

  console.error("Menu not found for", name);
  return null;
};

export const getLink = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return "/mooc/" + menu.link;
};

export const getTitle = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  return menu.title;
};

export const getId = (name) => {
  let menu = getMenu(name);

  if (!menu) {
    return null;
  }

  let menuName = menu.name.replace(" ", "");
  return menuName.charAt(0).toLowerCase() + menuName.slice(1);
};
