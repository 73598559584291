import {contentService} from "./content";

class MainService {
    constructor() {
        this.contents = null;
        this.language_id = null;
    }

    loadContents(language_id) {
        if (!this.contents || this.language_id !== language_id) {
            this.contents = {};
            this.language_id = language_id;
            for (let c of contentService.contents) {
                if (c.load && c.languageId === language_id) {
                    this.contents[c.name] = c.text;
                }
            }
        }

        return this.contents;
    }
}

export const mainService = new MainService();