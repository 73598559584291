import {useDispatch, useSelector} from "react-redux";
import {getAll} from "../../../../store/actions/actions/actionItems";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {getData, getImagePath} from "../../../../store/actions/general/image";

function Action({image, text}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        if (image) {
            getData(dispatch, image);
        }
    }, [image]);

    const handleKeyUp = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            navigate("/actions");
        }
    };

    const handleClick = () => {
        navigate("/actions");
    };

    return (
        <div className={"item clickable"} role={"button"} tabIndex={0} onClick={handleClick} onKeyUp={handleKeyUp}>
            {imageData[image] ? <img
                alt={imageData[image].name}
                className={
                    "highlightImages"
                }
                src={getImagePath(imageData[image])}
            /> : <></>}
            <div className={"info"}>
                <p>
                    {text}
                </p>
            </div>
        </div>
    );
}

function Actions() {
    const dispatch = useDispatch();

    const actions = useSelector((state) => state.actionItems.actionItems);

    useEffect(() => {
        getAll(dispatch);
    }, []);

    const parts = [];

    for (let idx in actions) {
        let id = parseInt(parseInt(idx) / 3);
        if (parts[id] === undefined) {
            parts[id] = []
        }

        parts[id].push(actions[idx])
    }

    return (
        <div className={"highlights"}>
            <div className={"container"}>
                {
                    parts.map((part, i) => {
                        return (
                            <div className={"row"} key={i}>
                                {
                                    part.map((item, j) => {
                                        return <Action image={item.image} text={item.name} key={j}></Action>;
                                    })
                                }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Actions;