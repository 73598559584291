import { post } from "../../../fw/web_service_caller";

export const GET_DATA = "@audio/GET_DATA";

export const getData = (dispatch, id) => {
  post(dispatch, GET_DATA, "getAudioData", id);
};

export const getAudioPath = (audio) => {
  let data = {
    "common_file_id": audio["common_file_id"]
  };

  return (
    process.env.REACT_APP_FTP_SERVER_URL +
    "return-files?" +
    new URLSearchParams(data)
  );
};
