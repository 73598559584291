export default [
  {
    content_name: "who",
    language_id: 1,
    load: true,
    text: "Who",
  },
  {
    content_name: "what",
    language_id: 1,
    load: true,
    text: "What",
  },
  {
    content_name: "why",
    language_id: 1,
    load: true,
    text: "Why",
  },
  {
    content_name: "how",
    language_id: 1,
    load: true,
    text: "How",
  },
  {
    content_name: "home",
    language_id: 1,
    load: true,
    text: "Home",
  },
  {
    content_name: "project_details",
    language_id: 1,
    load: true,
    text: "Project Details",
  },
  {
    content_name: "project_results",
    language_id: 1,
    load: true,
    text: "Project Results",
  },
  {
    content_name: "who_we_are",
    language_id: 1,
    load: true,
    text: "Who we are",
  },
  {
    content_name: "project",
    language_id: 1,
    load: true,
    text: "Project",
  },
  {
    content_name: "project",
    language_id: 2,
    load: true,
    text: "Progetto",
  },
  {
    content_name: "consortium",
    language_id: 1,
    load: true,
    text: "Consortium",
  },
  {
    content_name: "consortium",
    language_id: 2,
    load: true,
    text: "Consorzio",
  },
  {
    content_name: "partners",
    language_id: 1,
    load: true,
    text: "Associated Partners",
  },
  {
    content_name: "partners",
    language_id: 2,
    load: true,
    text: "Partner Associati",
  },
  {
    content_name: "download",
    language_id: 1,
    load: true,
    text: "Download",
  },
  {
    content_name: "download",
    language_id: 2,
    load: true,
    text: "Scarica",
  },
  {
    content_name: "who_we_are",
    language_id: 2,
    load: true,
    text: "Chi siamo",
  },
  {
    content_name: "what_we_do",
    language_id: 1,
    load: true,
    text: "What we do",
  },
  {
    content_name: "what_we_do",
    language_id: 2,
    load: true,
    text: "Cosa facciamo",
  },
  {
    content_name: "news_events",
    language_id: 1,
    load: true,
    text: "News & Events",
  },
  {
    content_name: "news_events",
    language_id: 2,
    load: true,
    text: "Notizie ed eventi",
  },
  {
    content_name: "subscription_title",
    language_id: 1,
    load: true,
    text: "Get updates about Invisible Project in your mailbox",
  },
  {
    content_name: "subscription_title",
    language_id: 2,
    load: true,
    text: "Ricevi aggiornamenti su Invisible Project nella tua casella di posta",
  },
  {
    content_name: "subscribe",
    language_id: 1,
    load: true,
    text: "Subscribe",
  },
  {
    content_name: "subscribe",
    language_id: 2,
    load: true,
    text: "Sottoscrivi",
  },
  {
    content_name: "subscription_checkbox",
    language_id: 1,
    load: true,
    text: "Ho preso visione della Privacy Policy e Terms of Service apply.",
  },
  {
    content_name: "subscription_checkbox",
    language_id: 2,
    load: true,
    text: "Ho preso visione della Privacy Policy e Terms of Service apply.",
  },
  {
    content_name: "subscription_mail_placeholder",
    language_id: 1,
    load: true,
    text: "Your email address",
  },
  {
    content_name: "subscription_mail_placeholder",
    language_id: 2,
    load: true,
    text: "Il tuo indirizzo di posta elettronica",
  },
  {
    content_name: "footer_about",
    language_id: 1,
    load: true,
    text: "This project has received funding from the European Erasmus+ programme under grant agreement N°2021-1-IT02-KA220-HED-000031139.",
  },
  {
    content_name: "footer_about",
    language_id: 2,
    load: true,
    text:
      "Questo progetto ha ricevuto finanziamenti dal\n" +
      "Programma europeo Erasmus&#43; in borsa\n" +
      " accordo N°2021-1-IT02-KA220-HED-000031139. Il\n" +
      "Sostegno della Commissione Europea al\n" +
      " la produzione di questo sito web non costituisce\n" +
      " un'approvazione dei contenuti, che rifletta\n" +
      " le opinioni solo degli autori, e il\n" +
      " La Commissione non può essere ritenuta responsabile per alcun\n" +
      " uso che può essere fatto delle informazioni\n" +
      "in esso contenuto.",
  },
  {
    content_name: "footer_detail",
    language_id: 1,
    load: true,
    text: "The European Commission's support for the production of this website does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.",
  },
  {
    content_name: "footer_detail",
    language_id: 2,
    load: true,
    text: "Il supporto della Commissione Europea per la produzione di questo sito web non costituisce un avallo dei contenuti, che riflettono solo le opinioni degli autori, e la Commissione non può essere ritenuta responsabile per qualsiasi uso che possa essere fatto delle informazioni in esso contenute .",
  },
  {
    content_name: "footer_legal",
    language_id: 1,
    load: true,
    text: "Legal",
  },
  {
    content_name: "footer_legal",
    language_id: 2,
    load: true,
    text: "Legale",
  },
  {
    content_name: "footer_cookie",
    language_id: 1,
    load: true,
    text: "Cookie Policy",
  },
  {
    content_name: "footer_cookie",
    language_id: 2,
    load: true,
    text: "Gestione dei Cookie",
  },
  {
    content_name: "footer_privacy",
    language_id: 1,
    load: true,
    text: "Privacy Policy",
  },
  {
    content_name: "footer_privacy",
    language_id: 2,
    load: true,
    text: "Politica sulla riservatezza",
  },
  {
    content_name: "search_placeholder",
    language_id: 1,
    load: true,
    text: "type your search text",
  },
  {
    content_name: "search_placeholder",
    language_id: 2,
    load: true,
    text: "digita il testo di ricerca",
  },
  {
    content_name: "search_placeholder_2",
    language_id: 1,
    load: true,
    text: "or click search",
  },
  {
    content_name: "search_placeholder_2",
    language_id: 2,
    load: true,
    text: "digita il testo di ricerca",
  },
  {
    content_name: "search",
    language_id: 1,
    load: true,
    text: "Search",
  },
  {
    content_name: "search",
    language_id: 2,
    load: true,
    text: "Ricerca",
  },
  {
    content_name: "search_quick_results",
    language_id: 1,
    load: true,
    text: "Quick results",
  },
  {
    content_name: "search_quick_results",
    language_id: 2,
    load: true,
    text: "Risultati rapidi",
  },
  {
    content_name: "search_all_results",
    language_id: 1,
    load: true,
    text: "All results",
  },
  {
    content_name: "search_all_results",
    language_id: 2,
    load: true,
    text: "Tutti i risultati",
  },
  {
    content_name: "search_categories",
    language_id: 1,
    load: true,
    text: "Categories",
  },
  {
    content_name: "search_categories",
    language_id: 2,
    load: true,
    text: "Categorie",
  },
  {
    content_name: "search_years",
    language_id: 1,
    load: true,
    text: "Years",
  },
  {
    content_name: "search_years",
    language_id: 2,
    load: true,
    text: "Anni",
  },
  {
    content_name: "search_months",
    language_id: 1,
    load: true,
    text: "Months",
  },
  {
    content_name: "search_months",
    language_id: 2,
    load: true,
    text: "Mesi",
  },
  {
    content_name: "search_results",
    language_id: 1,
    load: true,
    text: "Search Results",
  },
  {
    content_name: "search_results",
    language_id: 2,
    load: true,
    text: "Risultati di ricerca",
  },
  {
    content_name: "search_results_subtitle_1",
    language_id: 1,
    load: true,
    text: "We’ve found",
  },
  {
    content_name: "search_results_subtitle_1",
    language_id: 2,
    load: true,
    text: "Abbiamo trovato",
  },
  {
    content_name: "search_results_subtitle_2",
    language_id: 1,
    load: true,
    text: "results for",
  },
  {
    content_name: "search_results_subtitle_2",
    language_id: 2,
    load: true,
    text: "risultati di",
  },
  {
    content_name: "partners_title",
    language_id: 1,
    load: true,
    text: "Partners",
  },
  {
    content_name: "partners_title",
    language_id: 2,
    load: true,
    text: "Partner",
  },
  {
    content_name: "partners_subtitle",
    language_id: 1,
    load: true,
    text: "Lorem Ipsum Si Dolor Amet",
  },
  {
    content_name: "partners_subtitle",
    language_id: 2,
    load: true,
    text: "Lorem Ipsum Si Dolor Amet",
  },
  {
    content_name: "consortium_title",
    language_id: 1,
    load: true,
    text: "Consortium",
  },
  {
    content_name: "consortium_title",
    language_id: 2,
    load: true,
    text: "Consorzio",
  },
  {
    content_name: "consortium_subtitle",
    language_id: 1,
    load: true,
    text: "Lorem Ipsum Si Dolor Amet",
  },
  {
    content_name: "consortium_subtitle",
    language_id: 2,
    load: true,
    text: "Lorem Ipsum Si Dolor Amet",
  },
  {
    content_name: "highlights_title",
    language_id: 1,
    load: true,
    text: "Highlights",
  },
  {
    content_name: "highlights_title",
    language_id: 2,
    load: true,
    text: "Mette in risalto",
  },
  {
    content_name: "consortium_search_category",
    language_id: 1,
    load: true,
    text: "Consortium",
  },
  {
    content_name: "partners_search_category",
    language_id: 1,
    load: true,
    text: "Associated Partners",
  },
  {
    content_name: "project_search_category",
    language_id: 1,
    load: true,
    text: "Project",
  },
  {
    content_name: "objectives_search_category",
    language_id: 1,
    load: true,
    text: "Objectives",
  },
  {
    content_name: "actions_search_category",
    language_id: 1,
    load: true,
    text: "Actions",
  },
  {
    content_name: "project_results_search_category",
    language_id: 1,
    load: true,
    text: "Project Results",
  },
  {
    content_name: "download_search_category",
    language_id: 1,
    load: true,
    text: "Download",
  },
  {
    content_name: "news_search_category",
    language_id: 1,
    load: true,
    text: "News",
  },
  {
    content_name: "events_search_category",
    language_id: 1,
    load: true,
    text: "Events",
  },
  {
    content_name: "project_title",
    language_id: 1,
    load: true,
    text: "Project",
  },
  {
    content_name: "consortium_title",
    language_id: 1,
    load: true,
    text: "Consortium",
  },
  {
    content_name: "partners_title",
    language_id: 1,
    load: true,
    text: "Associated\nPartners",
  },
  {
    content_name: "objectives",
    language_id: 1,
    load: true,
    text: "Objectives",
  },
  {
    content_name: "actions",
    language_id: 1,
    load: true,
    text: "Actions",
  },
  {
    content_name: "action_title",
    language_id: 1,
    load: true,
    text: "Action",
  },
  {
    content_name: "project_results",
    language_id: 1,
    load: true,
    text: "Project Results",
  },
  {
    content_name: "home_partners_title",
    language_id: 1,
    load: true,
    text: "Partners",
  },
  {
    content_name: "home",
    language_id: 1,
    load: true,
    text: "Home",
  },
  {
    content_name: "news_title",
    language_id: 1,
    load: true,
    text: "News",
  },
  {
    content_name: "news_events_title",
    language_id: 1,
    load: true,
    text: "News & Events",
  },
  {
    content_name: "event_agenda_text",
    language_id: 1,
    load: true,
    text: "Click here to download the Agenda.",
  },
  {
    content_name: "event_presentations_title",
    language_id: 1,
    load: true,
    text: "Presentations",
  },
  {
    content_name: "event_details_title",
    language_id: 1,
    load: true,
    text: "Details",
  },
  {
    content_name: "event_start_title",
    language_id: 1,
    load: true,
    text: "Start",
  },
  {
    content_name: "event_end_title",
    language_id: 1,
    load: true,
    text: "End",
  },
  {
    content_name: "event_event_category_title",
    language_id: 1,
    load: true,
    text: "Event Category",
  },
  {
    content_name: "event_organizer_title",
    language_id: 1,
    load: true,
    text: "Organizer",
  },
  {
    content_name: "event_venues_title",
    language_id: 1,
    load: true,
    text: "Venues",
  },
  {
    content_name: "coming_soon",
    language_id: 1,
    load: true,
    text: "Coming soon",
  },
  {
    content_name: "download_click_here_to_download",
    language_id: 1,
    load: true,
    text: "Click here to download.",
  },
  {
    content_name: "resources",
    language_id: 1,
    load: true,
    text: "Resources",
  },
  {
    content_name: "downloads",
    language_id: 1,
    load: true,
    text: "Download",
  },
  {
    content_name: "teaching_learning_tools",
    language_id: 1,
    load: true,
    text: "Teaching & Learning Tools",
  },
  {
    content_name: "image_classifier",
    language_id: 1,
    load: true,
    text: "Image Classifier",
  },
  {
    content_name: "editor_menu",
    language_id: 1,
    load: true,
    text: "Editor Menu",
  },
  {
    content_name: "approve_users",
    language_id: 1,
    load: true,
    text: "Approve Users",
  },
  {
    content_name: "user_menu",
    language_id: 1,
    load: true,
    text: "User Menu",
  },
  {
    content_name: "list_news",
    language_id: 1,
    load: true,
    text: "News",
  },
  {
    content_name: "news",
    language_id: 1,
    load: true,
    text: "News",
  },
  {
    content_name: "events",
    language_id: 1,
    load: true,
    text: "Events",
  },
  {
    content_name: "approve_news",
    language_id: 1,
    load: true,
    text: "Approve News",
  },
  {
    content_name: "list_downloads",
    language_id: 1,
    load: true,
    text: "Downloads",
  },
  {
    content_name: "approve_downloads",
    language_id: 1,
    load: true,
    text: "Approve Downloads",
  },
  {
    content_name: "list_events",
    language_id: 1,
    load: true,
    text: "Events",
  },
  {
    content_name: "user",
    language_id: 1,
    load: true,
    text: "Users",
  },
  {
    content_name: "approve_events",
    language_id: 1,
    load: true,
    text: "Approve Events",
  },
  {
    content_name: "approve_project",
    language_id: 1,
    load: true,
    text: "Approve Project",
  },
  {
    content_name: "consortium_members",
    language_id: 1,
    load: true,
    text: "Consortium Members",
  },
  {
    content_name: "approve_consortium_member",
    language_id: 1,
    load: true,
    text: "Approve Consortium Member",
  },
  {
    content_name: "partner_members",
    language_id: 1,
    load: true,
    text: "Associated Partner Members",
  },
  {
    content_name: "approve_partner_member",
    language_id: 1,
    load: true,
    text: "Approve Partner Member",
  },
  {
    content_name: "approve_consortium",
    language_id: 1,
    load: true,
    text: "Approve Consortium",
  },
  {
    content_name: "approve_partner",
    language_id: 1,
    load: true,
    text: "Approve Partners",
  },
  {
    content_name: "partner",
    language_id: 1,
    load: true,
    text: "Partners",
  },
  {
    content_name: "approve_objectives",
    language_id: 1,
    load: true,
    text: "Approve Objectives",
  },
  {
    content_name: "objectives",
    language_id: 1,
    load: true,
    text: "Objectives",
  },
  {
    content_name: "gallery",
    language_id: 1,
    load: true,
    text: "Gallery",
  },
  {
    content_name: "approve_gallery",
    language_id: 1,
    load: true,
    text: "Approve Gallery",
  },
  {
    content_name: "project_detail",
    language_id: 1,
    load: true,
    text: "Project Details",
  },
  {
    content_name: "approve_project_detail",
    language_id: 1,
    load: true,
    text: "Approve Project Details",
  },
  {
    content_name: "project_result",
    language_id: 1,
    load: true,
    text: "Project Results",
  },
  {
    content_name: "approve_project_result",
    language_id: 1,
    load: true,
    text: "Approve Project Results",
  },
  {
    content_name: "actions",
    language_id: 1,
    load: true,
    text: "Actions",
  },
  {
    content_name: "approve_actions",
    language_id: 1,
    load: true,
    text: "Approve Actions",
  },
  {
    content_name: "action_item",
    language_id: 1,
    load: true,
    text: "Action Items",
  },
  {
    content_name: "approve_action_item",
    language_id: 1,
    load: true,
    text: "Approve Action Items",
  },
  {
    content_name: "he_inclusive_innovative_didactic_modules",
    language_id: 1,
    load: true,
    text: "HE Inclusive and Innovative Didactic Modules",
  },
  {
    content_name: "typological_courses",
    language_id: 1,
    load: true,
    text: "Typological Courses",
  },
  {
    content_name: "typological_courses_files",
    language_id: 1,
    load: true,
    text: "Typological Courses -> Files",
  },
  {
    content_name: "chronological_courses",
    language_id: 1,
    load: true,
    text: "Chronological Courses",
  },
  {
    content_name: "chronological_courses_files",
    language_id: 1,
    load: true,
    text: "Chronological Courses -> Files",
  },
  {
    content_name: "guidelines_for_users_title",
    language_id: 1,
    load: true,
    text: "Guidelines for Users",
  },
  {
    content_name: "3d_architectural_models",
    language_id: 1,
    load: true,
    text: "3D Architectural Models",
  },
  {
    content_name: "files",
    language_id: 1,
    load: true,
    text: "Files",
  },
  {
    content_name: "gallery",
    language_id: 1,
    load: true,
    text: "Gallery",
  },
  {
    content_name: "guidelines",
    language_id: 1,
    load: true,
    text: "Guidelines",
  },
  {
    content_name: "guidelines_files",
    language_id: 1,
    load: true,
    text: "User Guidelines -> Files",
  },
  {
    content_name: "3D_architectural_models_accessible_to_vib",
    language_id: 1,
    load: true,
    text: "3D Architectural Models Accessible to VIB",
  },
  {
    content_name: "3D_architectural_models_accessible_to_vib_files",
    language_id: 1,
    load: true,
    text: "3D Architectural Models Accessible to VIB Files",
  },
  {
    content_name: "make_architecture_arts_accessible_to_vib",
    language_id: 1,
    load: true,
    text: "Guidelines to make architecture & arts accessible to VIB",
  },
  {
    content_name: "make_architecture_arts_accessible_to_vib_files",
    language_id: 1,
    load: true,
    text: "Guidelines to make architecture & arts accessible to VIB -> Files",
  },
  {
    content_name: "image_captioning_dataset_and_system",
    language_id: 1,
    load: true,
    text: "Image Captioning Dataset and System",
  },
  {
    content_name: "image_captioning_moocs_web_hosts",
    language_id: 1,
    load: true,
    text: "Image Captioning Dataset and System & MOOCs Web Hosts",
  },
  {
    content_name: "drawing_files_files",
    language_id: 1,
    load: true,
    text: "Drawing Files -> Files",
  },
  {
    content_name: "gallery_files",
    language_id: 1,
    load: true,
    text: "Gallery -> Files",
  },
  {
    content_name: "idm_guidelines",
    language_id: 1,
    load: true,
    text: "User Guidelines",
  },
  {
    content_name: "drawing_files",
    language_id: 1,
    load: true,
    text: "Drawing Files",
  },
  {
    content_name: "3d_models_guidelines",
    language_id: 1,
    load: true,
    text: "Guidelines for the design and realization of 3D architectural models accessible to VIB",
  },
  {
    content_name: "3d_models_guidelines_files",
    language_id: 1,
    load: true,
    text: "Guidelines for the design and realization of 3D architectural models accessible to VIB -> Files",
  },
  {
    content_name: "moocs",
    language_id: 1,
    load: true,
    text: "MOOCs",
  },
  {
    content_name: "courses",
    language_id: 1,
    load: true,
    text: "Courses",
  },
  {
    content_name: "course_basic_info",
    language_id: 1,
    load: true,
    text: "Course Basic Info",
  },
  {
    content_name: "course_basic_info",
    language_id: 1,
    load: true,
    text: "Basic Information about the course",
  },
  {
    content_name: "about_the_course",
    language_id: 1,
    load: true,
    text: "About the Course",
  },
  {
    content_name: "structure_of_the_course",
    language_id: 1,
    load: true,
    text: "Structure of Course and Short Description of Modules",
  },
  {
    content_name: "project_info",
    language_id: 1,
    load: true,
    text: "Project Info",
  },
  {
    content_name: "prerequisite_requirements",
    language_id: 1,
    load: true,
    text: "Prerequisite Requirements",
  },
  {
    content_name: "quizzes_tests_scores",
    language_id: 1,
    load: true,
    text: "Info about Quizzes, Tests and Scores",
  },
  {
    content_name: "rules_certificate",
    language_id: 1,
    load: true,
    text: "Rules of Certificate of Accomplishment",
  },
  {
    content_name: "authors",
    language_id: 1,
    load: true,
    text: "Course Authors",
  },
  {
    content_name: "course_module",
    language_id: 1,
    load: true,
    text: "Course Modules",
  },
  {
    content_name: "course_module_part",
    language_id: 1,
    load: true,
    text: "Course Module Parts",
  },
  {
    content_name: "course_module_part_file",
    language_id: 1,
    load: true,
    text: "Course Module Part Files",
  },
  {
    content_name: "course_module_part_quiz",
    language_id: 1,
    load: true,
    text: "Course Module Part Quizzes",
  },
];
