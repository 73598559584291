import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getData as getFileData } from "../../store/actions/general/file";
import PDFViewer from "./PDFViewer";
import DOCViewer from "./DOCViewer";

const FileViewer = ({ fileId }) => {
  const dispatch = useDispatch();
  const fileData = useSelector((state) => state.file.fileData);

  const [file, setFile] = useState(fileData[fileId]);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [progress, setProgress] = useState(0);

  const onError = (error) => {
    console.error(error);
  };

  const onDocumentLoadSuccess = (data) => {
    setNumPages(data.numPages);
  };

  const onLoadProgress = ({ loaded, total }) => {
    setProgress((loaded * 100) / total);
  };

  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  useEffect(() => {
    if (fileId) {
      getFileData(dispatch, fileId);
    }
  }, [fileId, dispatch]);

  if (!fileId || !file) {
    return <></>;
  }

  switch (file.file.mime_type) {
    case "application/pdf": {
      return <PDFViewer file={file}></PDFViewer>;
    }
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
      return <DOCViewer file={file}></DOCViewer>;
    }
    case "application/octet-stream": {
      return <DOCViewer file={file}></DOCViewer>;
    }
    case "Unknown file type": {
      return <DOCViewer file={file}></DOCViewer>;
    }
    default: {
      alert("Unknown file type: " + file.file.mime_type);
    }
  }
  return <></>;
};

export default FileViewer;
