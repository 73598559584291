import "../../../../../assets/scss/components/page/private/Page.scss";
import React, { useEffect, useState } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "../../../../../store/constant";
import { getAll as getAllCourses } from "../../../../../store/actions/mooc/course/course";
import { getAll as getAllModules } from "../../../../../store/actions/mooc/course/module";
import {
  getAll,
  SAVE,
} from "../../../../../store/actions/mooc/course/module_part";
import { useNavigate } from "react-router-dom";
import { raiseError } from "../../../../../store/actions/general/error";
import Message from "../../../../../ui-component/Message";
import { getAddLink, getId, getTitle } from "../../../private_menu_util";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import IconEdit from "../../../../../assets/images/operations/icon-edit.png";

const ListCourseModulePart = () => {
  const MENU = "MOOCs - Course Module Part";

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const contents = useSelector((state) => state.main.contents);

  const userType = useSelector((state) => state.login.userType);
  const dataList = useSelector(
    (state) => state.courseModulePart.courseModuleParts
  );
  const coursesList = useSelector((state) => state.course.courses);
  const modulesList = useSelector((state) => state.courseModule.courseModules);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        setSaveSuccess(true);
        getAll(dispatch);
        getAllModules(dispatch);
        getAllCourses(dispatch);
        break;
      }
    }
  }, [dispatch, loading?.completedOperation]);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    } else {
      getAll(dispatch);
      getAllModules(dispatch);
      getAllCourses(dispatch);
    }
  }, [dispatch, userType]);

  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  if (userType) {
    return (
      <Page title={contents[getTitle(MENU)]} id={getId(MENU)} type={"style1"}>
        <Section fullWidth={true} centered={true} display={saveSuccess}>
          <Message
            operation={setSaveSuccess}
            operationHandler={handleSuccessClose}
            text={
              contents[getTitle(MENU)] +
              " Change Request Submitted Successfully."
            }
          ></Message>
        </Section>
        <Section centered={true}>
          <MainCard style={{ width: "100%" }}>
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} sm={12}>
                <>
                  {coursesList.map((c) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ justifyContent: "left !important" }}
                      >
                        <Typography>
                          <strong>{c.name}</strong>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {modulesList.map((m) =>
                          m.course_id === c.id ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>
                                  <strong>
                                    {m.order} - {m.name} ({m.location})
                                  </strong>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <MainCard style={{ width: "100%" }}>
                                  <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12} sm={12}>
                                      <Button
                                        variant="contained"
                                        style={{
                                          padding: "9px 16px",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        onClick={() =>
                                          navigate(getAddLink(MENU), {
                                            state: {
                                              module: m,
                                              course: c,
                                            },
                                          })
                                        }
                                      >
                                        Add
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TableContainer component={Paper}>
                                        <Table
                                          size="medium"
                                          aria-label="a dense table"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="left">
                                                Name
                                              </TableCell>
                                              <TableCell align="center">
                                                Actions
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {dataList?.map((d) =>
                                              d.module_id === m.id ? (
                                                <TableRow
                                                  key={d.name}
                                                  className={"list"}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {d.name}
                                                    <br></br>
                                                    Module Part: {d.order}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    <Tooltip
                                                      title="Edit"
                                                      disableInteractive
                                                    >
                                                      <IconButton
                                                        color="primary"
                                                        aria-label={
                                                          "Update the " +
                                                          contents[
                                                            getTitle(MENU)
                                                          ] +
                                                          "!"
                                                        }
                                                        onClick={() =>
                                                          navigate(
                                                            getAddLink(MENU),
                                                            {
                                                              state: {
                                                                data: d,
                                                                module: m,
                                                                course: c,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={IconEdit}
                                                          width="35rem"
                                                          height="35rem"
                                                          alt="edit"
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                                                <></>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </MainCard>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <></>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              </Grid>
            </Grid>
          </MainCard>
        </Section>
      </Page>
    );
  }
};

export default ListCourseModulePart;
