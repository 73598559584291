import * as actionTypes from "../../actions/user/login_count";

export const initialState = {
  data: null,
  getSuccess: false,
};

// ==============================|| PATIENTS REDUCER ||============================== //

const loginCount = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET.name:
      return {
        ...state,
        data: action.data,
        getSuccess: action.success,
      };
    default:
      return state;
  }
};

export default loginCount;
