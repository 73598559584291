import React, { useEffect } from "react";
import "../../../../assets/scss/views/private/Dashboard.scss";
import "../../../../assets/scss/components/page/private/Page.scss";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../../../ui-component/page/private";
import { useNavigate } from "react-router-dom";
import ApproveWebSiteContent from "../../website-editor/approve/ApproveWebSiteContent";
import EditWebSiteContent from "../../website-editor/approve/EditWebSiteContent";
import EditContent from "../../editor/approve/EditContent";
import ApproveContent from "../../editor/approve/ApproveContent";
import ApproveUsers from "../approve/ApproveUsers";
import {
  getAll,
  getAllWebSite,
} from "../../../../store/actions/user/approvals";
import {
  get as getLoginCount,
} from "../../../../store/actions/user/login_count";
import LoginCount from "../login-count/LoginCount";

const Dashboard = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const userType = useSelector((state) => state.login.userType);
  const userName = useSelector((state) => state.login.username);

  useEffect(() => {
    if (!(userType && userName)) {
      navigate("/login");
    } else {
      if (userType === "Web Site Editor") {
        getLoginCount(dispatch);
        getAllWebSite(dispatch);
        getAll(dispatch);
      }
      if (userType === "Editor" || userType === "Content Editor") {
        getLoginCount(dispatch);
        getAll(dispatch);
      }
    }
  }, [dispatch]);

  if (userName && userType) {
    return (
      <Page
        id={"dashboard"}
        type={"style1"}
        title={userType + " Panel"}
        subTitle={"User: " + userName}
      >
        {userType === "Web Site Editor" || userType === "Editor" ? (
          <>
            <LoginCount></LoginCount>
            <ApproveUsers></ApproveUsers>
          </>
        ) : (
          <></>
        )}
        {userType === "Web Site Editor" ? (
          <>
            <ApproveWebSiteContent></ApproveWebSiteContent>
            <EditWebSiteContent></EditWebSiteContent>
          </>
        ) : (
          <></>
        )}
        {userType === "Web Site Editor" ||
        userType === "Editor" ||
        userType === "Content Editor" ? (
          <>
            <ApproveContent></ApproveContent>
            <EditContent></EditContent>
          </>
        ) : (
          <></>
        )}
      </Page>
    );
  }

  return <></>;
};

export default Dashboard;
