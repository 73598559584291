import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import React, {useState} from "react";
import {getFilePath} from "../../store/actions/general/file";
import {Document, Page, pdfjs} from "react-pdf";
import {CircularProgress, Typography} from "@mui/material";
import {Pagination} from "@mui/lab";
import AudioPlayer from "../AudioPlayer";
import Button from "@mui/material/Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({file}) => {
    const [numPages, setNumPages] = useState(0);
    const [pageScale, setPageScale] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [progress, setProgress] = useState(0);

    const onError = (error) => {
        console.error(error);
    };

    const onDocumentLoadSuccess = (data) => {
        setNumPages(data.numPages);
    };

    const onLoadProgress = ({loaded, total}) => {
        setProgress((loaded * 100) / total);
    };

    const handleChange = (event, value) => {
        setPageNumber(value);
    };

    const handleZoomIn = () => {
        if (pageScale < 3) {
            setPageScale(pageScale + 0.2);
        }
    }

    const handleZoomOut = () => {
        if (pageScale > 0.3) {
            setPageScale(pageScale - 0.2);
        }
    }

    if (!file) {
        return <></>;
    }

    return (
        <div className={"pdfViewer"}>
            {file.generate_audio &&
            file.audio_files &&
            file.audio_files.length === numPages ? (
                <AudioPlayer audio={file?.audio_files[pageNumber - 1]}></AudioPlayer>
            ) : (
                <></>
            )}

            {progress === 100 ? (
                <div className={"pagination"}>
                    <Typography>Page: {pageNumber}</Typography>
                    <Pagination
                        count={numPages}
                        page={pageNumber}
                        onChange={handleChange}
                    />
                </div>
            ) : (
                <></>
            )}

            {progress < 100 ? (
                <div className={"progressBar"}>
                    <CircularProgress variant="determinate" value={progress}/>
                </div>
            ) : (
                <></>
            )}

            <div className={"zoom"}>
                <Button variant="outlined" onClick={handleZoomIn} disabled={pageScale >= 3}>
                    Zoom +
                </Button>
                <Typography>Scale: {parseInt(pageScale * 100)}%</Typography>
                <Button variant="outlined" onClick={handleZoomOut} disabled={pageScale <= 0.3}>
                    Zoom -
                </Button>
            </div>

            <Document
                file={getFilePath(file)}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onError}
                onLoadProgress={onLoadProgress}
                loading={<div>Please wait!</div>}
            >
                <Page pageNumber={pageNumber} scale={pageScale}/>
            </Document>
        </div>
    );
};

export default PDFViewer;
