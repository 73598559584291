import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setMenuStatus,
  setSelectedHeaderMenu,
} from "../../../../store/actions/general/main";
import { Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

const HeaderMenu = ({ menu, getLink }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const contents = useSelector((state) => state.main.contents);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event, link) => {
    setSelectedHeaderMenu(dispatch, null);
    setMenuStatus(dispatch, "closed");
    handleClose();
    navigate(link);
  };

  const handleMenuKeyUp = (event, link) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      setSelectedHeaderMenu(dispatch, null);
      setMenuStatus(dispatch, "closed");
      navigate(link);
    }
  };

  if (menu.children) {
    return (
      <div>
        <Button
          id={"container" + menu.name}
          aria-controls={open ? menu.name : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<ExpandMoreIcon />}
        >
          {contents ? contents[menu.title] : ""}
        </Button>
        <Menu
          id={menu.name}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "container" + menu.name,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menu?.children.map((subMenu, j) => {
            return (
              <MenuItem
                key={j}
                disableRipple
                onClick={(event) =>
                  handleMenuClick(event, getLink(subMenu.name))
                }
                onKeyUp={(event) =>
                  handleMenuKeyUp(event, getLink(subMenu.name))
                }
              >
                {contents ? contents[subMenu.title] : ""}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          id={"container" + menu.name}
          aria-controls={open ? menu.name : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={(event) => handleMenuClick(event, getLink(menu.name))}
        >
          {contents ? contents[menu.title] : ""}
        </Button>
        <Menu
          id={menu.name}
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          MenuListProps={{
            "aria-labelledby": "container" + menu.name,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        ></Menu>
      </div>
    );
  }
};

export default HeaderMenu;
