// action - state management
import * as actionTypes from '../../actions/general/loading';

export const initialState = {
    inProgress: [],
    completedOperation: null
};

// ==============================|| PATIENTS REDUCER ||============================== //

const loading = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET: {
            return {
                ...state,
                inProgress: state.inProgress.concat(action.data)
            };
        }
        case actionTypes.RESET: {
            return {
                ...state,
                inProgress: []
            };
        }
        case actionTypes.UNSET: {
            state.inProgress = state.inProgress.filter(function(item) {
                return item !== action.data
            });
            return {
                ...state,
                inProgress: state.inProgress,
                completedOperation: action.data
            };
        }
        default:
            return state;
    }
};

export default loading;
