import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { raiseError } from "../../../../store/actions/general/error";
import { useDispatch, useSelector } from "react-redux";

const Course = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const location = useLocation();
  const course = location?.state?.course;

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  return (
    <Page id={course.id} type={"style1"}>
      <Section centered={true}></Section>
    </Page>
  );
};

export default Course;
