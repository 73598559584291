import {Typography} from "@mui/material";
import Page from "../../../../ui-component/page/public";
import Section from "../../../../ui-component/page/public/section";
import error from "../../../../store/reducers/general/error";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
const HtmlToReactParser = require('html-to-react').Parser;

const Error = () => {
    let navigate = useNavigate();
    const error = useSelector((state) => state.error);
    let errorMessage = error?.error?.response?.data?.message ? error?.error?.response?.data?.message : error?.error?.message;

    if (!errorMessage || errorMessage.length === 0) {
        navigate("/");
        return <></>;
    }

    errorMessage = errorMessage.replace("\n", "<br/>");
    return (
        <Page title={""} id={"error"} type={"style1"}>
            <Section fullWidth={true} centered={true}>
                <Typography variant="h3" style={{color: 'red', textAlign: "center"}}>
                    {new HtmlToReactParser().parse(errorMessage)}
                </Typography>
            </Section>
        </Page>
    );
};

export default Error;
