import "../../../../assets/scss/components/page/private/Page.scss";

function Section({title, fullWidth, marginContent, centered, children, display}) {
    if (display === undefined || display === true) {
        return (
            <div className={"section " + (fullWidth ? "fullWidth" : "")}>
                {
                    title ?
                        <div className={"title"}>
                            <p>{title}</p>
                        </div>
                        : <></>
                }
                <div
                    className={"content " + (marginContent ? "marginContent" : "") + " " + (centered ? "centered" : "")}>
                    {children}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

export default Section;