import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  GET_BY_MODULE_PART,
  SAVE,
} from "../../../actions/mooc/course/module_part_quiz";

export const initialState = {
  courseModulePartQuizzes: [],
  courseModulePartQuizzesFull: {},
  waitingCourseModulePartQuizzes: [],
};

const courseModulePartQuiz = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        courseModulePartQuizzesFull: {
          ...state.courseModulePartQuizzesFull,
          [id]: action.data,
        },
      };
    case GET_ALL.name:
      return {
        ...state,
        courseModulePartQuizzes: action.data,
      };
    case GET_BY_MODULE_PART.name:
      return {
        ...state,
        courseModulePartQuizzes: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingCourseModulePartQuizzes: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default courseModulePartQuiz;
