import React, { useEffect } from "react";
import "../assets/scss/components/FileUploader.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addFile,
  addFilePreview,
  removeFile,
  reset,
} from "../store/actions/general/file_uploader";
import FileUpload from "react-mui-fileuploader";
import Image from "mui-image";
import {
  getData as getImageData,
  getImagePath,
} from "../store/actions/general/image";
import {
  getData as getFileData,
  getFilePath,
} from "../store/actions/general/file";
import IconDelete from "../assets/images/operations/icon-close.png";
import { IconButton } from "@mui/material";

const FileUploader = ({
  id,
  files,
  maxFileAllowed = 1,
  isImage = false,
  isFile = false,
  maxFileSize = 2,
}) => {
  const dispatch = useDispatch();

  const imageData = useSelector((state) => state.image.imageData);
  const fileData = useSelector((state) => state.file.fileData);
  const filePreview = useSelector((state) => state.fileUploader.filePreview);

  useEffect(() => {
    reset(dispatch, id);

    if (!files) {
      return;
    }

    for (let file of files) {
      if (file) {
        if (isImage) {
          getImageData(dispatch, file);
        }

        if (isFile) {
          getFileData(dispatch, file);
        }
      }
    }
  }, [files, dispatch]);

  useEffect(() => {
    if (!files) {
      return;
    }

    for (let file of files) {
      if (file) {
        if (isImage && imageData[file]) {
          addFile(dispatch, id, "old", imageData[file].id, imageData[file]);

          addFilePreview(dispatch, id, imageData[file].name, {
            url: getImagePath(imageData[file]),
            name: imageData[file].name,
            id: imageData[file].id,
            type: "old",
          });
        }

        if (isFile && fileData[file]) {
          addFile(dispatch, id, "old", fileData[file].id, fileData[file]);

          addFilePreview(dispatch, id, fileData[file].name, {
            url: getFilePath(fileData[file]),
            name: fileData[file].name,
            id: fileData[file].id,
            type: "old",
          });
        }
      }
    }
  }, [imageData, fileData, dispatch]);

  if (!id) {
    return <h3>Empty file uploader id!</h3>;
  }

  const onFileChange = (data) => {
    for (let newFile of data) {
      let reader = new FileReader();
      reader.onload = function (e) {
        addFilePreview(dispatch, id, newFile.name, {
          url: e.target.result,
          name: newFile.name,
          type: "new",
        });
      };
      reader.readAsDataURL(newFile);

      addFile(dispatch, id, "new", newFile.name, newFile);
    }
  };

  const removeImage = (name, _id, type) => {
    if ("old" === type) {
      removeFile(dispatch, id, "old", _id, name);
    }

    if ("new" === type) {
      removeFile(dispatch, id, "new", name, name);
    }
  };

  let acceptedType = "";
  if (isImage) {
    acceptedType = "image/*";
  }

  if (isFile) {
    acceptedType = "application/*";
  }

  if (!filePreview.hasOwnProperty(id)) {
    return <></>;
  }

  return (
    <div className={"fileUploader"}>
      {Object.values(filePreview[id]).map((image, i) => {
        console.error(image)
        return (
          <div className={"image"} key={i}>
            {isImage ? (
              <div className={"up"}>
                <Image src={image.url} style={{ width: "10em" }} />
              </div>
            ) : (
              <a href={image.url} target={"_blank"}>{image.url}</a>
            )}
            <div className={"down"}>
              <p>{image.name}</p>
              <IconButton
                color="primary"
                aria-label="delete"
                onClick={() => {
                  removeImage(image.name, image.id, image.type);
                }}
              >
                <img src={IconDelete} width="20px" height="20px" alt="delete" />
              </IconButton>
            </div>
          </div>
        );
      })}

      {Object.values(filePreview[id]).length >= maxFileAllowed ? (
        <></>
      ) : (
        <FileUpload
          header=""
          leftLabel=""
          rightLabel="to select file"
          acceptedType={acceptedType}
          BannerProps={{ display: "none" }}
          title=""
          multiFile={maxFileAllowed > 1}
          onFilesChange={onFileChange}
          showPlaceholderImage={false}
          maxFileSize={maxFileSize}
        />
      )}
    </div>
  );
};

export default FileUploader;
