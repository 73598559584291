import {GET_ALL, GET_ALL_WEBSITE} from "../../actions/user/approvals";

export const initialState = {
    approvals: [],
    websiteApprovals: [],
};

const approvals = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL:
            return {
                ...state,
                approvals: action.data
            };
        case GET_ALL_WEBSITE:
            return {
                ...state,
                websiteApprovals: action.data
            };
        default:
            return state;
    }
};

export default approvals;
