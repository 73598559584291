import React, { useEffect } from "react";
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/NewsDetail.scss";
import Page from "../../../ui-component/page/public";
import Section from "../../../ui-component/page/public/section";
import { useLocation } from "react-router-dom";
import {
  getData as getImageData,
  getImagePath,
} from "../../../store/actions/general/image";
import { Parser as HtmlToReactParser } from "html-to-react";
import { getSimpleDate } from "../../../util/date_util";
import { useDispatch, useSelector } from "react-redux";

const NewsDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const news = location?.state?.news;
  const imageData = useSelector((state) => state.image.imageData);

  useEffect(() => {
    getImageData(dispatch, news.image);
  }, [dispatch, news?.image]);

  useEffect(() => {
    for (let item of news.gallery) {
      getImageData(dispatch, item);
    }
  }, [dispatch, news?.gallery]);

  const htmlToReactParser = new HtmlToReactParser();

  if (!news) {
    return <></>;
  }

  return (
    <Page
      id={"newsDetail"}
      type={"style1"}
      title={news.name}
      subTitle={"(" + getSimpleDate(news.date) + ")"}
    >
      <Section fullWidth={true} centered={true}>
        {imageData[news?.image] ? (
          <img src={getImagePath(imageData[news?.image])} alt={news?.name} />
        ) : (
          <></>
        )}
      </Section>
      <Section>
        <div className={"left-side"}>
          <div className={"info"}>{htmlToReactParser.parse(news?.info)}</div>
        </div>
      </Section>
      {news.gallery.map((gallery) => {
        if (!imageData[gallery]) {
          return <></>;
        }

        return (
          <Section fullWidth={true} centered={true}>
            <img
              src={getImagePath(imageData[gallery])}
              alt={imageData[gallery].name}
            />
          </Section>
        );
      })}
    </Page>
  );
};

export default NewsDetail;
