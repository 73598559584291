import { getMenu } from "./public_menu_util";

export const ContentMenus = [
  {
    name: "Project",
    title: "project",
    link: "project",
  },
  {
    name: "Project Details",
    title: "project_details",
    link: "project-details",
  },
  {
    name: "Consortium",
    title: "consortium",
    link: "consortium",
  },
  {
    name: "Consortium Members",
    title: "consortium_members",
    link: "consortium-members",
  },
  {
    name: "Partners",
    title: "partners",
    link: "partners",
  },
  {
    name: "Partner Members",
    title: "partner_members",
    link: "partner-members",
  },
  {
    name: "Objectives",
    title: "objectives",
    link: "objectives",
  },
  {
    name: "Actions",
    title: "actions",
    link: "actions",
  },
  {
    name: "Action Items",
    title: "action_item",
    link: "action-items",
  },
  {
    name: "Project Results",
    title: "project_results",
    link: "project-results",
  },
  {
    name: "Resources",
    title: "resources",
    link: "downloads",
  },
  {
    name: "News",
    title: "news",
    link: "news",
  },
  {
    name: "Events",
    title: "events",
    link: "events",
  },
  {
    name: "News & Events",
    title: "news_events",
    link: "news-events",
  },
  {
    name: "Gallery",
    title: "gallery",
    link: "gallery",
  },
];

export const HeaderMenuTree = [
  {
    title: "who_we_are",
    name: "Who We Are",
    children: [getMenu("Project"), getMenu("Consortium"), getMenu("Partners")],
  },
  {
    title: "what_we_do",
    name: "What We Do",
    children: [
      getMenu("Objectives"),
      getMenu("Actions"),
      getMenu("Project Results"),
    ],
  },
  getMenu("Resources"),
  getMenu("News & Events"),
];

export const ContentMenuTree = [
  {
    title: "who_we_are",
    name: "Who We Are",
    children: [
      getMenu("Project"),
      getMenu("Project Details"),
      getMenu("Consortium"),
      getMenu("Consortium Members"),
      getMenu("Partners"),
      getMenu("Partner Members"),
    ],
  },
  {
    title: "what_we_do",
    name: "What We Do",
    children: [
      getMenu("Objectives"),
      getMenu("Actions"),
      getMenu("Action Items"),
      getMenu("Project Results"),
    ],
  },
  {
    title: "resources",
    name: "Resources",
    children: [getMenu("Resources")],
  },
  {
    title: "home",
    name: "Home",
    children: [getMenu("Gallery"), getMenu("Project Details")],
  },
  {
    title: "news_events",
    name: "News & Events",
    children: [getMenu("News"), getMenu("Events")],
  },
];
