import "../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  get,
  save,
  SAVE,
} from "../../../../store/actions/consortium/consortium";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Message from "../../../../ui-component/Message";
import HTMLEditor from "../../../../ui-component/Editor";
import { reset as resetEditor } from "../../../../store/actions/general/editor";

const ListConsortium = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const username = useSelector((state) => state.login.userId);
  const userType = useSelector((state) => state.login.userType);
  const consortium = useSelector((state) => state.consortium.consortium);
  const loading = useSelector((state) => state.loading);
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);

  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    } else {
      get(dispatch);
    }
  }, [userType, dispatch]);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        setSaveSuccess(true);
        get(dispatch);
        break;
      }
    }
  }, [loading?.completedOperation, dispatch]);

  const edit = () => {
    save(
      dispatch,
      {},
      {
        id: consortium.id,
        consortium_id: consortium.id,
        info: editorHTMLs["info"],
        owner: username,
        status: "UPDATE",
      }
    );

    resetEditor(dispatch);
    navigate("/website-editor/home");
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  if (userType) {
    return (
      <Page title={"Consortium"} id={"listConsortium"} type={"style1"}>
        <Section fullWidth={true} centered={true} display={saveSuccess}>
          <Message
            operation={saveSuccess}
            operationHandler={handleSuccessClose}
            text="Consortium Change Request Submitted Successfully."
          ></Message>
        </Section>
        <Section centered={true}>
          <MainCard style={{ width: "100%" }}>
            <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}>
                          <InputLabel>Info</InputLabel>
                          <HTMLEditor id={"info"} html={consortium?.info} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Stack
                  direction="row"
                  spacing={2}
                  style={{ marginTop: "1rem" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </Section>
      </Page>
    );
  }
};

export default ListConsortium;
