import React, { useEffect } from "react";
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/ProjectResults.scss";
import { useDispatch, useSelector } from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import { get, getAll } from "../../../store/actions/project/projectResults";
import { getData, getImagePath } from "../../../store/actions/general/image";
import { useParams } from "react-router";
import { Parser as HtmlToReactParser } from "html-to-react";

const ProjectResults = () => {
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);
  const params = useParams();
  const projectResults = useSelector(
    (state) => state.projectResults.projectResults
  );
  const projectResultsFull = useSelector(
    (state) => state.projectResults.projectResultsFull
  );
  const imageData = useSelector((state) => state.image.imageData);
  const htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    getAll(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (projectResults) {
      for (let projectResult of projectResults) {
        getData(dispatch, projectResult.image);
        get(dispatch, projectResult.id);
      }
    }
  }, [projectResults, dispatch]);

  if (!projectResults) {
    return <></>;
  }

  return (
    <Page id={"projectResults"} type={"style1"}>
      <Section>
        <div className={"left-side"}>
          <div className={"title"}>
            <p>{contents ? contents["project_results"] : ""}</p>
          </div>
        </div>
        <div className={"right-side"}>
          <p className={"content"}>
            {contents ? contents["project_results_detail_1"] : ""}
          </p>
        </div>
      </Section>
      {projectResults.map((projectResult, j) => {
        projectResult = projectResultsFull[projectResult.id];
        if (!projectResult) {
          return <></>;
        }

        let subTitle = "" + (j + 1);
        return (
          <Section key={j}>
            <div className={"left-side"}>
              <div className={projectResult.circle}></div>
              {imageData[projectResult.image] ? (
                <img
                  className={"image"}
                  src={getImagePath(imageData[projectResult.image])}
                  alt={"Profile"}
                />
              ) : (
                <></>
              )}
            </div>
            <div className={"right-side"}>
              <input
                ref={(ref) => {
                  if (j === params.id) {
                    if (ref) {
                      ref.focus();
                    }
                  }
                }}
                value={subTitle}
                className={"sub_title"}
                readOnly
              ></input>
              <p className={"title"}>{projectResult.name}</p>
              <div className={"content"}>
                {htmlToReactParser.parse(projectResult.info)}
              </div>
              <div className={"divider"}></div>
            </div>
          </Section>
        );
      })}
      <Section>
        <div className={"left-side"}>
          <div className={"title"}>
            <p>{contents ? contents["project_results"] : ""}</p>
          </div>
        </div>
        <div className={"right-side"}>
          <p className={"content"}>
            {contents ? contents["project_results_detail_2"] : ""}
          </p>
        </div>
      </Section>
    </Page>
  );
};

export default ProjectResults;
