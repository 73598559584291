import React, {useEffect} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Consortium.scss";
import Page from "../../../ui-component/page/public";
import {useDispatch, useSelector} from "react-redux";
import {get as getConsortium} from "../../../store/actions/consortium/consortium";
import {get, getAll as getAllConsortiumMembers} from "../../../store/actions/consortium/consortiumMember";
import Section from "../../../ui-component/page/public/section";
import {getData as getImageData, getImagePath} from "../../../store/actions/general/image";
import {Parser as HtmlToReactParser} from "html-to-react";

const Consortium = ({name, info, logo, link}) => {
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.image.imageData);

    useEffect(() => {
        getImageData(dispatch, logo);
    }, [logo]);

    const htmlToReactParser = new HtmlToReactParser();
    const handleClick = () => {
        window.open(link);
    };

    const handleKeyup = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            window.open(link);
        }
    };

    return (
        <Section>
            <div role={"button"} tabIndex={0} className={"left-side clickable"} onClick={handleClick}
                 onKeyUp={handleKeyup}>
                {imageData[logo] ? <img
                    alt=""
                    className={"image"}
                    src={getImagePath(imageData[logo])}
                /> : <></>}
            </div>
            <div className={"right-side"}>
                <div className={"consortium"}>
                    <p className={"title"}>{name}</p>
                </div>
                <div className={"detail"}>
                    {htmlToReactParser.parse(info)}
                </div>
                <div className={"divider"}></div>
            </div>
        </Section>
    );
};

const Consortiums = () => {
    const htmlToReactParser = new HtmlToReactParser();
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const consortiumMembers = useSelector((state) => state.consortiumMember.consortiumMembers);
    const consortiumMembersFull = useSelector((state) => state.consortiumMember.consortiumMembersFull);
    const consortium = useSelector((state) => state.consortium.consortium);

    useEffect(() => {
        getAllConsortiumMembers(dispatch);
        getConsortium(dispatch);
    }, []);

    useEffect(() => {
        for(let item of consortiumMembers){
            get(dispatch, item.id);
        }
    }, [consortiumMembers]);

    if (!consortium) {
        return <></>
    }

    if (!consortiumMembersFull) {
        return <></>
    }

    return (
        <Page id={"consortium"} type={"style1"}>
            <Section>
                <div className={"left-side"}>
                    <div className={"title"}>
                        <p>{contents ? contents["consortium_title"] : ""}</p>
                    </div>
                </div>
                <div className={"right-side"}>
                    <div className={"content"}>{htmlToReactParser.parse(consortium.info)}</div>
                </div>
            </Section>
            {
                Object.values(consortiumMembersFull)?.map((consortium, j) => {
                    return <Consortium name={consortium.name} link={consortium.url} info={consortium.info}
                                       logo={consortium.image} key={j}></Consortium>
                })
            }
        </Page>
    );
};

export default Consortiums;
