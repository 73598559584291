import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SearchButton from "../../../../assets/images/search/search.png";
import { setSearchStatus } from "../../../../store/actions/general/search";

const Search = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearchModalClick = (event) => {
    setSearchStatus(dispatch, "active");
  };

  const handleSearchModalKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      navigate("/search");
    }
  };

  return (
    <div
      className={"clickable headerItem search"}
      role={"button"}
      tabIndex={0}
      onClick={handleSearchModalClick}
      onKeyUp={handleSearchModalKeyDown}
    >
      <img src={SearchButton} alt={"Search"}></img>
    </div>
  );
};

export default Search;
