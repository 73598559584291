import {GET_DATA} from "../../actions/general/audio";

export const initialState = {
    audioData: {},
};

const audio = (state = initialState, action) => {
    switch (action.type) {
        case GET_DATA:
            if (action.data) {
                let id = action.data.id;
                return {
                    ...state,
                    audioData: {...state.audioData, [id]: action.data},
                };
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default audio;
