import React, { useEffect } from "react";
import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../../../store/actions/mooc/course/module_part";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import IconModule from "../../../../assets/images/mooc/tree/module.png";
import IconFile from "../../../../assets/images/mooc/tree/module_file.png";
import Box from "@mui/material/Box";
import Image from "mui-image";
import { getAll as getAllModulePartFiles } from "../../../../store/actions/mooc/course/module_part_file";
import { getData } from "../../../../store/actions/general/file";
import { raiseError } from "../../../../store/actions/general/error";

const CourseAdditionalMaterials = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  let navigate = useNavigate();
  const location = useLocation();
  const menu = location?.state?.menu;
  const module = menu?.selector;
  const moduleParts = useSelector(
    (state) => state.courseModulePart.courseModuleParts
  );

  const docList = useSelector(
    (state) => state.courseModulePartFile.courseModulePartFiles
  );

  const fileData = useSelector((state) => state.file.fileData);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  useEffect(() => {
    getAll(dispatch);
    getAllModulePartFiles(dispatch);
  }, [dispatch]);

  useEffect(() => {
    for (let doc of docList) {
      getData(dispatch, doc.file);
    }
  }, [dispatch, docList]);


  const handleClick = (title, data) => {
    navigate("/user/mooc/module-part-file", {
      state: {
        file: data,
        title: title,
      },
    });
  };

  if (!module) {
    return <></>;
  }

  return (
    <Page
      title={menu.title}
      subTitle={"Location: " + module.location}
      id={"courseModule"}
      type={"mooc"}
    >
      <Section>
        <List style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignSelf: 'center'
        }}>
          {moduleParts?.map((modulePart, i) =>
            modulePart.module_id === module.id ? (
              <>
                <ListItemButton key={i}  className={'clickable'}
                                style={{display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center'
                                }}>
                  <ListItemIcon>
                    <Box color="inherit" sx={{ mr: 1 }}>
                      <Image src={IconModule} style={{ width: "7em" }} />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={modulePart.name} />
                </ListItemButton>
                <List>
                  {docList?.map((doc, j) =>
                    doc.module_part_id === modulePart.id &&
                    doc.type === "Additional Material" &&
                    Object.keys(fileData).includes(String(doc.file)) ? (
                      <ListItemButton
                        key={i * 1000 + j}
                        onClick={() =>
                          handleClick(doc.name, fileData[doc.file])
                        }
                        className={'clickable'}
                        style={{display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <ListItemIcon>
                          <Box color="inherit" sx={{ mr: 1 }}>
                            <Image src={IconFile} style={{ width: "7em" }} />
                          </Box>
                        </ListItemIcon>
                        <ListItemText primary={doc.name} />
                      </ListItemButton>
                    ) : (
                      <></>
                    )
                  )}
                </List>
              </>
            ) : (
              <></>
            )
          )}
        </List>
      </Section>
    </Page>
  );
};

export default CourseAdditionalMaterials;
