// project imports
import MainLayout from "layout/MainLayout";
import ApproveNews from "../views/private/website-editor/news/ApproveNews";
import ViewNewsDetail from "../views/private/website-editor/news/ViewNewsDetail";
import ApproveDownloads from "../views/private/website-editor/download/ApproveDownloads";
import ViewDownloadDetail from "../views/private/website-editor/download/ViewDownloadDetail";
import ApproveEvents from "../views/private/website-editor/event/ApproveEvents";
import ViewEventDetail from "../views/private/website-editor/event/ViewEventDetail";
import ViewProjectDetail from "../views/private/website-editor/project/ViewProjectDetail";
import ApproveProject from "../views/private/website-editor/project/ApproveProject";
import ApproveConsortiumMember from "../views/private/website-editor/consortium_member/ApproveConsortiumMember";
import ViewConsortiumMemberDetail from "../views/private/website-editor/consortium_member/ViewConsortiumMemberDetail";
import ApprovePartnerMember from "../views/private/website-editor/partner_member/ApprovePartnerMember";
import ViewPartnerMemberDetail from "../views/private/website-editor/partner_member/ViewPartnerMemberDetail";
import ApproveConsortium from "../views/private/website-editor/consortium/ApproveConsortium";
import ViewConsortiumDetail from "../views/private/website-editor/consortium/ViewConsortiumDetail";
import ApprovePartner from "../views/private/website-editor/partner/ApprovePartner";
import ViewPartnerDetail from "../views/private/website-editor/partner/ViewPartnerDetail";
import ApproveObjectives from "../views/private/website-editor/objectives/ApproveObjectives";
import ViewObjectiveDetail from "../views/private/website-editor/objectives/ViewObjectiveDetail";
import ApproveGallery from "../views/private/website-editor/gallery/ApproveGallery";
import ViewGalleryDetail from "../views/private/website-editor/gallery/ViewGalleryDetail";
import ViewProjectDetailDetail from "../views/private/website-editor/project_details/ViewProjectDetailDetail";
import ApproveProjectDetail from "../views/private/website-editor/project_details/ApproveProjectDetail";
import ApproveProjectResult from "../views/private/website-editor/project_results/ApproveProjectResult";
import ViewProjectResultDetail from "../views/private/website-editor/project_results/ViewProjectResultDetail";
import ApproveActions from "../views/private/website-editor/actions/ApproveActions";
import ViewActionsDetail from "../views/private/website-editor/actions/ViewActionsDetail";
import ApproveActionItem from "../views/private/website-editor/action_items/ApproveActionItem";
import ViewActionItemDetail from "../views/private/website-editor/action_items/ViewActionItemDetail";
import Dashboard from "../views/private/editor-common/dashboard";
import ListNews from "../views/private/website-editor/news/ListNews";
import AddNews from "../views/private/website-editor/news/AddNews";
import ListDownloads from "../views/private/website-editor/download/ListDownloads";
import AddDownload from "../views/private/website-editor/download/AddDownload";
import ListEvents from "../views/private/website-editor/event/ListEvents";
import AddEvent from "../views/private/website-editor/event/AddEvent";
import AddProject from "../views/private/website-editor/project/AddProject";
import ListProject from "../views/private/website-editor/project/ListProject";
import ListConsortiumMembers from "../views/private/website-editor/consortium_member/ListConsortiumMembers";
import AddConsortiumMember from "../views/private/website-editor/consortium_member/AddConsortiumMember";
import ListPartnerMember from "../views/private/website-editor/partner_member/ListPartnerMember";
import AddPartnerMember from "../views/private/website-editor/partner_member/AddPartnerMember";
import ListConsortium from "../views/private/website-editor/consortium/ListConsortium";
import ListPartner from "../views/private/website-editor/partner/ListPartner";
import ListObjectives from "../views/private/website-editor/objectives/ListObjectives";
import AddObjective from "../views/private/website-editor/objectives/AddObjective";
import ListGallery from "../views/private/website-editor/gallery/ListGallery";
import AddGallery from "../views/private/website-editor/gallery/AddGallery";
import ListProjectDetails from "../views/private/website-editor/project_details/ListProjectDetails";
import AddProjectDetail from "../views/private/website-editor/project_details/AddProjectDetail";
import ListProjectResults from "../views/private/website-editor/project_results/ListProjectResults";
import AddProjectResult from "../views/private/website-editor/project_results/AddProjectResult";
import ListActions from "../views/private/website-editor/actions/ListActions";
import ListActionItems from "../views/private/website-editor/action_items/ListActionItems";
import AddActionItem from "../views/private/website-editor/action_items/AddActionItem";

// ==============================|| MAIN ROUTING ||============================== //

const WebSiteEditorRoutes = {
  path: "/website-editor/",
  element: <MainLayout />,
  children: [
    {
      path: "/home",
      element: <Dashboard />,
    },
    {
      path: "/news/list",
      element: <ListNews />,
    },
    {
      path: "/news/add",
      element: <AddNews />,
    },
    {
      path: "/downloads/list",
      element: <ListDownloads />,
    },
    {
      path: "/downloads/add",
      element: <AddDownload />,
    },
    {
      path: "/events/list",
      element: <ListEvents />,
    },
    {
      path: "/events/add",
      element: <AddEvent />,
    },
    {
      path: "/project/add",
      element: <AddProject />,
    },
    {
      path: "/project/list",
      element: <ListProject />,
    },
    {
      path: "/consortium-member/list",
      element: <ListConsortiumMembers />,
    },
    {
      path: "/consortium-member/add",
      element: <AddConsortiumMember />,
    },
    {
      path: "/partner-members/list",
      element: <ListPartnerMember />,
    },
    {
      path: "/partner-members/add",
      element: <AddPartnerMember />,
    },
    {
      path: "/consortium/list",
      element: <ListConsortium />,
    },
    {
      path: "/partners/list",
      element: <ListPartner />,
    },
    {
      path: "/objective/list",
      element: <ListObjectives />,
    },
    {
      path: "/objective/add",
      element: <AddObjective />,
    },
    {
      path: "/gallery/list",
      element: <ListGallery />,
    },
    {
      path: "/gallery/add",
      element: <AddGallery />,
    },
    {
      path: "/project_details/list",
      element: <ListProjectDetails />,
    },
    {
      path: "/project-detail/add",
      element: <AddProjectDetail />,
    },
    {
      path: "/project-result/list",
      element: <ListProjectResults />,
    },
    {
      path: "/project-result/add",
      element: <AddProjectResult />,
    },
    {
      path: "/actions/list",
      element: <ListActions />,
    },
    {
      path: "/action-item/list",
      element: <ListActionItems />,
    },
    {
      path: "/action-item/add",
      element: <AddActionItem />,
    },
    {
      path: "/news/approve",
      element: <ApproveNews />,
    },
    {
      path: "/news/view",
      element: <ViewNewsDetail />,
    },
    {
      path: "/downloads/approve",
      element: <ApproveDownloads />,
    },
    {
      path: "/downloads/view",
      element: <ViewDownloadDetail />,
    },
    {
      path: "/events/approve",
      element: <ApproveEvents />,
    },
    {
      path: "/events/view",
      element: <ViewEventDetail />,
    },
    {
      path: "/project/approve",
      element: <ApproveProject />,
    },
    {
      path: "/project/view",
      element: <ViewProjectDetail />,
    },
    {
      path: "/consortium-member/approve",
      element: <ApproveConsortiumMember />,
    },
    {
      path: "/consortium-member/view",
      element: <ViewConsortiumMemberDetail />,
    },
    {
      path: "/partner-members/approve",
      element: <ApprovePartnerMember />,
    },
    {
      path: "/partner-members/view",
      element: <ViewPartnerMemberDetail />,
    },
    {
      path: "/consortium/approve",
      element: <ApproveConsortium />,
    },
    {
      path: "/consortium/view",
      element: <ViewConsortiumDetail />,
    },
    {
      path: "/partners/approve",
      element: <ApprovePartner />,
    },
    {
      path: "/partners/view",
      element: <ViewPartnerDetail />,
    },
    {
      path: "/objective/approve",
      element: <ApproveObjectives />,
    },
    {
      path: "/objective/view",
      element: <ViewObjectiveDetail />,
    },
    {
      path: "/gallery/approve",
      element: <ApproveGallery />,
    },
    {
      path: "/gallery/view",
      element: <ViewGalleryDetail />,
    },
    {
      path: "/project-detail/approve",
      element: <ApproveProjectDetail />,
    },
    {
      path: "/project-detail/view",
      element: <ViewProjectDetailDetail />,
    },
    {
      path: "/project-result/approve",
      element: <ApproveProjectResult />,
    },
    {
      path: "/project-result/view",
      element: <ViewProjectResultDetail />,
    },
    {
      path: "/actions/approve",
      element: <ApproveActions />,
    },
    {
      path: "/actions/view",
      element: <ViewActionsDetail />,
    },
    {
      path: "/action-item/approve",
      element: <ApproveActionItem />,
    },
    {
      path: "/action-item/view",
      element: <ViewActionItemDetail />,
    },
  ],
};

export default WebSiteEditorRoutes;
