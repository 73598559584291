export const SET_COURSE = "@mooc/SET_COURSE";

export const SET_MENU = "@mooc/SET_MENU";

export const setCourse = (dispatch, course) => {
  dispatch({ type: SET_COURSE, data: course });
};

export const setMenu = (dispatch, menu) => {
  dispatch({ type: SET_MENU, data: menu });
};
