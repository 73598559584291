import { HeaderMenuTree } from "../../../../views/public/public_menus";
import HeaderMenu from "./HeaderMenu";
import { getLink } from "../../../../views/public/public_menu_util";

const PublicHeaderMenu = () => {
  return (
    <>
      {HeaderMenuTree.map((level1, i) => {
        return (
          <HeaderMenu key={i} menu={level1} getLink={getLink}></HeaderMenu>
        );
      })}
    </>
  );
};

export default PublicHeaderMenu;
