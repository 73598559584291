import "../../../../assets/scss/components/page/private/Page.scss";
import {useEffect, useState} from 'react';
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {
    InputLabel,
    Stack,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {save} from "../../../../store/actions/home/gallery";
import {getData} from "../../../../store/actions/general/image";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {raiseError} from "../../../../store/actions/general/error";
import {reset as resetFileUploader} from "../../../../store/actions/general/file_uploader";
import FileUploader from "../../../../ui-component/FileUploader";

const AddGallery = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const username = useSelector((state) => state.login.userId);
    const gallery = location?.state?.gallery;
    const newFiles = useSelector((state) => state.fileUploader.newFiles);
    const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

    const [text, setText] = useState(gallery ? gallery.text : '');
    const [image, setImage] = useState(gallery ? [gallery.image] : []);

    useEffect(() => {
        if (gallery) {
            setText(gallery.text);
            setImage([gallery.image]);

            if (gallery?.image) {
                getData(dispatch, gallery.image);
            }
        }
    }, [gallery, dispatch]);


    const add = () => {
        if (text.length === 0) {
            raiseError(dispatch, {
                message: "Empty text!"
            });
            return;
        }

        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {text: text, owner: username, status: "ADD", images: []});

        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const edit = () => {
        save(dispatch, {
            "image": Object.values(newFiles["image"])
        }, {
            id: gallery.id,
            gallery_id: gallery.id,
            images: Object.keys(oldFiles["image"]),
            text: text,
            owner: username,
            status: "UPDATE"
        });

        resetFileUploader(dispatch);
        navigate("/website-editor/home");
    };

    const handleChangeText = (event) => {
        setText(event.target.value);
    };

    return (
        <Page title={"Add Gallery"} id={"addGallery"} type={"style1"}>
            <Section centered={true}>
                <MainCard title="Add/Edit Gallery" style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table size="medium" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <TextField
                                                    fullWidth
                                                    label="Text"
                                                    id="text"
                                                    value={text}
                                                    onChange={handleChangeText}
                                                    error={text.length > 0 && text.length < 3}
                                                    helperText={
                                                        text && (text.length < 3) ? 'Enter a valid text.' : ''
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{borderBottom: 'none'}}>
                                                <InputLabel>Image</InputLabel>
                                                <FileUploader id={"image"} files={image}
                                                              isImage={true}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Stack direction="row" spacing={2} style={{marginTop: '1rem'}}>
                                {gallery ? (
                                    <Button type="submit" variant="contained" margin="normal" onClick={edit}>
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={add}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default AddGallery;
