import { _approve, _delete, _get, CommonActions, getAction } from "../../util";
import { post } from "../../../fw/web_service_caller";

const NAME = "user";

export const GET_ALL = getAction(NAME, CommonActions.GET_ALL);

export const GET = getAction(NAME, CommonActions.GET);
export const DELETE = getAction(NAME, CommonActions.DELETE);
export const APPROVE = getAction(NAME, CommonActions.APPROVE);
export const MAKE_EDITOR = getAction(NAME, "makeEditor");
export const UPDATE_INFO = getAction(NAME, "updateInfo");

export const getAll = (dispatch) => {
  _get(dispatch, GET_ALL, {});
};

export const get = (dispatch, id) => {
  _get(dispatch, GET, id);
};

export const del = (dispatch, data) => {
  _delete(
    (response) => {
      dispatch(response);
      getAll(dispatch);
    },
    DELETE,
    data
  );
};

export const makeEditor = (dispatch, data) => {
  post(
    (response) => {
      dispatch(response);
      getAll(dispatch);
    },
    MAKE_EDITOR.name,
    MAKE_EDITOR.method,
    data
  );
};

export const approve = (dispatch, data) => {
  _approve(dispatch, APPROVE, data);
};
