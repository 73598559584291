import "../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../ui-component/page/private";
import Section from "../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { get, save, SAVE } from "../../../../store/actions/partners/partner";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Message from "../../../../ui-component/Message";
import { raiseError } from "../../../../store/actions/general/error";
import { reset } from "../../../../store/actions/general/editor";
import HTMLEditor from "../../../../ui-component/Editor";

const ListPartner = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const username = useSelector((state) => state.login.userId);
  const userType = useSelector((state) => state.login.userType);
  const partner = useSelector((state) => state.partner.partner);
  const loading = useSelector((state) => state.loading);
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);

  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    } else {
      get(dispatch);
    }
  }, [userType, dispatch]);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        setSaveSuccess(true);
        get(dispatch);
        break;
      }
    }
  }, [loading?.completedOperation, dispatch]);

  const edit = () => {
    save(
      dispatch,
      {},
      {
        id: partner.id,
        partner_id: partner.id,
        info: editorHTMLs["info"],
        detail: editorHTMLs["detail"],
        owner: username,
        status: "UPDATE",
      }
    );

    reset(dispatch);
    navigate("/website-editor/home");
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSaveSuccess(false);
  };

  if (userType) {
    return (
      <Page title={"Partners"} id={"listPartners"} type={"style1"}>
        <Section fullWidth={true} centered={true} display={saveSuccess}>
          <Message
            operation={saveSuccess}
            operationHandler={handleSuccessClose}
            text="Partners Change Request Submitted Successfully."
          ></Message>
        </Section>
        <Section centered={true}>
          <MainCard style={{ width: "100%" }}>
            <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="medium" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}>
                          <InputLabel>Info</InputLabel>
                          <HTMLEditor id={"info"} html={partner?.info} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ borderBottom: "none" }}>
                          <InputLabel>Detail</InputLabel>
                          <HTMLEditor id={"detail"} html={partner?.detail} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Stack
                  direction="row"
                  spacing={2}
                  style={{ marginTop: "1rem" }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </Section>
      </Page>
    );
  }
};

export default ListPartner;
