import {useEffect, useState} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Search.scss";
import Page from "../../../ui-component/page/public";
import Row from "../../../ui-component/page/public/row";
import SearchIcon from "../../../assets/images/search/search.png";
import UpIcon from "../../../assets/images/common/chevron-up.png";
import DownIcon from "../../../assets/images/common/chevron-down.png";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from "../../../ui-component/checkbox";
import {getCategories, search} from "../../../store/actions/general/search";
import {useParams} from "react-router";
import {getData, getImagePath} from "../../../store/actions/general/image";
import IconNoImage from "../../../assets/images/common/no_image.png";
import {Parser as HtmlToReactParser} from "html-to-react";

function FilterItem({label, number, checked, handleFilterChanged}) {
    let id = label;
    label = label + " (" + number + ")";
    return (
        <Checkbox label={label} checked={checked} setStatusFunction={handleFilterChanged} id={id}></Checkbox>
    )
}

function SearchResult({image, title, subtitle, category, date}) {
    const dispatch = useDispatch();
    const imageData = useSelector((state) => state.image.imageData);
    const htmlToReactParser = new HtmlToReactParser();

    useEffect(() => {
        if (image) {
            getData(dispatch, image);
        }
    }, [image, dispatch]);

    return (
        <div className={"card"}>
            {
                imageData[image] ? <img className={"image"} src={getImagePath(imageData[image])} alt={"Card"}/> :
                    <img src={IconNoImage} alt={title} style={{width: "10em"}}/>
            }
            <div className={"container"}>
                <div className={"title"}>
                    <div className={"info"}>
                        <p className={"category"}>{category}</p>
                    </div>
                    <p className={"title"}>{title}</p>
                    <p className={"subtitle"}>{htmlToReactParser.parse(subtitle)}</p>
                </div>
                <p className={"date"}>{date}</p>
            </div>
        </div>
    )
}

const Search = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const contents = useSelector((state) => state.main.contents);
    const results = useSelector((state) => state.search.results);
    const [filteredResults, setFilteredResults] = useState(results);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        getCategories(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (searchText.trim().length > 0) {
            search(dispatch, searchText);
        }
    }, [searchText, dispatch]);

    useEffect(() => {
        if (results) {
            setFilteredResults(results);
            initFilters();
        }
    }, [results]);

    useEffect(() => {
        if (params?.searchText) {
            setSearchText(params?.searchText);
        }
    }, []);

    const [categoriesOpen, setCategoriesOpen] = useState(false);
    const [yearsOpen, setYearsOpen] = useState(false);
    const [monthsOpen, setMonthsOpen] = useState(false);

    const [categoriesFilters, setCategoriesFilters] = useState([]);
    const [yearsFilters, setYearsFilters] = useState([]);
    const [monthsFilters, setMonthsFilters] = useState([]);

    useEffect(() => {
        filterResults();
    }, [categoriesFilters, yearsFilters, monthsFilters]);

    const handleCategoriesOpenClick = () => {
        setCategoriesOpen(!categoriesOpen);
    };

    const handleYearsOpenClick = () => {
        setYearsOpen(!yearsOpen);
    };

    const handleMonthsOpenClick = () => {
        setMonthsOpen(!monthsOpen);
    };

    const initFilters = () => {
        let _categoriesFilter = [];
        let _yearsFilter = [];
        let _monthsFilter = [];

        for (let item of results) {
            let found = false;
            for (let idx in _categoriesFilter) {
                if (_categoriesFilter[idx].name === item.action.text) {
                    found = true;
                    _categoriesFilter[idx].count += 1;
                }
            }
            if (!found) {
                _categoriesFilter.push({
                    name: item.action.text,
                    count: 1,
                    checked: true
                });
                setCategoriesOpen(true);
            }

            let regex = /.*(20[0-9]{2}).*/;
            let result = regex.exec(item.date);

            if (result) {
                let year = result[1];

                found = false;
                for (let idx in _yearsFilter) {
                    if (_yearsFilter[idx].name === year) {
                        found = true;
                        _yearsFilter[idx].count += 1;
                    }
                }
                if (!found) {
                    _yearsFilter.push({
                        name: year,
                        count: 1,
                        checked: true
                    });
                    setYearsOpen(true);
                }
            } else {
                setYearsOpen(false);
            }

            regex = /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/;
            result = regex.exec(item.date);

            if (result) {
                let month = result[1];

                found = false;
                for (let idx in _monthsFilter) {
                    if (_monthsFilter[idx].name === month) {
                        found = true;
                        _monthsFilter[idx].count += 1;
                    }
                }
                if (!found) {
                    _monthsFilter.push({
                        name: month,
                        count: 1,
                        checked: true
                    });
                    setMonthsOpen(true);
                }
            } else {
                setMonthsOpen(false);
            }
        }
        setCategoriesFilters(_categoriesFilter);
        setYearsFilters(_yearsFilter);
        setMonthsFilters(_monthsFilter);
    }

    function filterResults() {
        let filtered = [];
        for (let item of results) {
            let ok = true;
            for (let filter of categoriesFilters) {
                if (item.action.text === filter.name) {
                    ok = ok && filter.checked;
                    break;
                }
            }

            let regex = /(20[0-9]{2})/;
            let result = regex.exec(item.date);

            if (result) {
                let year = result[1];

                for (let filter of yearsFilters) {
                    if (year === filter.name) {
                        ok = ok && filter.checked;
                        break;
                    }
                }
            }

            regex = /(January|February|March|April|May|June|July|August|September|October|November|December)/;
            result = regex.exec(item.date);

            if (result) {
                let month = result[1];

                for (let filter of monthsFilters) {
                    if (month === filter.name) {
                        ok = ok && filter.checked;
                        break;
                    }
                }
            }

            if (ok) {
                filtered.push(item);
            }
        }

        setFilteredResults(filtered);
    }

    function handleSearchChange(event) {
        let text = event.target?.value.toLowerCase();
        setSearchText(text);
    }

    const handleCategoryFilterChanged = (id, checked) => {
        for (let idx in categoriesFilters) {
            if (categoriesFilters[idx].name === id) {
                categoriesFilters[idx].checked = checked;
            }
        }

        setCategoriesFilters(categoriesFilters);

        filterResults();
    };

    const handleYearFilterChanged = (id, checked) => {
        for (let idx in yearsFilters) {
            if (yearsFilters[idx].name === id) {
                yearsFilters[idx].checked = checked;
            }
        }

        setYearsFilters(yearsFilters);

        filterResults();
    };

    const handleMonthFilterChanged = (id, checked) => {
        for (let idx in monthsFilters) {
            if (monthsFilters[idx].name === id) {
                monthsFilters[idx].checked = checked;
            }
        }

        setMonthsFilters(monthsFilters);

        filterResults();
    };

    let info = "" + contents["search_results_subtitle_1"] + " " + filteredResults.length + " " +
        contents["search_results_subtitle_2"] + " “" + searchText + "”.";
    return (
        <Page title={contents ? contents["search_results"] : ""} subTitle={searchText.length > 0 ? info : ""}
              id={"search"} type={"style3"}>
            <Row>
                <div className={"filters"}>
                    <div className={"search"}>
                        <div className={"input"}>
                            <div className={"input"}>
                                <input className={"label"} onChange={handleSearchChange} onKeyUp={handleSearchChange}
                                       placeholder={contents ? contents["search_placeholder"] : ""} value={searchText}/>
                                <img className={"icon"} src={SearchIcon} alt={contents ? contents["search"] : ""}/>
                            </div>
                        </div>
                    </div>
                    <div className={"filterList"}>
                        <div className={"categories"} style={{order: 3}}>
                            <div className={"title"}>
                                <input className={"categories"} value={contents ? contents["search_categories"] : ""}/>
                                <div className={"clickable"} role={"button"} tabIndex={0}
                                     onClick={handleCategoriesOpenClick} onKeyUp={handleCategoriesOpenClick}>
                                    <img className={"icon"} src={categoriesOpen ? UpIcon : DownIcon}
                                         alt={"Show and Hide Categories"}/>
                                </div>
                            </div>
                            {categoriesOpen ?
                                <div className={"items"}>
                                    {
                                        categoriesFilters.map((filter, i) => {
                                            return <FilterItem key={i} label={filter.name} number={filter.count}
                                                               checked={filter.checked}
                                                               handleFilterChanged={handleCategoryFilterChanged}></FilterItem>
                                        })
                                    }
                                </div> : <></>}
                        </div>
                        <div className={"divider"} style={{order: 4}}></div>
                        <div className={"years"} style={{order: 5}}>
                            <div className={"title"}>
                                <input className={"years"} value={contents ? contents["search_years"] : ""}/>
                                <div className={"clickable"} role={"button"} tabIndex={0} onClick={handleYearsOpenClick}
                                     onKeyUp={handleYearsOpenClick}>
                                    <img className={"icon"} src={yearsOpen ? UpIcon : DownIcon}
                                         alt={"Show and Hide Years"}/>
                                </div>
                            </div>
                            {yearsOpen ?
                                <div className={"items"}>
                                    {
                                        yearsFilters.map((filter, i) => {
                                            return <FilterItem key={i} label={filter.name} number={filter.count}
                                                               checked={filter.checked}
                                                               handleFilterChanged={handleYearFilterChanged}></FilterItem>
                                        })
                                    }
                                </div> : <></>}
                        </div>
                        <div className={"divider"} style={{order: 6}}></div>
                        <div className={"months"} style={{order: 7}}>
                            <div className={"title"}>
                                <input className={"months"} value={contents ? contents["search_months"] : ""}/>
                                <div className={"clickable"} role={"button"} tabIndex={0}
                                     onClick={handleMonthsOpenClick} onKeyUp={handleMonthsOpenClick}>
                                    <img className={"icon"} src={monthsOpen ? UpIcon : DownIcon}
                                         alt={"Show and Hide Story Months"}/>
                                </div>
                            </div>
                            {monthsOpen ?
                                <div className={"items"}>
                                    {
                                        monthsFilters.map((filter, i) => {
                                            return <FilterItem key={i} label={filter.name} number={filter.count}
                                                               checked={filter.checked}
                                                               handleFilterChanged={handleMonthFilterChanged}></FilterItem>
                                        })
                                    }
                                </div> : <></>}
                        </div>
                    </div>
                </div>
                <div className={"resultList"}>
                    {
                        filteredResults.map((result, i) => {
                            return <SearchResult key={i} image={result.image} title={result.title}
                                                 subtitle={result.detail}
                                                 category={contents ? contents[result.action] : ""}
                                                 date={result.date}></SearchResult>
                        })
                    }
                </div>
            </Row>
        </Page>
    );
};

export default Search;
