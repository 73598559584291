import "../../../../../assets/scss/components/page/private/Page.scss";
import { useEffect, useState } from "react";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import { useDispatch, useSelector } from "react-redux";
import {
  InputLabel,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { gridSpacing } from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  get,
  save,
  SAVE,
} from "../../../../../store/actions/idm/typological_courses/idm_typological_courses_files";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { raiseError } from "../../../../../store/actions/general/error";
import { reset as resetEditor } from "../../../../../store/actions/general/editor";
import HTMLEditor from "../../../../../ui-component/Editor";
import { getId, getTitle } from "../../../private_menu_util";
import { getData as getFileData } from "../../../../../store/actions/general/file";
import FileUploader from "../../../../../ui-component/FileUploader";

const AddIDMTypologicalCoursesFiles = () => {
  const MENU = "IDM - Typological Courses Files";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const contents = useSelector((state) => state.main.contents);

  const username = useSelector((state) => state.login.userId);
  const id = location?.state?.data?.id;
  const data = useSelector(
    (state) => state.idmTypologicalCoursesFiles.listFull[id]
  );
  const editorHTMLs = useSelector((state) => state.editor.editorHTMLs);
  const newFiles = useSelector((state) => state.fileUploader.newFiles);
  const oldFiles = useSelector((state) => state.fileUploader.oldFiles);

  const [name, setName] = useState(data ? data.name : "");
  const [type, setType] = useState(data ? data.type : "");
  const [file, setFile] = useState(data ? [data.file] : []);

  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    switch (loading?.completedOperation) {
      case SAVE.method: {
        resetEditor(dispatch);
        navigate("/editor/home");
        break;
      }
    }
  }, [loading?.completedOperation, dispatch, navigate]);

  useEffect(() => {
    if (id) {
      get(dispatch, id);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setType(data.type);
      setFile([data.file]);

      if (data.file) {
        getFileData(dispatch, data.file);
      }
    }
  }, [data, dispatch]);

  const add = () => {
    if (name.length === 0) {
      raiseError(dispatch, {
        message: "Empty name!",
      });
      return;
    }

    if (editorHTMLs["info"].length === 0) {
      raiseError(dispatch, {
        message: "Empty info!",
      });
      return;
    }

    if (type.length === 0) {
      raiseError(dispatch, {
        message: "Empty type!",
      });
      return;
    }

    save(
      dispatch,
      {
        file: Object.values(newFiles["file"]),
      },
      {
        name: name,
        info: editorHTMLs["info"],
        type: type,
        owner: username,
        status: "ADD",
        file: [],
      }
    );
  };

  const edit = () => {
    save(
      dispatch,
      {
        file: Object.values(newFiles["file"]),
      },
      {
        id: data.id,
        main_id: data.id,
        file: Object.keys(oldFiles["file"]),
        name: name,
        info: editorHTMLs["info"],
        type: type,
        owner: username,
        status: "UPDATE",
      }
    );
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  return (
    <Page
      title={"Add " + contents[getTitle(MENU)]}
      id={getId(MENU)}
      type={"style1"}
    >
      <Section centered={true}>
        <MainCard
          title={"Add/Edit " + contents[getTitle(MENU)]}
          style={{ width: "100%" }}
        >
          <Grid container spacing={gridSpacing} columns={{ xs: 12 }}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          fullWidth
                          label="Name"
                          id="name"
                          value={name}
                          onChange={handleChangeName}
                          error={name.length > 0 && name.length < 3}
                          helperText={
                            name && name.length < 3 ? "Enter a valid name." : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <TextField
                          fullWidth
                          label="Type"
                          id="type"
                          value={type}
                          onChange={handleChangeType}
                          error={type.length > 0 && type.length < 3}
                          helperText={
                            name && type.length < 3 ? "Enter a valid type." : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <HTMLEditor id={"info"} html={data?.info} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ borderBottom: "none" }}>
                        <InputLabel>File</InputLabel>
                        <FileUploader
                          id={"file"}
                          files={file}
                          isFile={true}
                          maxFileSize={10}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Stack direction="row" spacing={2} style={{ marginTop: "1rem" }}>
                {data ? (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={edit}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    margin="normal"
                    onClick={add}
                  >
                    Save
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>
        </MainCard>
      </Section>
    </Page>
  );
};

export default AddIDMTypologicalCoursesFiles;
