import {
  APPROVE,
  GET,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/actions/actionItems";

export const initialState = {
  actionItems: [],
  actionItemsFull: {},
  waitingActionItems: [],
};

const actionItems = (state = initialState, action) => {
  switch (action.type) {
    case GET.name:
      let id = action.data.id;
      return {
        ...state,
        actionItemsFull: { ...state.actionItemsFull, [id]: action.data },
      };
    case GET_ALL.name:
      return {
        ...state,
        actionItemsFull: {},
        actionItems: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingActionItems: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default actionItems;
