import {applyMiddleware, createStore} from 'redux';
import reducer from './reducer';
import logger from "redux-logger";

// ==============================|| REDUX - MAIN STORE ||============================== //

const middlewares = [logger];
const store = createStore(reducer, applyMiddleware(...middlewares));
const persister = 'Free';

export { store, persister };
