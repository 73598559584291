import "../../../../assets/scss/views/private/Dashboard.scss";
import "../../../../assets/scss/components/page/private/Page.scss";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "../../../../store/constant";
import MainCard from "../../../../ui-component/cards/MainCard";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Section from "../../../../ui-component/page/public/section";

const EditContent = () => {
  let navigate = useNavigate();
  const contents = useSelector((state) => state.main.contents);
  const waitingApprovals = useSelector((state) => state.approvals.approvals);

  const userType = useSelector((state) => state.login.userType);
  if (!("Web Site Editor" === userType || "Editor" === userType)) {
    return <></>;
  }

  let getCount = (category, name = null) => {
    let count = 0;
    for (let item of waitingApprovals) {
      if (item.category === category) {
        if (name) {
          if (item.name === name) {
            return item.count;
          }
        } else {
          count += item.count;
        }
      }
    }

    return count;
  };

  return (
    <Section centered={true}>
      <MainCard title="Approve Users" style={{ width: "100%" }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                size="medium"
                aria-label="a dense table"
                style={{ tableLayout: "fixed" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: "none", padding: "0.5em" }}
                      align={"left"}
                    >
                      <div
                        className={"buttons clickable"}
                        role={"button"}
                        tabIndex={0}
                        onClick={() => navigate("/editor/approveUsers")}
                        onKeyUp={() => navigate("/editor/approveUsers")}
                        style={{ padding: "0.5em" }}
                      >
                        <div className={"up"}>
                          <div className={"headerItem"}>
                            <p className={"label"}>
                              {contents ? contents["user"] : ""} (
                              {getCount("Users", "Users")})
                            </p>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </MainCard>
    </Section>
  );
};

export default EditContent;
