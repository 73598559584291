export const RESET = '@file_uploader/RESET';

export const ADD_FILE = '@file_uploader/ADD_FILE';

export const REMOVE_FILE = '@file_uploader/REMOVE_FILE';

export const ADD_FILE_PREVIEW = '@file_uploader/ADD_FILE_PREVIEW';

export const reset = (dispatch, id) => {
    dispatch({type: RESET, id: id});
};

export const addFile = (dispatch, id, operation, file_id, file_data) => {
    dispatch({type: ADD_FILE, id: id, operation: operation, file_id: file_id, file_data: file_data});
};

export const addFilePreview = (dispatch, id, file_name, file_data) => {
    dispatch({type: ADD_FILE_PREVIEW, id: id, file_name:file_name, file_data: file_data});
};


export const removeFile = (dispatch, id, operation, file_id, file_name) => {
    dispatch({type: REMOVE_FILE, id: id, operation: operation, file_id: file_id, file_name: file_name});
};