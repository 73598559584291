import {
  _approve,
  _get,
  _getAll,
  _getAllWaiting,
  _save,
  CommonActions,
  getAction,
} from "../../../util";

const NAME = "threeDModelsGuidelinesFiles";

export const GET = getAction(NAME, CommonActions.GET);
export const GET_ALL = getAction(NAME, CommonActions.GET_ALL);
export const GET_ALL_WAITING = getAction(NAME, CommonActions.GET_ALL_WAITING);
export const SAVE = getAction(NAME, CommonActions.SAVE);
export const APPROVE = getAction(NAME, CommonActions.APPROVE);

export const getAll = (dispatch) => {
  _getAll(dispatch, GET_ALL);
};

export const get = (dispatch, id) => {
  _get(dispatch, GET, id);
};

export const getAllWaiting = (dispatch) => {
  _getAllWaiting(dispatch, GET_ALL_WAITING);
};

export const save = (dispatch, files, data) => {
  _save(dispatch, SAVE, files, data);
};

export const approve = (dispatch, data) => {
  _approve(dispatch, APPROVE, data);
};
