import { getAction } from "../../util";
import { post } from "../../../fw/web_service_caller";

const NAME = "login";
export const LOGIN = getAction(NAME, "login");

export const LOGOUT = getAction(NAME, "logout");

export const SIGNUP = getAction(NAME, "signUp");

export const login = (dispatch, username, password) => {
  post(dispatch, LOGIN.name, LOGIN.method, {
    username: username,
    password: password,
  });
};

export const logout = (dispatch, username, token) => {
  post(dispatch, LOGOUT.name, LOGOUT.method, {
    username: username,
    token: token,
  });
};

export const signUp = (dispatch, name, email, username, password, type) => {
  post(dispatch, SIGNUP.name, SIGNUP.method, {
    name: name,
    email: email,
    username: username,
    password: password,
    type: type,
  });
};
