import "../../../../../assets/scss/components/page/private/Page.scss";
import Page from "../../../../../ui-component/page/private";
import Section from "../../../../../ui-component/page/private/section";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox, InputLabel, Stack, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import MainCard from "../../../../../ui-component/cards/MainCard";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import {gridSpacing} from "../../../../../store/constant";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {approve} from "../../../../../store/actions/mooc/course/module_part_quiz";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {getId, getTitle} from "../../../private_menu_util";
import React, {useState} from "react";

const ViewCourseModulePartQuizDetail = () => {
    const MENU = "MOOCs - Course Module Part Quiz";

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const contents = useSelector((state) => state.main.contents);

    const userType = useSelector((state) => state.login.userType);
    const userName = useSelector((state) => state.login.username);
    const location = useLocation();
    const data = location?.state?.data;

    const [questions, setQuestions] = useState(data ? data.questions : {});
    const [choices, setChoices] = useState(data ? data.choices : {});
    const [answers, setAnswers] = useState(data ? data.answers : {});
    const [marks, setMarks] = useState(data ? data.marks : {});

    const handleApprove = (status) => {
        approve(dispatch, {
            data: data,
            status: status,
        });
        navigate("/editor/home");
    };

    return (
        <Page
            title={contents[getTitle(MENU)] + " Detail"}
            id={getId(MENU)}
            type={"style1"}
        >
            <Section centered={true}>
                <MainCard title={contents[getTitle(MENU)]} style={{width: "100%"}}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            {Object.keys(questions).length === 0 ? (
                                <></>
                            ) : (
                                <>
                                    <TableContainer component={Paper}>
                                        <Table size="medium" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{borderBottom: "none"}}>
                                                        <InputLabel style={{color: "blue"}}>Language</InputLabel>
                                                        {data.language === 'en' ? 'English' : data.language === 'tr' ? 'Turkish' : data.language === 'it' ? 'Italian' : 'None'}
                                                    </TableCell>
                                                </TableRow>
                                                {Object.keys(questions)?.map((order, i) => {
                                                    return (
                                                        <Table size="medium" aria-label="a dense table">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <InputLabel style={{color: "blue"}}>
                                                                        Question {parseInt(order) + 1}
                                                                    </InputLabel>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <InputLabel style={{color: "blue"}}>
                                                                            Question
                                                                        </InputLabel>
                                                                        {questions[order]}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <InputLabel style={{color: "blue"}}>
                                                                            Mark
                                                                        </InputLabel>
                                                                        {marks[order]}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell component={"td"} scope="row">
                                                                        <Table
                                                                            size="medium"
                                                                            aria-label="a dense table"
                                                                        >
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell align="left">
                                                                                        Choice
                                                                                    </TableCell>
                                                                                    <TableCell align="left">
                                                                                        Is Answer
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {choices[order]?.map((choice, j) => {
                                                                                    return (
                                                                                        <TableRow>
                                                                                            <TableCell
                                                                                                component={"td"}
                                                                                                scope="row"
                                                                                            >
                                                                                                {choice}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                <Checkbox
                                                                                                    disabled={true}
                                                                                                    checked={answers[order][j]}
                                                                                                />
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    );
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}

                            <Stack direction="row" spacing={2} style={{marginTop: "1rem"}}>
                                {(userType === "Web Site Editor" || userType === "Editor") &&
                                data.owner !== userName ? (
                                    <>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            margin="normal"
                                            onClick={() => handleApprove("APPROVE")}
                                        >
                                            Approve
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            margin="normal"
                                            onClick={() => handleApprove("REJECT")}
                                        >
                                            Reject
                                        </Button>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {data.owner === userName ? (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        margin="normal"
                                        onClick={() => handleApprove("REJECT")}
                                    >
                                        Undo
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Section>
        </Page>
    );
};

export default ViewCourseModulePartQuizDetail;
