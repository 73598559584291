import {
  APPROVE,
  GET_ALL,
  GET_ALL_WAITING,
  SAVE,
} from "../../actions/partners/partnerMembers";

export const initialState = {
  partnerMembers: [],
  waitingPartnerMembers: [],
};

const partnerMember = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL.name:
      return {
        ...state,
        partnerMembers: action.data,
      };
    case GET_ALL_WAITING.name:
      return {
        ...state,
        waitingPartnerMembers: action.data,
      };
    case SAVE.name:
      return {
        ...state,
      };
    case APPROVE.name:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default partnerMember;
