import "../../../../assets/scss/views/private/Dashboard.scss";
import "../../../../assets/scss/components/page/private/Page.scss";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { gridSpacing } from "../../../../store/constant";
import MainCard from "../../../../ui-component/cards/MainCard";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableRow } from "@mui/material";
import Section from "../../../../ui-component/page/public/section";

const LoginCount = () => {
  const loginCount = useSelector((state) => state?.loginCount?.data?.count);
  const userType = useSelector((state) => state.login.userType);
  if (!("Web Site Editor" === userType || "Editor" === userType)) {
    return <></>;
  }

  return (
    <Section centered={true}>
      <MainCard title="Statistics" style={{ width: "100%" }}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                size="medium"
                aria-label="a dense table"
                style={{ tableLayout: "fixed" }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{ borderBottom: "none", padding: "0.5em" }}
                      align={"left"}
                    >
                      <div
                        className={"buttons clickable"}
                        role={"button"}
                        tabIndex={0}
                        onClick={() => navigate("/editor/approveUsers")}
                        onKeyUp={() => navigate("/editor/approveUsers")}
                        style={{ padding: "0.5em" }}
                      >
                        <div className={"up"}>
                          <div className={"headerItem"}>
                            <p className={"label"}>
                              <h3>Login Count:</h3> {loginCount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </MainCard>
    </Section>
  );
};

export default LoginCount;
