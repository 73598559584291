// routing
import Routes from 'routes';
import NavigationScroll from "./layout/NavigationScroll";
import {Alert, AlertTitle} from "@mui/lab";
import {CircularProgress, Snackbar} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// ==============================|| APP ||============================== //

const App = () => {
    let navigate = useNavigate();

    const error = useSelector((state) => state.error);
    const errorMessage = error?.error?.response?.data?.message ? error?.error?.response?.data?.message : error?.error?.message;
    const errorType = error?.error?.response?.data?.type ? error?.error?.response?.data?.type : error?.error?.type;
    const errorUrl = error?.error?.config?.url;
    const [errorNotify, setErrorNotify] = useState(false);

    const loading = useSelector((state) => state.loading.inProgress);

    useEffect(() => {
        if (errorMessage) {
            if (!errorType) {
                setErrorNotify(true);
            } else {
                navigate("/error");
            }
        }
    }, [error?.error]);

    const sendErrorMessage = (message) => {
        return message ? message : errorMessage;
    };

    const showErrorMessage = () => {
        const endPath = errorUrl?.substring(errorUrl?.lastIndexOf('/') + 1);
        switch (endPath) {
            case 'a':
                return sendErrorMessage('Login error.');
            default:
                return errorMessage;
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorNotify(false);
    };

    return (
        <NavigationScroll>
            {loading.length > 0 ?
                <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    left: 0,
                    top: 0,
                    background: "white",
                    zIndex: 1000,
                    opacity: 0.7
                }}>
                    <CircularProgress
                        style={{margin: '20% 45%', width: "5em", height: "5em", zIndex: 2000, opacity: 1}}/>
                </div> : <></>}
            <Snackbar
                open={errorNotify}
                autoHideDuration={8000}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                style={{zIndex: 1000}}
            >
                <Alert severity="error" variant="filled" sx={{width: '100%'}} onClose={handleClose}>
                    <AlertTitle>Oops...</AlertTitle>
                    {showErrorMessage()}
                </Alert>
            </Snackbar>
            <Routes/>
        </NavigationScroll>
    );
};

export default App;
