import React, {useEffect, useState} from 'react';
import "../../../assets/scss/components/page/public/Page.scss";
import "../../../assets/scss/views/public/Download.scss";
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../ui-component/page/public/section";
import Page from "../../../ui-component/page/public";
import {getAll} from "../../../store/actions/download";
import {Parser as HtmlToReactParser} from "html-to-react";
import {getData as getFileData, getFilePath} from "../../../store/actions/general/file";
import {getData as getImageData, getImagePath} from "../../../store/actions/general/image";

function DownloadItem({i, name, info, file, image}) {
    const htmlToReactParser = new HtmlToReactParser();

    if (!file) {
        return <Section key={"download-item-" + i}>
            <div className={"left-side"}> </div>
            <div className={"right-side file"}>
                <a className={"title"}
                   href={"/downloads"}
                   target={"_self"}>{name}</a>
                <div
                    className={"subTitle"}>{htmlToReactParser.parse(info)}
                </div>
            </div>
        </Section>
    }

    return <Section key={"download-item-" + i}>
        <div className={"left-side"}>
            <div className={"image"}>
                {image ? (
                    <img src={getImagePath(image)} alt={name}/>
                ) : (
                    <></>
                )}
            </div>
        </div>
        <div className={"right-side file"}>
            <a className={"title"}
               href={getFilePath(file)}
               target={"_blank"}>{name}</a>
            <div
                className={"subTitle"}>({file.file.mime_type.replace('application/', '')}, {(file.file.size / 1024).toFixed(2)} KB)
            </div>
            <div
                className={"detail"}>{htmlToReactParser.parse(info)}</div>
            <a className={"download"} href={getFilePath(file)} target={"_blank"}>Click here to download.</a>
        </div>
    </Section>
}

const Download = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        getAll(dispatch);
    }, [dispatch]);

    const downloads = useSelector((state) => state.downloads.downloads);
    const fileData = useSelector((state) => state.file.fileData);
    const imageData = useSelector((state) => state.image.imageData);

    const [types, setTypes] = useState([]);

    useEffect(() => {
        let _types = {}
        for (let download of downloads) {
            _types[download.type] = {}
        }
        setTypes(Object.keys(_types));
    }, [downloads]);

    useEffect(() => {
        if (downloads) {
            for (let item of downloads) {
                if (item.file) {
                    getFileData(dispatch, item.file);
                }

                if (item.image) {
                    getImageData(dispatch, item.image);
                }
            }
        }
    }, [downloads, dispatch]);

    if (!downloads) {
        return <></>;
    }

    return (
        <Page id={"download"} type={"style1"}>
            {
                types.map((type, j) => {
                    return <>
                        <Section key={"1" + j}>
                            <div className={"left-side"}>
                                <div className={"title"}>
                                    <p>{type}</p>
                                </div>
                            </div>
                        </Section>
                        {downloads.map((download, i) => {
                            if (!download) {
                                return <></>
                            }

                            if (download.type !== type) {
                                return <></>
                            }

                            let file = null;
                            if (download.file && fileData[download.file]) {
                                file = fileData[download.file]
                            }

                            let image = null;
                            if (download.image && imageData[download.image]) {
                                image = imageData[download.image]
                            }

                            return <DownloadItem i={i}
                                                 name={download?.name}
                                                 info={download?.info}
                                                 file={file}
                                                 image={image}/>;
                        })
                        }
                        <Section key={"download-" + j}>
                            <div className={"divider"}></div>
                        </Section>
                    </>
                })
            }
        </Page>
    );
};

export default Download;
