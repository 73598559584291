import "../../../../assets/scss/components/page/public/Page.scss";
import "../../../../assets/scss/Common.scss";
import Section from "../../../../ui-component/page/private/section";
import Page from "../../../../ui-component/page/private";
import { useLocation } from "react-router-dom";
import FileViewer from "../../../../ui-component/file-viewer/FileViewer";
import { useEffect } from "react";
import { raiseError } from "../../../../store/actions/general/error";
import { useDispatch, useSelector } from "react-redux";

const CourseModulePartFile = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const location = useLocation();
  const file = location?.state?.file;
  const title = location?.state?.title;

  useEffect(() => {
    if (!userType) {
      raiseError(dispatch, {
        type: "page",
        message: "You don't have permission to view this page.\nPlease login.",
      });
    }
  }, [dispatch, userType]);

  if (!file) {
    return <></>;
  }

  return (
    <Page title={title || file.name} id={"courseModulePartFile"} type={"mooc"}>
      <Section>
        <FileViewer fileId={file.id}></FileViewer>
      </Section>
    </Page>
  );
};

export default CourseModulePartFile;
