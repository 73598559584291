import * as React from "react";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { TreeView } from "@mui/x-tree-view/TreeView";
import { CoursePreMenuTree } from "../course_tree";
import { useDispatch, useSelector } from "react-redux";
import IconModule from "../../../../assets/images/mooc/tree/module.png";
import IconFile from "../../../../assets/images/mooc/tree/module_file.png";
import { MenuTreeItem } from "./MenuTreeItem";
import { useNavigate } from "react-router-dom";
import { setMenu } from "../../../../store/actions/mooc/mooc";
import { getAll as getModules } from "../../../../store/actions/mooc/course/module";

export default function MenuTreeView() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const contents = useSelector((state) => state.main.contents);
  const course = useSelector((state) => state.mooc.course);
  const menu = useSelector((state) => state.mooc.menu);
  const modules = useSelector((state) => state.courseModule.courseModules);
  const [menus, setMenus] = useState({});
  const [fullMenus, setFullMenus] = useState({});

  const getNodePath = (nodeId) => {
    let menu = fullMenus[nodeId];

    if (!menu) {
      return [];
    }

    let array = [];
    let parents = menu.node.split("|");
    let root = "";
    for (let idx in parents) {
      if (idx < parents.length - 1) {
        array.push(root + parents[idx]);
        root = parents[idx] + "|";
      }
    }
    array.push(menu.node);
    return array;
  };

  const selectMenu = (nodeIds) => {
    let menu = fullMenus[nodeIds];
    if (menu && menu.link) {
      navigate("/user/mooc/" + menu.link, {
        state: {
          menu: menu,
        },
      });
    }
  };

  useEffect(() => {
    let array = {};
    let fullArray = {};
    for (let menu of CoursePreMenuTree) {
      array[menu.node] = menu;
      fullArray[menu.node] = menu;

      for (let subMenu of menu.children) {
        fullArray[subMenu.node] = subMenu;
      }
    }

    let nodeId = "2";
    let node = {
      title: "Course: " + course.name,
      icon: IconModule,
      node: nodeId,
      children: [],
    };
    array[nodeId] = node;
    fullArray[nodeId] = node;

    if (modules) {
      for (let module of modules) {
        let nodeId = "2|" + module.order;
        let node = {
          title:
            "Module: " +
            module.order +
            "/Week: " +
            module.week +
            " - " +
            module.name,
          icon: IconModule,
          link: "module",
          node: nodeId,
          selector: module,
          children: [],
        };

        array["2"].children.push(node);
        fullArray[nodeId] = node;
      }
    }

    nodeId = "3";
    node = {
      title: "Additional Materials",
      icon: IconFile,
      node: nodeId,
      children: [],
    };
    array[nodeId] = node;
    fullArray[nodeId] = node;

    if (modules) {
      for (let module of modules) {
        let nodeId = "3|" + module.order;
        let node = {
          title:
            "Module: " +
            module.order +
            "/Week: " +
            module.week +
            " - " +
            module.name,
          icon: IconFile,
          link: "additional-materials",
          node: nodeId,
          selector: module,
          children: [],
        };

        array["3"].children.push(node);
        fullArray[nodeId] = node;
      }
    }

    setMenus(array);
    setFullMenus(fullArray);

    setMenu(dispatch, getNodePath("1|1"));
  }, [dispatch, modules]);

  useEffect(() => {
    getModules(dispatch, course.id);
  }, [course, dispatch]);

  useEffect(() => {
    if (menu.length >= 1) {
      selectMenu(menu[menu.length - 1]);
    }
  }, [dispatch, menu]);

  const onNodeSelect = (event, nodeIds) => {
    let selectedMenu = getNodePath(nodeIds);
    if (selectedMenu) {
      setMenu(dispatch, selectedMenu);
    }
  };

  const onNodeToggle = (event, nodeIds) => {
    event.preventDefault();
  };

  return (
    <TreeView
      onNodeSelect={onNodeSelect}
      onNodeToggle={onNodeToggle}
      expanded={menu}
      selected={menu.length > 0 ? menu[menu.length - 1] : []}
      multiSelect={false}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {Object.values(menus).map((row) => {
        return (
          <MenuTreeItem
            nodeId={row.node}
            labelText={
              contents
                ? contents[row.title]
                  ? contents[row.title]
                  : row.title
                : ""
            }
            labelIcon={row.icon}
            iconSize={"3em"}
            key={row.node}
          >
            {row.children.map((cell) => {
              return (
                <MenuTreeItem
                  nodeId={cell.node}
                  labelText={
                    contents
                      ? contents[cell.title]
                        ? contents[cell.title]
                        : cell.title
                      : ""
                  }
                  labelIcon={cell.icon}
                  iconSize={"1.5em"}
                  key={cell.node}
                  color="#1a73e8"
                  bgColor="#e8f0fe"
                  colorForDarkMode="#B8E7FB"
                  bgColorForDarkMode="#071318"
                ></MenuTreeItem>
              );
            })}
          </MenuTreeItem>
        );
      })}
    </TreeView>
  );
}
